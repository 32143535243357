@font-face {
  font-family: "Helvetica-Bold-Font700";   /*Can be any text*/
  src: local("Helvetica-Bold-Font"),
    url("./assets/Font/Helvetica-Bold-Font.ttf") format("truetype");
    font-display: swap;
}
@font-face {
  font-family: "Madame-Grettha";   /*Can be any text*/
  src: local("Madame-Grettha"),
    url("./assets/Font/Madame-Grettha.ttf") format("truetype");
    font-display: swap;
}
@font-face {
  font-family: "Marcellus-Regular";   /*Can be any text*/
  src: local("Marcellus-Regular"),
    url("./assets/Font/Marcellus-Regular.ttf") format("truetype");
    font-display: swap;
}
@font-face {
  font-family: "Karla-Regular";   /*Can be any text*/
  src: local("Karla-Regular"),
    url("./assets/Font/Karla-Regular.ttf") format("truetype");
    font-display: swap;
}
@font-face {
  font-family: "Playfair-Reg";   /*Can be any text*/
  src: local("Playfair-Reg"),
    url("./assets/Font/PlayfairDisplay-Regular.ttf") format("truetype");
    font-display: swap;
}
@font-face {
  font-family: "Helvetica4";   /*Can be any text*/
  src: local("Helvetica4"),
    url("./assets/Font/Helvetica4.ttf") format("truetype");
    font-display: swap;
}
@font-face {
  font-family: "Cormo";   /*Can be any text*/
  src: local("Cormorant-MediumItalic"),
    url("./assets/Font/Cormorant-MediumItalic.ttf") format("truetype");
    font-display: swap;
}
@font-face {
  font-family: "RacNF";   /*Can be any text*/
  src: local("RaconteurNF"),
    url("./assets/Font/RaconteurNF.ttf") format("truetype");
    font-display: swap;
}
@font-face {
  font-family: "Montserrat-B700";   /*Can be any text*/
  src: 
  /* local("Helvetica-Bold-Font"),
    url("./assets/Font/Helvetica-Bold-Font.ttf") format("truetype");
    font-display: swap; */
  local("Montserrat-Bold"),
    url("./assets/Font/Montserrat/Montserrat-Bold.ttf") format("truetype");
    font-display: swap;
}

@font-face {
  font-family: "Montserrat-EBold800";   /*Can be any text*/
  src: local("Montserrat-ExtraBold"),
    url("./assets/Font/Montserrat/Montserrat-ExtraBold.ttf") format("truetype");
    font-display: swap;
}
@font-face {
  font-family: "Montserrat-ELight200";   /*Can be any text*/
  src: local("Montserrat-ExtraLight"),
    url("./assets/Font/Montserrat/Montserrat-ExtraLight.ttf") format("truetype");
    font-display: swap;
}
@font-face {
  font-family: "Montserrat-L300";   /*Can be any text*/
  src: local("Montserrat-Light"),
    url("./assets/Font/Montserrat/Montserrat-Light.ttf") format("truetype");
    font-display: swap;

}
@font-face {
  font-family: "Montserrat-M500";   /*Can be any text*/
  src: local("Montserrat-Medium"),
    url("./assets/Font/Montserrat/Montserrat-Medium.ttf") format("truetype");
    font-display: swap;}
@font-face {
  font-family: "Cinz";   /*Can be any text*/
  src: local("Cinzel"),
    url("./assets/Font/Cinzel.ttf") format("truetype");
    font-display: swap;
}
@font-face {
  font-family: "Montserrat-SemiB";   /*600*/
  src: local("Montserrat-SemiBold"),
    url("./assets/Font/Montserrat/Montserrat-SemiBold.ttf") format("truetype");
    font-display: swap;
}
@font-face {
  font-family: "Montserrat-Reg";   /*Can be any text*/
  src: local("Montserrat-Regular"),
    url("./assets/Font/Montserrat/Montserrat-Regular.ttf") format("truetype");
    font-display: swap;}
.invert-color { filter: invert(1); }
.rotate180{
  transform: rotate(180deg);
}
.semi-bold{
  font-family: "Montserrat-SemiB";
}
.carousel-container {
  height: 575px; 
  /* height: 65vh;  */
  overflow: hidden; 
}

.image-container-arrow {
  position: relative;

  width: 38px;
  height: 38px;
}

.arrow-default {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 0.5s ease;
}

.arrow-hover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.5s ease;
}

.image-container-arrow:hover .arrow-default {
  opacity: 0;
}

.image-container-arrow:hover .arrow-hover {
  opacity: 1;
}

.custom-indicators-container { position: absolute; bottom: 10%; left: 52%; display: flex; justify-content: center; align-items: center; }

.custom-indicators {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 3vw;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: .5;
  transition: opacity .6s ease;
}

.custom-indicators.active {
  opacity: 1;
  width: 4vw;
}


.carousel-txt {
  padding-inline-start: 10px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  color: wheat;
}

.carousel-txt-title {
  padding-top: 100px;
  font-family: 'Karla-Regular';
  display: flex;
  color: #C6AC96;
  font-size: 2vw;
}
.carousel-txt-title-hr{
  margin-top: 100px;
  margin-left: 10px;  
  /* border-top: 5px solid; */
  /* border: 1px solid #C6AC96 !important; */
  color: #C6AC96;
  background-color: #C6AC96;
  width: 8%;
  height: 1px;
  align-self: center;
  margin-bottom: 20px;
  

}

.carousel-txt-subtitle {
  margin-top: 0px;
  margin-inline-end: 15px;
  font-family: 'Montserrat-Reg';
  font-size: 3vw;
  color: white;

  
 
}

.img-fit-height {
  height: 100%;
  object-fit: cover; /* This will make the image fit the height while maintaining its aspect ratio */
}

.sellBtn {
  position: absolute;
  bottom: 10%;
  left: 25%;
  right: 25%;
}

.carousel-btn{
  font-family: 'Marcellus-Regular';
font-style: normal;
font-size: 1.3vw;
line-height: 10px;
justify-content: center;
display: flex;
/* padding-inline: 20px; */
padding-top: 3%;
width: 25%;
padding-bottom: 3%;
background: #C6AC96;
color: #FFFFFF;
cursor: pointer;
}

.carousel-btn  {
  transform: scale(1, 1);
  transition: transform 0.4s;
}

.carousel-btn:hover{
  transform: scale(1.05, 1.05);

}


img{
  aspect-ratio: auto 640 / 360;
}
body {
  margin: 0;

}
.input-pos{
  position: absolute;
  right: 12px;
  bottom: 15px;
}
input:focus{
  border: 0px solid!important;
}

.cardimage:hover{
  opacity:0.5 !important;
}
/* .img-topPicks:hover{
  opacity:0.5 !important;
} */

.card-product-image:hover{
  opacity:0.5 !important;
}

.carousel-selected-Image .carousel-indicators button {
  margin: -10px !important;
  background-image:url(./assets/images/circlegray.png) !important;
  background-color: transparent !important;

  background-repeat: no-repeat !important;
  background-size: 7px !important;
  opacity: 1 !important;
  width: 22px;
  height: 22px;
}
.carousel-selected-Image .carousel-indicators .active{
  margin: -10px !important;
  background-image:url(./assets/images/circleswiper.png) !important;
  background-repeat: no-repeat !important;
  background-size: 8px !important;
  background-color: transparent !important;
  opacity: 1 !important;
  width: 22px;
  height: 22px;
}
.carousel-selected-Image .carousel-indicators [data-bs-target] {

  height: 22px !important;

}
.carousel-selected-Image .carousel-indicators {
display: none !important;
}
.carousel-selected-Image .carousel-indicators [data-bs-target]{
  width: 9px !important;
} 
/* .carousel-selected-Image .carousel-control-prev-icon {
  background-image: url(../../assets/images/a);
} */
.carousel-selected-Image .carousel-control-prev-icon{
  background-image:url(./assets/images/back.svg) !important
}
.carousel-selected-Image .carousel-control-next-icon{
  background-image:url(./assets/images/next.svg) !important
}
.profileimg{
  width: 271px;
height: 271px;
border-radius: 50%;
}
.profiletxt1{
  font-family: 'Montserrat-M500';
font-style: normal;
font-size: 32px;
line-height: 39px;
color: #000000;
}
.profiletxt2{
  font-family: 'Montserrat-Reg';
font-style: normal;
font-size: 24px;
line-height: 29px;
color: #000000;
}
.locationprof{
  width: 18px;
height: 19px;
}

.share{
  width: 30px;
  height: 34px;
}
.copied{
  font-family: 'Montserrat-Reg';
  font-style: normal;
  font-size: 24px;
  line-height: 29px;
  color: #000000;
}
.profiletxt3{
  font-family: 'Montserrat-Reg';
font-style: normal;
font-weight: 400;
font-size: 24px;
line-height: 29px;

color: #404040;
}
.profiletxtmob1{
  font-family: 'Montserrat-M500';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 18px;
text-align: center;

color: #000000;

}
.profiletxtmob2{
  font-family: 'Montserrat-Reg';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 18px;
text-align: center;

color: #000000;
}
.profiletxtmob3{
  font-family: 'Montserrat-M500';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 17px;
text-align: center;

color: #000000;
}
@media screen and (max-width: 540px) {
  /* .carousel-selected-Image .carousel-indicators [data-bs-target] {

      height: 22px !important;
  
  } */
}
.mainblog-menu:hover{
color: #C6AC96;
}
@media screen   and (min-width: 1300px)   {
  .mainblog-menu{
    font-family: 'Montserrat-Reg';
  font-style: normal;
  font-size: 21px;
  line-height: 41px;
  letter-spacing: 0.02em;
  text-decoration-line: underline;
  /* text-transform: uppercase; */
  
  color: #000000;
  }
 
  .mainblog-menu1{
    font-family: 'Montserrat-Reg';
  font-style: normal;
  font-size: 24px;
  line-height: 41px;
  letter-spacing: 0.02em;
  text-decoration-line: underline;
  /* text-transform: uppercase; */
  color: #000000;
  }
  .mainblog-menuall{
    font-family: 'Montserrat-Reg';
    font-style: normal;
    font-size: 24px;
    line-height: 41px;
    letter-spacing: 0.02em;
    text-decoration-line: underline;
    text-transform: uppercase;
    color: #000000;
  }
  .height-txt-blog{
    width: 300px;
  }
 
  
  .mainblog-img{
    width: 300px;
  height: 300px;
  }
  .blog-txt{
    font-family: 'Montserrat-Reg';
  font-size: 20px;
  line-height: 41px;
  letter-spacing: 0.02em;
  /* text-transform: uppercase; */
  color: #000000;
  width: 300px;
  text-decoration: none;

  }
  .flex-blog {
    text-decoration: none;

  }
  .btn-blog{
    font-family: 'Montserrat-Reg';
  font-style: normal;
  font-size: 20px;
  line-height: 41px;
  letter-spacing: 0.02em;
  /* text-transform: uppercase; */
  color: #FFFFFF;
  background: #C6AC96;
  width: 200px;
  text-align: center;
  cursor: pointer;
  } 
}
@media screen   and (max-width: 1300px)   {
  .mainblog-menu{
    font-family: 'Montserrat-Reg';
  font-style: normal;
  font-size: 18px;
  line-height: 41px;
  letter-spacing: 0.02em;
  text-decoration-line: underline;
  text-transform: uppercase;
  width: 197px;
  justify-content: center;
  display: flex;
  color: #000000;
  }
    .mainblog-menu2{
   
  width: 197px;
  
  }
  .mainblog-menu1{
    font-family: 'Montserrat-Reg';
  font-style: normal;
  font-size: 18px;
  line-height: 41px;
  letter-spacing: 0.02em;
  text-decoration-line: underline;
  text-transform: uppercase;
  width: 197px;
  justify-content: center;
  display: flex;
  color: #000000;
  }
  .mainblog-menuall{
    font-family: 'Montserrat-Reg';
  font-style: normal;
  font-size: 18px;
  line-height: 41px;
  letter-spacing: 0.02em;
  text-decoration-line: underline;
  text-transform: uppercase;
  width: 197px;
  justify-content: center;
  display: flex;
  color: #000000;
  }
  .height-txt-blog {
    height: 130px;
}
  .mainblog-img{
    width: 403px;
  height: 403px;
  }
  .blog-txt{
    font-family: 'Montserrat-Reg';
  font-size: 20px;
  line-height: 41px;
  letter-spacing: 0.02em;
  /* text-transform: uppercase; */
  color: #000000;
  width: 403px;
}
  .btn-blog{
    font-family: 'Montserrat-Reg';
  font-style: normal;
  font-size: 20px;
  line-height: 41px;
  letter-spacing: 0.02em;
  /* text-transform: uppercase; */
  color: #FFFFFF;
  background: #C6AC96;
  width: 200px;
  text-align: center;
  
  }
}
@media screen   and (max-width: 1300px)   {

  .height-txt-blog {
    height: 130px;
}
  .mainblog-img{
    width: 303px;
  height: 303px;
  }
  .blog-txt{
    font-family: 'Montserrat-Reg';
  font-size: 20px;
  line-height: 41px;
  letter-spacing: 0.02em;
  /* text-transform: uppercase; */
  color: #000000;
  width: 303px;
}
  .btn-blog{
    font-family: 'Montserrat-Reg';
  font-style: normal;
  font-size: 20px;
  line-height: 41px;
  letter-spacing: 0.02em;
  /* text-transform: uppercase; */
  color: #FFFFFF;
  background: #C6AC96;
  width: 200px;
  text-align: center;
  
  }
}
@media screen   and (max-width: 1290px)   {
.blog-txt{
  font-size: 18px;
}
  .mainblog-menu{
    font-family: 'Montserrat-Reg';
    font-style: normal;
    font-size: 16px;
    line-height: 41px;
    letter-spacing: 0.02em;
    text-decoration-line: underline;
    text-transform: uppercase;
    width: 120px;
    justify-content: center;
    display: flex;
  }
  .height-txt-blog {
    height: 70px;
}
  .mainblog-img{
    width: 230px;
  height: 230px;
  }
  .btn-blog {
    font-family: 'Montserrat-Reg';
    font-style: normal;
    font-size: 16px;
    line-height: 33px;
    letter-spacing: 0.02em;
    /* text-transform: uppercase; */
    color: #FFFFFF;
    background: #C6AC96;
    width: 180px;
    text-align: center;
}
}

    @media screen   and (max-width: 800px)   {

      .mainblog-menu{
      
        width: 197px;
        justify-content: center;
        display: flex;
    
      }
       .mainblog-menu2{
      
       
        width:100px;
        
    
      }
      .mainblog-menu1{
        width: 197px;
    
      }
     
      .mainblog-menuall{
        width: 197px;
    
      }
    }    
@media screen   and (max-width: 1200px)   {

  .dispaly-row-blog{
display: block;
  }
}
@media screen   and (min-width: 1300px)   {
  .marginnewdrops{
    margin-inline: 141px;
  }}
@media screen   and (min-width: 1200px)   {
  .dispaly-row-blog{
    display: flex;
      }
      .product-img{
        height: 200px;
        width: 200px;
        }

      /* email */
      /* .product-img{
        height:120px;
        width: 120px;
        } */
    .cardimage{
      height: 200px;
    width: 200px;
    }
    @keyframes skeleton-loading2 {
      0% {
        
        background-image: url(./assets/images/palceholderimage.webp);
        text-align: center;
        background-size: contain;
        height: 215px;
    width: 215px;
        margin: 0 auto;
      }
      100% {
       

        background-image: url(./assets/images/palceholderimage.webp);
        margin: 0 auto;
        background-size: contain;
        height: 215px;
    width: 215px;
        text-align: center;
       

      }
    
    }
    .skeleton {
      animation: skeleton-loading 3s linear  alternate;
    }
    
    @keyframes skeleton-loading {
      0% {
        
        background-image: url(./assets/images/palceholderimage.webp);
        text-align: center;
        background-size: contain;
        height: 215px;
        width: 215px;
        margin: 0 auto;
      }
      100% {
       

        background-image: url(./assets/images/palceholderimage.webp);
        margin: 0 auto;
        background-size: contain;
        height: 215px;
        width: 215px;
        text-align: center;
       

      }
    
    }
}

@media screen   and (min-width: 1290px)   {
  .navbar-links{
    font-family: "Montserrat-Reg";
  font-style: normal;
  font-size: 14px;
  line-height: 22px;
  text-decoration: none;
  color: #000000;
  }
  .search-brand2{
    background :transparent !important;
    height: 37px !important;
    width: 350px !important;
    border: none !important;
    border-bottom: 1px solid #404040 !important;
    border-radius: 0px !important;
    border-left: 0px !important;
    padding-bottom: 9px !important;
   }

   .search-nav-new{
    background :transparent !important;
    height: 37px !important;
    width: 300px !important;
    border: none !important;
    /* border-bottom: 1px solid #404040 !important; */
    border-radius: 3px !important;
    border-left: 0px !important;
    padding-bottom: 9px !important;
   }
  
   .search-brand2Ar{
    background :white !important;
    height: 37px !important;
    width: 350px !important;
    border: none !important;
    border-bottom: 1px solid #404040 !important;
    border-radius: 0px !important;
    border-right: 0px !important;
    padding-bottom: 9px !important;
   }

   .search-navAr-new{
    background :transparent !important;
    height: 37px !important;
    width: 350px !important;
    border: none !important;
    border-bottom: 0px solid #404040 !important;
    border-radius: 0px !important;
    border-right: 0px !important;
    padding-bottom: 9px !important;
   }
}
@media screen   and (max-width: 1290px)   {
  .navbar-links{
    font-family: "Montserrat-Reg";
    font-style: normal;
    font-size: 14px;
    line-height: 22px;
    text-decoration: none;
    color: #000000;
  }
  .search-brand2{
    background :transparent !important;
    height: 37px !important;
    width: 250px !important;
    border: none !important;
    border-bottom: 1px solid #404040 !important;
    border-radius: 0px !important;
    border-left: 0px !important;
    padding-bottom: 9px !important;
   }

   .search-nav-new{
    background :transparent !important;
    height: 37px !important;
    width: 200px !important;
    border: none !important;
    border-bottom: 0px solid #404040 !important;
    border-radius: 3px !important;
    border-left: 0px !important;
    padding-bottom: 9px !important;
   }
  
   .search-brand2Ar{
    background :white !important;
    height: 37px !important;
    width: 250px !important;
    border: none !important;
    border-bottom: 1px solid #404040 !important;
    border-radius: 0px !important;
    border-right: 0px !important;
    padding-bottom: 9px !important;
   }

   .search-navAr-new{
    background :white !important;
    height: 37px !important;
    width: 250px !important;
    border: none !important;
    border-bottom: 0px solid #404040 !important;
    border-radius: 0px !important;
    border-right: 0px !important;
    padding-bottom: 6px !important;
   }

}
@media screen   and (min-width: 1100px)   {
  .img-topPicks{
    width: 153.95px !important;
  height: 152.03px !important;
  }

}
@media screen   and (max-width: 1100px)   {
  .img-topPicks{
    width: 103.95px !important;
  height: 102.03px !important;
  }

}
@media screen   and (max-width:1000px)  {
  .img-topPicks{
    border-radius: 10%;
    width: 
    169.1px !important;
  height: 
  167.78px !important;
  }


}
  @media screen   and (max-width:1200px)  {
    .product-img{
      width: 150px;
      height: 150px;
      }
      .cardimage{
        height: 150px;
        width: 150px;
      
        }
      
  }

  @media screen    and (max-width:518px)  {
    .product-img{
      width: 150px;
      height: 150px;
      }
      .cardimage{
        width: 150px;
        height: 150px;
        }
       
  }
  @media screen    and (max-width:518px)  {
    .product-img{
      width: 140px;
      height: 140px;
      }
      .cardimage{
        width: 140px;
        height: 140px;
        }
       
  }
  /* @media screen and (max-width:340px)  {
    .product-img{
      width: 100px;
      height: 100px;
      }
      .cardimage{
        width: 100px;
        height: 100px;
        }
       
  } */
  @media screen and (max-width:320px)  {
    .product-img{
      width: 70px;
      height: 70px;
      }
      .cardimage{
        width: 120px;
        height: 120px;
        }
       
  }
.deal-price{
  font-family: 'Montserrat-M500';
  font-size: 18px;
  /* line-height: 33px; */
  text-align: start;
  color: #800020;
}
.card-order{
  width:243px  !important;
  height:450px  !important;
  /* background: rgba(255, 255, 255, 0.24) !important; */
  box-shadow: 0px 7px 18px -1px rgb(0 0 0 / 17%) !important;

}
.card3{
  width:288.85px  !important;
  height:440px  !important;
  /* background: rgba(255, 255, 255, 0.24) !important; */
  box-shadow: 0px 7px 18px -1px rgb(0 0 0 / 17%) !important;

}
.flex-wrap{
flex-wrap: wrap ;
}
.swipeable-list-item__trailing-actions--return {
  display: block;
  /* transition-timing-function: ease, step-end!important; */
}


.username-handBag{
  font-family: 'Montserrat-Reg';
font-style: normal;
font-size: 21px;
line-height: 29px;

color: #000000;
}

.paginationBttns {
  justify-content: end;
  flex-wrap: wrap!important;
  align-items: center !important;
  width: 100%;
  /* height: 40px; */
  list-style: none;
  display: flex;
  /* justify-content: center; */
  margin-bottom: 0px !important;
  padding-right: 0!important;
  padding-left: 0!important;
}
.paginationend{
    justify-content: end;

}

.bg-orders{
  background: #DFDFDF;
}
.dropdown-divider{
  border-top: 0.4px solid #C6AC96 !important;

}
.dropdown-divider:hover{
  border-top: 0.4px solid #C6AC96 !important;

}
.list-style-none{
  list-style-type: none;

}
.designrer-brand{
  width: 
  238.67px;
    height: 
    240.85px;
    cursor: pointer;
    transform: scale(1, 1);
    transition: transform 0.4s;

}
.designrer-brand:hover{
  transform: scale(1.05, 1.05);
}
.justify-space-evenly{
  justify-content: space-evenly;
}

.react-switch-handle{
height: 19px !important;
width: 19px !important;
box-shadow: 0px 2px 2px -1px rgba(0, 0, 0, 0.25) !important;
}
.react-switch-bg{
  margin: 5.5px !important;
}
.btn-profile:focus{
  border-color: transparent !important;
  border: none !important;
}

.navbar-light .navbar-toggler{
color: transparent !important;
}
.navbar-toggler:hover{
  background-color: transparent !important;
  border: 0px !important;
}
.navbar-toggler:focus{
  background-color: transparent !important;
  border: 0px !important;
}
.navbar-links:hover{
  color: #C6AC96 !important;
  cursor: pointer;
}
.navbar-text:focus{
  color: #C6AC96;
  cursor: pointer;
}
 .dropdown-navEn[data-bs-popper]{
  left: -244px  !important;
  
}
.dropdown-navAr[data-bs-popper]{
  left: -0px  !important;
  
}
.dropdown-nav2[data-bs-popper]{
  left: -409px  !important
  
}
.dropdown-navSearch{
  left: -21px  !important;
  width:700px;
  background-color: white;
  z-index: 2;
  border: 1px solid rgba(0,0,0,.15);
    border-radius: 0.25rem;

}
.dropdown-navSearchAr{
  right: 0px  !important;
  width:700px;
  background-color: white;
  z-index: 1;
  border: 1px solid rgba(0,0,0,.15);
    border-radius: 0.25rem;

}
.btn-seacrh{
  background: #C6AC96;
  font-family: 'Montserrat-Reg';
font-style: normal;
font-size: 11px;
line-height: 11px;
/* identical to box height */
border: 0px;
height: 
26px;
letter-spacing: 0.11em;
width: 100%;
color: #FFFFFF;
}
.welcome-nav{
  font-family: 'Montserrat-M500';
font-style: normal;
font-size: 14px;
line-height: 17px;
/* identical to box height */

letter-spacing: 0.11em;

color: #000000;
}
.sold-price{
  text-decoration: line-through;
}
.clear-filters2{
  font-family: 'Montserrat-Reg';
font-style: normal;
font-size: 28px;
line-height: 43px;

color: #000000;
 }
.paginationBttns a {
  /* padding-inline: 5px !important; */
    /* padding-top: 2px!important;
    padding-bottom: 2px!important; */
    border-radius: 0px !important;
    font-family: 'Montserrat-M500'!important;
    font-style: normal;

    font-size: 16px !important;
    /* line-height: 37px !important; */
    box-shadow: none !important;
    text-transform: capitalize;
    background: transparent !important;
    /* border: 0.4px solid #404040 !important; */
    color: #404040 !important;
    margin-inline: 5px;
    text-decoration: none;
}

.paginationBttns a:hover {
  color: #C6AC96 !important;
  /* background: #C6AC96; */
}

.paginationActive a {
  color: #C6AC96 !important;
  /* background: #C6AC96 !important; */
 
}

.paginationDisabled a {
  color: grey;
  background-color: grey;
}

.clear-filters{
  font-family: 'Montserrat-M500';
font-style: normal;

font-size:28px;
line-height: 43px;
color: #C6AC96;
}
.price-notindeal{
  padding-bottom: 2rem;
}
.deal-price1{
  font-family: "Montserrat-M500";
  font-size: 17px;
  /* line-height: 30px; */
  text-align: start;
  text-decoration-line: line-through;
  
  color: #1A1818;
}
.oops{
  font-family: 'Montserrat-SemiB';
font-style: normal;

font-size: 18px;
line-height: 27px;
/* identical to box height */


color: #C6AC96;
}
ul {
  list-style-type: none;
}
.prof-img {
  border-radius: 25px;
 
  border-radius: 50%;
  width: 115px;
  height: 115px;
}
.list-designers{
height: 300px;
overflow-y: auto;
}
.txt-accordion2{
  font-family: 'Montserrat-Reg';
  font-style: normal;

  font-size: 15px;
  line-height: 22px;
  text-transform: capitalize;
  text-decoration: none;
  color: #1A1818;
}
.txt-accordion:focus {
  color: #97816d;
}
.txt-accordion2:focus {
  color: #97816d;
}
.search .fa-search-brand{
  position: absolute;
 }
 .fa-search-brand{

    position: absolute;
    top: 14px;
    left: 16px;
    width: 16px;
    height: 16px;
 }
 .fa-search-brandAr{

  position: absolute;
  top: 14px;
  right: 16px;
  width: 16px;
  height: 16px;
}
 .search .fa-search-brand2{
  position: absolute;
 }
 .fa-searchicon{
border-radius: 0px !important;
 }
 .fa-searchiconAr{
  border-radius: 0px !important;
  border-left: 0px !important;
   }
 .fa-search-brand2{

    /* position: absolute;
    top: 13px;
    left: 11px; */
    width: 16.021px;
    height: 15.998px;
 }
 .fa-search-brand2country{

  /* position: absolute;
  top: 13px;
  left: 11px; */
width: 14px;
height: 14px;
}
 .fa-search-first-step{
  position: absolute;
  top: 11px;
  left: 16px;
width: 31px;
height: 20px;
 }
 .view-more{
  font-family: 'Montserrat-Reg';
font-style: normal;

font-size: 19px;
line-height: 23px;
text-align: center;
text-decoration-line: underline;

color: #FFFFFF;
 }
 .search-brand-firstStep{
background-color: white;
 }
 .search .search-brand{
  height: 40px;
 }
 .text-dot-steps{
  font-family: 'Montserrat-M500';
font-style: normal;

font-size: 8px;
line-height: 10px;
/* identical to box height */


color: #404040;
 }
.hr-img{
  width: 230px;
}
.border-image-drag{
  
    border: 1px solid #404040;
    width: 
    100px !important;
    height: 
    100px;
    border-radius: 11%;


}
.pos-image-txt{
  z-index: 1;
  top: 27%;
  left: 16%;
}
.form-prof{
  font-family: 'Montserrat-M500';
font-style: normal;
font-size: 14px;
line-height: 17px;
/* identical to box height */


color: #404040;
}
.img-drag{
  width: 
  97px;
  height: 
  97px;
  border-radius: 11% !important;
}
.front-image{
  font-family: "Montserrat-Reg";
  font-style: normal;
font-size: 12px;
line-height: 15px;
/* identical to box height */


color: #404040;
}
@media screen and (max-width: 800px) {
  

  .hr-img {
    width: 87px;
    max-width: 100%;
  
  }
  }
.text-delete{
  font-family: 'Montserrat-M500';
font-style: normal;
font-size: 20px;
line-height: 37px;
/* identical to box height */


color: #404040;
}
.bg-add {
  background-color: #EEEEEE;
}
.show-modal-location{
  position: fixed;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 1;
  top:50%;
}
.custom-modal::backdrop { background-color: rgba(0, 0, 0, 0.5); /* Adjust the color and opacity as needed */ 
  pointer-events: none; /* Prevent interaction with the content behind the modal */ 
}
.show-modal-auth {
  position: fixed;
  left: 0;
  right: 0;
  top: 20%;
  margin: 0 auto;
  z-index: 5;
}
.padding-card-top{
  padding-top: 78px;
}
.sub-total{
  font-family: 'Montserrat-SemiB';
font-style: normal;

font-size: 16px;
line-height: 20px;
color: #404040;
}
.sub-total-price{
  font-family: 'Montserrat-SemiB';
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
  color: #404040;
}
.btn-next-checkout{
  font-family: 'Montserrat-M500';
font-style: normal;
font-size: 14px;
line-height: 20px;
color: #F8F8F8;
background: #404040;
border: none;
height: 45px;
/* padding-top: 15px;
padding-bottom: 15px; */

}
.btn-view-items{
  font-family: 'Montserrat-M500';
font-style: normal;
font-size: 14px;
line-height: 20px;
color: #F8F8F8;
background: #C6AC96;
border: none;
height: 45px;
/* padding-top: 15px;
padding-bottom: 15px; */

}
.btn-next-checkoutoff{
  font-family: 'Montserrat-M500';
font-style: normal;
font-size: 14px;
line-height: 20px;
color: #F8F8F8;
background: #404040;
border: none;
height: 45px;
width: 60%;
/* padding-top: 15px;
padding-bottom: 15px; */

}
.bg-wishList{
  background: #C6AC96;
  font-family: 'Montserrat-M500';
font-style: normal;
font-size: 12px;
line-height: 15px;
color: #FFFFFF;
}
.bg-delete{
  font-family: 'Montserrat-M500';
font-style: normal;
font-size: 12px;
line-height: 15px;
/* identical to box height */


color: #FFFFFF;
  background: #C32727;
}
.swipeable-list-item__content {
  display: block !important;
}
.auth-checkout{
  font-family: 'Montserrat-M500';
font-style: normal;
font-size: 14px;
line-height: 17px;
color: #777777;
}
.btn-location{
  font-family: 'Montserrat-SemiB';
font-style: normal;

font-size: 16px;
line-height: 24px;
text-align: center;
background: #C6AC96;
border: none;
padding-inline: 20px;
padding-top: 5px;
padding-bottom: 5px;

color: #FDFDFD;
}
.btn-rmCoupon-yes{
  font-family: 'Montserrat-SemiB';
font-style: normal;

font-size: 16px;
line-height: 24px;
text-align: center;
background: #000000;
border: none;
padding-inline: 40px;
padding-top: 5px;
padding-bottom: 5px;

color: #FDFDFD;
}
.btn-rmCoupon-no{
  font-family: 'Montserrat-SemiB';
font-style: normal;

font-size: 16px;
line-height: 24px;
text-align: center;
background: #C6AC96;
border: none;
padding-inline: 40px;
padding-top: 5px;
padding-bottom: 5px;

color: #FDFDFD;
}
.loaction-current{
  font-family: 'Montserrat-SemiB';
  font-style: normal;

  font-size: 15px;
  line-height: 23px;
  color: #404040;
}
.oops-txt{
  font-family: 'Montserrat-M500';
font-style: normal;
font-size: 15px;
line-height: 23px;
text-align: center;

color: #959595;
}
.mybag-Title{
  font-family: 'Montserrat-M500';
  font-style: normal;
  font-size: 35px;
  line-height: 43px;
  /* text-transform: capitalize; */
  color: #C6AC96;
}
.mybag-TitleNEW{
  font-family: 'Montserrat-M500';
  font-style: normal;
  font-size: 35px;
  line-height: 43px;
  /* text-transform: capitalize; */
  color: #404040
}
.width50{
  width:60%
}
.width60{
  width:60%
}
.bg-payment{
  background: #F8F8F8;

}

.bg-credit{
background: #F7F7F7;
}
.bg-myaddress2 {
  background: #FFFFFF !important;
}
.bg-myaddress {
  background: #FFFFFF !important;
}
.new-card{
  font-family: 'Montserrat-SemiB';
  font-style: normal;

  font-size: 20px;
  line-height: 32px;
  text-transform: capitalize;
  color: #C6AC96;
}
.card-nbr{
  font-family: 'Montserrat-M500';
font-style: normal;
font-size: 18px;
line-height: 29px;
text-transform: capitalize;
color: #404040;
}
.react-tel-input{
  font-family: 'Montserrat-Reg' !important;
  color: #404040 !important;
  font-size: 12px !important;

}
.react-tel-input .form-control {
  background: transparent !important;
}
.react-tel-input .selected-flag{
  padding: 0.275rem 0.75rem !important;
}
.react-tel-input .form-control{
  padding-top: 0.275rem !important;
  padding-bottom: 0.275rem  !important;

}
.alert-btn{
  border: none;
  background-color: transparent;
  font-family: 'Montserrat-M500';
font-style: normal;
font-size: 11px;
line-height: 13px;

color: #C6AC96;
}
.ReactFlagsSelect-module_selectValue__152eS, .ReactFlagsSelect-module_selectOption__3pcgW {
  font-family: 'Montserrat-Reg'!important;
  font-style: normal!important;
  /* font-weight: 400!important; */
  font-size: 15px!important;
  line-height: 26px!important;
  text-transform: capitalize!important;
  color: #404040!important;
  margin: 1px 1px!important;
}
.select-country::placeholder{
  color: #C4C4C4!important;

}
input[type='date']::-webkit-calendar-picker-indicator {
  background:none !important;
  background-size: contain !important;
}
.ReactFlagsSelect-module_selectBtn__19wW7 {
  border: 0px !important;
  /* padding: 5px 10px; */
  padding: 0px 0px !important;
}
.border-accordion-none{
  border-top: none !important;
  border-bottom: none !important;
  border-left: none !important;
  border-right: none !important;

}
.li-nav{
  font-family: 'Montserrat-M500';
  font-style: normal;
  font-size: 11px;
  line-height: 13px;
}
.web-icon{
  bottom: 50%;
}
.search-icon{
  background-color: white !important;
  border: none !important;
  display: block;
}
.placholder-search{
  font-family: "Montserrat-L300";
font-size: 16px;
line-height: 20px;
color: #808080;
}
.logo{
  margin: 0 auto;
}
/* ul {
  list-style-type: none;
} */
/*menu */
.cond-img{
  
font-family: 'Montserrat-Reg';
font-style: normal;
font-size: 11px;
line-height: 13px;

color: #404040;
}
.row-bg{
  background: white;

  height: auto;
  padding-top:10px;
  padding-bottom:10px;

}
.link-text{
  

  font-family: 'Montserrat-Reg';
  font-style: normal;
  font-size: 18px;
  line-height: 22px;
  
  color: #404040;

}

.link{
  text-decoration: none;
  color: #1A1818;
}
.link:hover{
  color: #97816d;
  cursor: pointer;
}

.link:focus{
  color: #97816d;
}
/* louis  */
.louis-title{
padding-top: 2.2rem;
font-family: 'Montserrat-M500';
font-style: normal;
font-size: 50px;
line-height: 61px;
color: #C6AC96;
text-align: center;
}
.handbag-heart{
top: 0%;
left: 20px;
}
.handbag-icons{

right: 49px;
}
.sub-title-louis{
  font-family: 'Montserrat-Reg';
  font-size: 27px;
  line-height: 33px;
color: #000000;
text-align: center;
padding-bottom: 1.8rem;
}
.bags{
  width: 282px;
  height: 282px;
}
.bags:hover{
  cursor: pointer;
  transform: scale(1.03);
  transition: all 0.1s ease;
}
.show-bag{
  height: 100% !important;
}
.show-bag:hover{
  cursor: pointer;
  transform: scale(1.03);
  transition: all 0.1s ease;
}
.bag-details{

position: relative;
}
.border-details{
  border-right: 1px solid #C6AC96;

}
.border-detailsAr{
  border-left: 1px solid #C6AC96;

}
.price{
  justify-content: space-between;
  align-items: start;
  display: flex;
}
.bag-details .price1{


  font-family: 'Montserrat-M500';
  font-style: normal;
  font-size: 20px;
  line-height: 29px;
  text-decoration-line: line-through;
  
  color: #404040;
}

.bag-details .price2{
  font-family: 'Montserrat-SemiB';
  font-style: normal;

  font-size: 20px;
  line-height: 29px;
  
  color: #DD0000;
  padding-inline: 9px;
 
}
.bag-details .pourcentage{
  font-family: 'Montserrat-L300';
  font-style: normal;

  font-size: 14px;
  line-height: 20px;
  color: #146900;
}
/* .badge-icon{
  padding-bottom: 28px;
} */
.estimated{
  font-family: 'Montserrat-Reg';
  font-style: normal;
  font-size: 20px;
  line-height: 24px;
  
  color: #777777;
padding-top: 2px;

}
.text-hanbagDetail{
  display: flex;
  
}
.badge-txt{
  font-family: 'Montserrat-Reg';
  font-style: normal;
  font-size: 12px;
  line-height: 15px;
  color: #C6AC96;
}
.learn-more{
  text-decoration: none;
  color: #C6AC96;
  text-decoration: underline;
}
.learn-more:hover,.learn-more:focus{
  color: #97816d !important;
}
.bag-details .msg{
padding-top: 21px;
font-family: 'Montserrat-Reg';
font-style: normal;

font-size: 18px;
line-height: 22px;
color: #404040;
}

/* new */
.btn-order{
width: 100%;
padding-top: 24px;
padding-bottom: 24px;
  background: #404040;
  border:none;
  font-family: 'Montserrat-M500';
  font-size: 24px;
  line-height: 20px;
  color: #FFFFFF;
  /* margin-top: 20px;
  margin-top: 40px; */
  }
  /* new */
  .btn-store{
    border: none;
    background: #FFFFFF;
    padding-bottom: 9px;
    padding-inline: 20px;
    padding-bottom: 10px;
    padding-top: 10px;
  }
.btn-order:hover{
  background: #C6AC96;
  border:none;
  color: white;

}
.btn-order:focus{
  background: #C6AC96;
  border:none;
  color: white;

}
.btn-offer:hover{
  background: #C6AC96;
  border:none;
  color: white;

}
.btn-2Image:hover{
  background: #C6AC96;
  border:none;
  color: white;

}
.btn-offer:focus{
  background: #C6AC96;
  border:none;
  color: white;

}
.btn-2Image:focus{
  background: #C6AC96;
  border:none;
  color: white;

}
input:focus{border: none !important;}
.btn-grp{
  
  padding-top: 20px;
}
.btn-bag{
  width: 240px;
height: 86px;

font-family: 'Montserrat-B700';
font-style: normal;

font-size: 26px;
background: #FFFFFF;
}
.requestView:hover{
  background: #C6AC96;
  color: #000000;

}
.btn-store:hover{
  background: #C6AC96;
  color: #000000;

}
.makeOffer:hover{
  background: #bca088;
  color: white;
}
.requestView{
  /* height: 86px; */
  background: #FFFFFF;
border: 1px solid #404040;
font-family: 'Montserrat-M500';
font-style: normal;
font-size: 14px;
line-height: 22px;
color: #404040;
width: 100%;
padding-inline: 25px;
    padding-top: 20px;
    padding-bottom: 20px;
}
.makeOffer{
  font-family: 'Montserrat-M500';
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  padding-inline: 30px;
  color: #000000;
  background: #C6AC96;
  border: none;
  width: 100%;
    padding-inline: 25px;
    padding-top: 20px;
    padding-bottom: 20px;
  /* height: 86px; */
}
/* old */
/* .btn-store{
  border: none;
  background: #FFFFFF;
  padding-top: 16px;
  padding-bottom: 9px;
  padding-inline: 20px;
  height: 86px;
} */
.btn-label{

font-family: 'Montserrat-B700';
font-style: normal;

font-size: 26px;
line-height: 32px;
color: #000000;
}
.btn-bag:focus{
  background: #C6AC96 !important;
}
.location{
display: flex;
padding-top: 39px;
}
.location-name{
  font-family: 'Montserrat-Reg';
  font-style: normal;
  font-size: 20px;
  line-height: 29px;
  color: #404040;
}
/* Details */
.detailsTitle{
  font-family: 'Montserrat-M500';
  font-style: normal;
  font-size: 35px;
  line-height: 55px;
  color: #C6AC96;
}
.detailText{
  font-family: 'Montserrat-M500';
  font-style: normal;
  font-size: 24px;
  line-height: 29px;
  color: #404040;
}
.detailType{
  font-family: 'Montserrat-ELight200';
  font-style: normal;
  font-size: 18px;
  line-height: 22px;
  text-align: right;
  color: #404040;
}

/* Seller */
.check-txt{
  font-family: 'Montserrat-Reg';
font-style: normal;
font-size: 24px;
line-height: 29px;

color: #C49235;
}

.unfollow{
  background: #C6AC96;
  width:107.09px !important;
height: 31px !important;
font-family: 'Montserrat-Reg ' !important;
font-style: normal;
font-size: 16px;
line-height: 20px;
border-radius: 5px;
color: #FFFFFF;
border: none;
}
.contact{
  background: #000000;

  width:107.09px !important;
  height: 31px !important;
  font-family: 'Montserrat-Reg ' !important;
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
  border-radius: 5px;
  color: #FFFFFF;
  border: none;
}
.unfollow:hover{
  background: #C6AC96;
  color: #000000;}
.contact:hover{
  background: #C6AC96;
  color: #000000;
}
.username2{
  font-family: 'Montserrat-M500';
font-style: normal;
font-size: 14px;
line-height: 17px;
color: #000000;
}
.see-more{
  font-family: 'Montserrat-M500';
font-style: normal;
font-size: 16px;
line-height: 20px;
text-decoration-line: underline;
color: #404040;
cursor: pointer;
}
.see-more:hover{
  color: #C6AC96;

}
/* Menu */
.quality{
  
  background: #EAEAEA;
  border-radius: 10px;
  width: 100%;
  height: auto;
  padding-top: 10px;
  padding-bottom: 10px;
}
.menu-txt{
  font-family: 'Montserrat-L300';
font-style: normal;
font-size: 30px;
line-height: 37px;
text-align: start;
color: #000000;
}
.shield{
  Width: 27px;
  height: 30px;
}
.arrow{
  width:19px ;
  height:31px ;
height: 100%;

}
.arrow2{
  width: 24.09px;
  height: 25.52px;


}
/* Comment */
.box{
  width:100%;
  background: #C6AC9630;
}


/* Card
.card-title{
  margin: auto;
  font-family: 'Montserrat';
font-style: normal;
font-size: 65px;
line-height: 79px;
text-align: center;
color: #000000;
padding-bottom: 60px;
padding-top: 75px;

}
.box-card{
width: 100%;
height: auto;
background: #F9F9F9;
padding-top: 130px;
padding-bottom: 130px;
justify-content: center;
}

.backgroud-card{
background-color: #F9F9F9;
}
.card-container{
  padding-top: 100px;
  padding-bottom: 100px;
} */

/* .card{
  border-radius: 34px !important;
} */

/* .rolex{

  height: 235px;
  width: 100%;

} */
.rolex-div{
  text-align: center;
  padding-top: 13px;
  height: 220px;
}

.icons-card{
  top: 12px;
  right: 0;
  align-items: center;
  padding-right: 27px;
}
.icons-trusted{
  top: 12px;
  left: 0;
  align-items: center;
  padding-left: 27px;
}
.trustedicon{
  width: 19px;
  height: 19px;
}


.rolex-div{
  display: flex;
  text-align: center;
  justify-content: center;
}
.gucci{
  width: 161px;
height: 214px;
}
/* Footer */
.footer{
  background: #262626 !important;
  /* background: #404040 !important; */
}
.footer-start{
  padding-top: 80px;
  padding-bottom: 80px;
}
.footer-end{
  padding-top: 10px;
  padding-bottom: 10px;
}
.footer-title{

  font-family: 'Montserrat-Reg';
  font-style: normal;

  font-size: 24px;
  line-height: 32px;
  /* identical to box height */
  
  /* text-transform: uppercase; */
  color: #FFFFFF;
  /* color: #FFFFFF; */
padding-bottom: 30px;

}
.btn-selected{
  background-color: white ;
  border: none;
 }

.sub-tittle-footer{
  font-family: 'Montserrat-Reg';
font-style: normal;
font-size: 14px;

line-height: 219%;


text-decoration: none;
/* color: #C4C4C4; */
color: #FFFFFF;
display: grid;
/* margin-bottom: 24px; */
}
.sub-tittle-footer:hover{
  color: #ede5e5;
  cursor: pointer;
}
.hr-footer{
  /* color: rgba(237, 237, 237, 0.24); */
  color: #CAC4D0;
}
.select-location{
  padding-left: 0px !important;

  font-family: 'Montserrat' !important;
  font-style: normal !important;
  font-size: 20px !important;
  line-height: 15px;
width: min-content !important;
  background-color: transparent !important;
border: none !important;
color: #FFFFFF !important;
background: url(../src/assets/images/ChevronDown.png) no-repeat !important;
background-position: right !important;
  color: #FFFFFF !important;

}
.end-section{
  font-family: 'Montserrat-Reg';
  font-style: normal;
  font-size: 24px;
  line-height: 29px;
  align-items: baseline;
  color: #C4C4C4;
}
.end-section:hover{
  color: #ede5e5;
}
.code{
    
  color: #C4C4C4;
}
.app-store{
  width: 130px;

}
.google-play{
  width: 110px;
}
.btn-footer{
  background-color:transparent!important;
  border: none;
}
.btn:hover{
  border: none;
}
.btn:focus{
  border: none;
  box-shadow: none !important;
  border-color: none;
}
.social-media{
  background-color: transparent;
  width: fit-content;
  width: 29.76px;
height: 29.76px;
  border: none;
}

/* Nav */

.Logo-nav{
  margin: auto;
}
.search{
  position: relative;
border: none !important;    
  }

  .search-brand-desc{

   height: 60px !important;
   text-indent: 25px;
   border: 2px solid #d6d4d4 !important;


  }
  .search-nav:focus{
   box-shadow: none !important;
   border-color: #d6d4d4 !important;
  }
  .search .fa-search{
   position: absolute;
  }
.input-group-text{
  background-color: white !important;
}

.input-promo:disabled{
  background-color: white !important;

}

.search-w{
  width: 400px !important;

}
.search-nav{
  border-left-style:none !important;
  display: block;
  height: 42.97px !important ;  
}

.search-nav::placeholder{
  font-family: 'Montserrat-L300';
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
  color: #808080;
}
.sell-item{
  background: rgba(64, 64, 64, 0.8);
font-style: normal;
font-family: 'Montserrat-M500';
font-size: 18px;
line-height: 22px;
border: none;
color: #FFFFFF;
padding-top: 10px;
padding-bottom: 10px;
padding-inline: 22px;
}
.sell-item:hover {
  background: #b5977d;
}
.sign-in{
  font-family: 'Montserrat-Reg';
  font-style: normal;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.11em;
  /* padding-left: 1rem;
  padding-right: 1rem; */
text-decoration: none;
  color: #000000;
  padding-top: 0.25rem;
  padding-bottom:  0.25rem;
  /* text-align: center; */

}
  
.sign-in2{
  font-family: 'Montserrat-Reg';
  font-style: normal;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.11em;
  /* padding-left: 1rem;
  padding-right: 1rem; */
text-decoration: none;
  color: #000000;
  padding-top: 0.25rem;
  padding-bottom:  0.25rem;
  /* text-align: center; */
  text-transform: uppercase;
}
.sign-in2:hover{
  color: #000000;
}
.sign-in:hover{
  color: #000000;
  border: 0px;
}
.sign-in:focus{
  color: #000000;
  border: 0px;
}
.shoppoing-bag{
 background-color: transparent;
 border: none;
}

.navbarheader{
  padding-top: 50px !important;
  padding-bottom: 50px!important;
background-color: white;
}

.menu{
background-color: white;
}

.footer-txt{
  font-family: 'Montserrat-Reg';
  font-style: normal;
  font-size: 10px;
  line-height: 22px;
  color: #808080;
}
.date{
  font-family: 'Montserrat-Reg';
font-style: normal;
font-size: 10px;
line-height: 26px;

color: #808080;
}
/*  */

.navHeader {
  flex-grow: 0 !important;
}

/* .card:hover{
  cursor: pointer;
  transform: scale(1.03);
  transition: all 0.1s ease;
} */
/* .sign-in:hover{
  color: #b5977d;

} */
.modal-nav{
  width: auto !important;
padding-right: 20px;
padding-left: 20px;
top: 25% !important;

}
.modal-header{
  border: none!important;
}
.close-btn{
  background-color: transparent !important;
  border: none !important;
  
}
.close-btn:hover{
  background-color: #97816d;
}
.close-btn2{
  width: 27.03px;
  height: 27.03px;
}
.auth-modalTitle{
  font-family: 'Montserrat-SemiB';
font-style: normal;

font-size: 24px;
line-height: 34px;
letter-spacing: 0.07em;
text-transform: uppercase;

color: #1A1818;
}
.sub-authModal{
  font-family: 'Montserrat-Reg';
font-style: normal;
font-style: normal;
font-size: 16px;
line-height: 28px;
letter-spacing: 0.07em;

color: #1A1818;
}
.auth-modal{
  max-width: 700px !important;
}
/* .modal-content{
  border-radius: 20px !important;
} */
.quality:hover{
  cursor: pointer;
  transform: scale(1.03);
  transition: all 0.1s ease;
}
/* .modal-backdrop {
background-color:  transparent !important;
} */
/* .modal-backdrop {
  position: relative !important;
  background-color: transparent !important;
} */
.btn{
  background-color: transparent;
  border: none;
}
.btn:hover{
border: 0px;
}
.btn:focus{
  border: 0px;
  
  }
  /* HomePage explore */
  .bg-explore{
background-color: rgba(220, 167, 79, 0.8);
position: relative;
  }
  .bg-explore .txt{
    font-family: 'Montserrat-M500';
    font-style: normal;
font-size: 30px;
line-height: 37px;
color: #FFFFFF;
  }
  .bg-explore .postion{
    position: absolute;
  }
  .bg-explore .txt2{
    font-family: 'Montserrat-Reg';
font-style: normal;
font-size: 18px;
line-height: 22px;
color: #404040;
  }
  .view-all{
    border: none;
    font-family: 'Montserrat-M500';
    font-style: normal;
font-size: 16px;
line-height: 20px;
padding-inline: 50px;
    padding-top: 10px;
    padding-bottom: 10px;
background: #404040;
color: #FFFFFF
  }
  .view{
    border: 1px solid #000000 !important;
    border-radius: 4px;
    padding-left: 32px;
    padding-right: 32px;
    padding-top: 12px;
    padding-bottom: 12px;
  }
  .center{
    margin: auto;
  }
  .explore-img{
    height: 100% !important;
  }
  /* section2  */
  .padding-img{
    padding-top: 88px;
  padding-bottom: 88px;
  }
  .img-txt{
    text-align: center;
    font-family: 'Montserrat-M500';
    font-style: normal;
font-size: 24px;
line-height: 29px;

color: #404040;
  }
  /* section 3 */
  .overflow{
    overflow: scroll;
  }
  #style-2{
    overflow-y: hidden;
  }
  .react-horizontal-scrolling-menu--scroll-container {
    overflow-x: hidden;
  }
  .scroll {  
     display: flex;
        overflow-x: auto;
      }
      #style-2::-webkit-scrollbar {
        display: none;
      }
      
      /* Hide scrollbar for IE, Edge and Firefox */
      #style-2:focus {
        -ms-overflow-style: none;  
        scrollbar-width: none; 
        display: block;
      }
/* #style-2::-webkit-scrollbar-track
{
    border-radius: 4px;
    background-color: #F5F5F5;
}

#style-2::-webkit-scrollbar
{ height: 6px;
    width: 2px;
    background-color: #F5F5F5;
} */

#style-2::-webkit-scrollbar-thumb
{  

    border-radius: 10px;
    background-color: #b3ada7;
}

 .brand-txt{
  font-family: 'Montserrat-M500';
font-style: normal;
font-size: 32px;
line-height: 39px;
text-align: center;

color: #FFFFFF;
  padding-bottom: 20px;
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translate(-50%, -50%);
  
 }
 .brand-txt2{
  font-family: 'Montserrat-SemiB';
font-style: normal;
font-size: 28px;
line-height: 39px;
text-align: center;

color: #FFFFFF;
  padding-bottom: 20px;
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  
 }
 .text-decoration{
   text-decoration: none !important;
 }
 .designer-title{
 
  font-family: 'Montserrat-M500';
font-style: normal;
font-size: 45px;
line-height: 55px;
/* identical to box height */

text-transform: uppercase;

color: #C6AC96;
 }
 /* auth */
 .bg-auth{
  background: #FCFCFC;
 }
 .bg-auth .txt{
  font-family: 'Montserrat-Reg';
  font-style: normal;
  font-size: 45px;
  line-height: 55px;
  color: #404040;
 }
 .bg-auth .txt-2{
  font-family: 'Montserrat-L300';
  font-style: normal;

  font-size: 24px;
  line-height: 29px;
  
  color: #404040;
 }
 .bg-auth .txt-3{
  font-family: 'Montserrat-L300';
  font-style: normal;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */
  
  text-decoration-line: underline;
  
  color: #404040;
 }
 .bg-auth .txt-3:hover{
 cursor: pointer;
 }
 .watch{
  height: 292px;
 }
 .bg-luxury{
  background: linear-gradient(269.87deg, rgba(130, 222, 228, 0.83) 0.39%, #027B84 98.6%);
 }
 .bg-luxury .txt{
  font-family: 'Montserrat-Reg';
  font-style: normal;
  font-size: 35px;
  line-height: 43px;
  text-align: center;
  
  color: #FFFFFF;
 }
 .bg-luxury .view{
  font-family: 'Montserrat-Reg';
  font-style: normal;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */
  
  text-align: center;
  text-decoration-line: underline;
  
  color: #FFFFFF;
 }
 .bag{
  width: 276px;
  height: 370.36px;
 }
 .signup-offer .txt1{
  font-family: 'Montserrat-M500';
  font-style: normal;
  font-size: 24px;
  line-height: 29px;
  
  color: #404040;
 }
 .signup-offer .txt2{
  font-family: 'Montserrat-L300';
  font-style: normal;
  font-size: 20px;
  line-height: 24px;
  color: #404040;
 }
 .btn-getOffer{
   padding-inline: 40px;
   padding-top: 8px;
   padding-bottom: 8px;
   border: none;
  background: #404040;
  font-family: 'Montserrat-Reg';
  font-style: normal;

  font-size: 24px;
  line-height: 29px;
  color: #FFFFFF;
 }
 .bg-offer{
  background: #F5F5F5;
 }
 /* Brand Page */
 .brand-logo{
   /* padding-top: 142px; */
   font-family: 'Montserrat-M500';
   font-style: normal;
font-size: 50px;
line-height: 61px;
color: #C6AC96;
 }
 .brand-logo2{
  /* padding-top: 142px; */
  font-family: 'Montserrat-M500';
font-style: normal;
font-size: 50px;
line-height: 61px;
color: #404040;
}
 .letter{
  font-family: 'Montserrat-Reg';
  font-style: normal;

  font-size: 65px;
  line-height: 77px;
  
  color: #C6AC96;
 }

 .padding-top{
   padding-top: 100px;
 }
 
 @media screen and (min-width: 1200px) {
 
  .centered2 {
    top: 50%;
    left: 36%;
    transform: translate(-50%, -50%);
    position: absolute;
  }
.centered2Ar {
    top: 50%;
    /* left: 0%; */
    right: -6%;
    transform: translate(-50%, -50%);
    position: absolute;
}
  .howtoselltitle{
    font-family: 'Montserrat-M500';
    font-style: normal;
    font-weight: 500;
    font-size: 70px;
    line-height: 85px;
    letter-spacing: 0.11em;
    
    color: #000000;

  }
  .howtoselltitle2 {
    font-family: 'Montserrat-Reg';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 42px;

letter-spacing: 0.11em;

color: #000000;

  }

  
 }
 @media screen and (max-width: 1200px) {
  .centered2 {
    top: 50%;
    left: 36%;
    transform: translate(-50%, -50%);
    position: absolute;
  }
  .centered2Ar {
    top: 50%;
    /* left: 0%; */
    right: -6%;
    transform: translate(-50%, -50%);
    position: absolute;
}
  .howtoselltitle{
    font-size: 20px;
    line-height: 24px;
    font-family: 'Montserrat-M500';

  }
  .howtoselltitle2 {
    font-family: 'Montserrat-Reg';


    font-size: 12px;
    line-height: 19px;


  }

  
 }
 @media screen and (max-width: 414px) {
  /* .carousel-product{
    min-height: 400px;
    max-height: 100%;
  } */
 .username-handBag{
  font-family: 'Montserrat-Reg';
font-style: normal;

font-size: 21px;
line-height: 29px;

color: #000000;
}
}
/* 

 
*/
@media screen and (min-width: 1450px) {
  
  .margin-inline-banner{
    margin-right: 3rem!important;
    margin-left: 3rem!important;
  }
  .padding-iniline-swiper{
    padding-inline:0px;
}
}
@media screen and (max-width: 1450px) {
  .margin-inline-banner{
    margin-right: 3rem!important;
    margin-left: 3rem!important;
  }
}
@media screen and (max-width : 970px)
{
  .margin-inline-banner{
    margin-right: 0rem!important;
    margin-left: 0rem!important;


  }
  .textBanner
  {
    font-size: 16px!important;
  }
 
  .subtextBanner
  {
    font-size: 12px!important;
  }
  .bannerBox
  {
width: 350px!important;
  }
}

@media screen and (max-width: 1100px) {

  .link-text{
    font-family: 'Montserrat-M500';
    font-style: normal;
    font-size: 14px;
    line-height: 11px;
    /* identical to box height */
    
    
    color: #404040;
   
 }
  .tab-bottom{
    visibility: block;
  }
 
}
@media screen and (max-width: 992px) {

  .tab-bottom{
    display: block;
  }
  .margin-b5{
    margin-bottom: 5.4rem;
  }
  .bg-tabBottom{
    padding-top: 15px;
    padding-bottom: 15px;
    background-color: white;
   
  }
  .img-tabBottom{
    width: 38px;
  }

 .txt-tabBottom{
  font-family: 'Montserrat-M500';
  font-style: normal;
  font-size: 9px;
  line-height: 11px;
  text-decoration: none;
  color: #404040;

 }
}

@media screen and (max-width: 995px) {
  .flex-row-auth{
    flex-direction: column-reverse;  

  }
}
@media screen and (max-width: 872px) {

  .tab-bottom{
    display: block;
  }
  .margin-b5{
    margin-bottom: 5.4rem;
  }
  .bg-tabBottom{
    padding-top: 15px;
    padding-bottom: 15px;
    background-color: white;
   
  }
  .shadow-box {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); /* Shadow effect */

  }
  .img-tabBottom{
    width: 28px;
  }

 .txt-tabBottom{
  text-decoration: none;
  font-family: 'Montserrat-M500';
  font-style: normal;
  font-size: 9px;
  line-height: 11px;
  text-decoration: none;
  color: #404040;

 }
 }

.ul-font{
  font-family: 'Montserrat-M500';
  font-style: normal;
  font-size: 24px;
  line-height: 29px;
  
  color: #404040;
}

.li-font{

  font-family: 'Montserrat-M500';
  font-style: normal;
  font-size: 18px;
  line-height: 22px;
  color: #404040;

}
.show {
  color: #404040 !important;
}
.show:hover{
  color: #C6AC96 !important;
}
.hide {
  color: #404040 !important;
}
.hide:hover{
  color: #C6AC96 !important;
}
.btn-en{
  border: none;
  background-color: transparent;
}
.search-toogle{
 
  display: inline;
}

.search-toogle:focus{
border-color: transparent;
}
/* Product */
#sideFilter{
  width: 25%;
  padding: 10px;
  margin: 0;
  float: left;
}
#products{
  width: 75%;
  padding: 10px;
  margin: 0;
  float: right;
}

@media(min-width:991px){
 
  #mobile-filter{
      display: none;
  }
}
@media(min-width:768px) and (max-width:991px){

  #mobile-filter{
      display: none;
  }
}
@media(max-width:992px){

  #sideFilter{
      width: 100%;
      padding: 10px;
      margin: 0;
      float: left;
  }
  #products{
      width: 100%;
      padding: 0px;
      margin: 0;
      float: right;
  }
  
  .card-img-top{
      width: 230px;
      height: 210px;
  }
  .list-group-item{
      padding: 3px;
  }
  .offset-1{
      margin-left: 8%;
  }
  .filter{
      display: block;
      margin-left: 17%;
      margin-top: 8px;
  
      border: none !important;
      background-color: transparent;
  }
  #sideFilter{
      display: none;
  }
  #mobile-filter{
      padding: 10px;
  }
}

.title2-product{
  font-family: 'Montserrat-M500';
font-style: normal;
font-size: 17px;
text-align: start;
color: #1A1818;
}
/* email */

 /* .title2-product{
  font-family: 'Montserrat-M500';
font-style: normal;
font-size: 17px;
text-align: center;
color:black ;
text-transform: uppercase;
}  */
/* email */
/*  */
.title3-product{
  font-family: 'Montserrat-Reg';
  padding-bottom: 2px;
font-style: normal;
font-size: 13px;
text-align: start;
color: #707070;
}
/* email */
/* .title3-product{
  font-family: 'Montserrat-Reg';
font-style: normal;
font-size: 13px;
text-align: center;
color: black;
} */
.title4-product{
  font-family: 'Montserrat-M500';
font-style: normal;
font-size: 27px;
margin-top: 5px;
padding-bottom: 7px;

text-align: start;
color: #C6AC96;
}
.title5-product{
  font-family: 'Montserrat-Reg';
font-style: normal;

font-size: 10px;
/* line-height: 12px; */
text-align: start;
color: #404040;
}
.title7-product{
  font-family: 'Montserrat-Reg';
font-style: normal;
font-size: 13px;
text-align: start;
color: #707070;
}
.SOLD{
  font-family: 'Montserrat-M500';
  font-style: normal;
  font-size: 17px;
  line-height: 26px;
 
  
  text-align: start;
  
  color: #B70101;


}
.title6-product-unsold{
  font-family: 'Montserrat-M500';
  font-style: normal;

  font-size: 12px;
  /* line-height: 10px; */
  position: absolute;
  color: #1A1818;
bottom: 1.7%;
}
.title6-product{
  font-family: 'Montserrat-M500';
  font-style: normal;

  font-size: 12px;
  /* line-height: 10px; */
  position: absolute;
  bottom: 0%;
  color: #1A1818;
}
.heart-product{
  top: 0;
  right: 0;
  padding-right: 39px;
  padding-top: 100px;
  background-image: url(./assets/images/Product/heart.png);
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  border: none;
}
.card-product{
  width: 288.85px !important;
  height: 470px   !important;
  background: rgba(255, 255, 255, 0.24);
  box-shadow: 0 7px 18px -1px rgba(0,0,0,.17);
}
/* email */
/* .card-product{
  border-radius: 0px !important;
  width: 230px !important;
  height: 230px !important;
 
} */
/* .card{
  border-radius: 0px !important;
} */
.bg-product{
  background: rgba(198, 150, 153, 0.32);

}
.bg-product .txt{
  font-family: 'Montserrat-Reg';
font-style: normal;

font-size: 25px;
line-height: 30px;
text-transform: uppercase;

color: #3C121B;
}
.bg-product .txt2{
  font-family: 'Montserrat-L300';
  font-style: normal;
  font-size: 14px;
  line-height: 17px;
  text-transform: capitalize;
  color: #404040;
}
.shop-btn{
  font-family: 'Montserrat-Reg';
font-style: normal;

font-size: 20px;
line-height: 24px;
text-transform: capitalize;
background: #FFFFFF;
color: #404040;
padding-inline: 30px;
padding-top: 7px;
padding-bottom: 7px;
border: none;
}
/* Filter */
.filters{
  background: white;
  padding-top: 10px;
  padding-bottom: 10px;
}
.title-accordion{
  background-color: transparent !important;
  border: 0 !important;
  text-decoration: none !important;
  font-family: 'Montserrat-M500'!important;
font-style: normal;

font-size: 16px !important;
line-height: 29px !important;

text-transform: capitalize !important;
color: #1A1818 !important;
  box-shadow: none !important;
border-color: transparent !important;
}
.title-accordionfilter{
  
  background-color: transparent !important;
  border: 0 !important;
  text-decoration: none !important;
  font-family: 'Montserrat-M500'!important;
font-style: normal;

font-size: 16px !important;
line-height: 39px !important;
color: #1A1818 !important;
border: 0px !important;
text-transform: capitalize !important;
color: #1A1818 !important;
  box-shadow: none !important;
border-color: transparent !important;
}

span.filtermodeltitle:hover {
  border-bottom: 1px solid #C6AC96;
}
.title-accordionTops{
  border: 0 !important;
  text-decoration: none !important;
  font-family: 'Montserrat-Reg'!important;
font-style: normal;

font-size: 15px !important;
line-height: 22px !important;

text-transform: capitalize !important;
color: #1A1818 !important;
  box-shadow: none !important;
border-color: transparent !important;
}
.title-accordionsize{
  background-color: transparent !important;
  border: 0 !important;
  text-decoration: none !important;
  font-family: 'Montserrat-Reg'!important;
font-style: normal;

font-size: 15px !important;
line-height: 22px !important;
text-transform: capitalize !important;
color:  #1A1818 !important;
  box-shadow: none !important;
border-color: transparent !important;
}
.title-accordionsize:not(.collapsed)::after {
  background-image: url(./assets/images/arrow_down.svg) !important;
  transform: rotate(-180deg);
  background-size: 0rem !important;
}
.title-accordion::after{
  height: 0.75rem !important;

}

.title-accordionfooter:not(.collapsed)::after {
  background-image: url(./assets/images/arrow-up.svg) !important;
 height: 0.75rem !important;
 background-size:inherit !important; 
 /* transform:  rotate(360deg); */


}
.title-accordionfooter::after {
  background-image: url(./assets/images/arrowdown.svg) !important;
  /* background-image: url(./assets/images/arrow_down.svg) !important; */

  background-size:inherit !important;
  transform: rotate(-180deg) !important;

}

.title-accordionfilter:not(.collapsed)::after {
   background-image: url(./assets/images/arrow-up.svg) !important;
  height: 0.75rem !important;
  background-size:inherit !important; 
  /* transform:  rotate(360deg); */


}
.title-accordion:not(.collapsed)::after {
  background-image: url(./assets/images/arrow_down.svg) !important;
  transform: rotate(-180deg);
  height: 0.75rem !important;
}

.title-accordionTops:not(.collapsed)::after {
  background-image:none!important;
  transform: rotate(-180deg);
}
.title-accordionTops:not(.collapsed)::before {
  /* background-image: url(./assets/images/Product/before2.svg) !important; */
  transform: rotate(180deg);
  background-size: 1.25rem;
}
.title-accordionTops::after  {
  background-image: none !important;
}
.title-accordionTops::before {
  width: 1.25rem;
    height: 1.25rem;
    /* margin-left: auto; */
    content: "";
    flex-shrink: 0;
    background-repeat: no-repeat;
    background-size: 1.25rem;
    /* transition: transform .2s ease-in-out; */
    background-image: url(./assets/images/Product/before2.svg);
  transform: rotate(-360deg);
  width: 1.25rem;
  height: 1.25rem;
}
.title-accordionTops2 {
  width: 1.25rem;
    height: 1.25rem;
    /* margin-left: auto; */
    content: "";
    flex-shrink: 0;
    background-repeat: no-repeat;
    background-size: 1.25rem;
  transform: rotate(-180deg);
  width: 1.25rem;
  height: 1.25rem;
}
.title-accordion::after {
  background-image: url(./assets/images/arrow_down.svg) !important;
  background-size:inherit !important;
}
.title-accordionfilter::after {
  background-image: url(./assets/images/arrowdown.svg) !important;
  /* background-image: url(./assets/images/arrow_down.svg) !important; */

  background-size:inherit !important;
  transform: rotate(-180deg) !important;

}
.txt-accordion3::after{
  background-image: url(./assets/images/Product/Plus.svg) !important;
  /* background-size:inherit !important; */
}
.txt-accordion3:not(.collapsed)::after{
  background-image:url(./assets/images/Product/Subtract.png) !important;
}
/* .accordion-plus::after {
  background-image: url(./assets/images/arrowdown.png) !important;
  background-size:inherit !important;
} */

.seller-button:focus{
  box-shadow: none !important;
  background-color: transparent !important;
}
 /* .accordion-bg::after{
  background-image:url(./assets/images/HomePage/ForwardFooter.png) !important ;
} */



.accordion-bg::after {
  content: "";
  background-image: url(./assets/images/HomePage/ForwardFooter.png) !important;
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 20px; /* Adjust the width as needed */
  height: 20px; /* Adjust the height as needed */
  transition: transform 0.3s;
  transform:  rotate(-90deg);
}

/* .accordion-bg:not(.collapsed)::after {
  transform: translateY(-50%) rotate(180deg);
} */


/* .accordion-bg::after {
  content: url(./assets/images/HomePage/ForwardFooter.png) !important;;

  position: absolute;
  right:0;
  top: 45%;
  bottom: 50%;

}

.accordion-bg .show::after {
  content: url(./assets/images/HomePage/ForwardFooter.png) !important;;
  position: absolute;
  right:0;
top: 45%;
 bottom: 50%;
 transform: rotate(180deg) !important;

} */

@media (min-width: 576px){
 
.modal-navbar {
    max-width: 100% !important;
}
}
.txt-accordion{
  font-family: 'Montserrat-L300';
  font-style: normal;
  font-size: 18px;
  line-height: 22px;
  text-transform: capitalize;
  text-decoration: none;
  color: #404040;
}
.txt-accordion:focus {
  color: #97816d;
}
.txt-accordion:hover{
  color: #97816d;
}
.accordion .items{
  border: none !important;
}
.input-prod-width{
  height: 50px !important;
  width: 90% !important;
}
.search-prod{
  border-left-style:none !important;
  display: block;

  background: rgba(237, 237, 237, 0.24) !important;
}
.search-prod::placeholder{
  font-family: 'Montserrat-L300'!important;
  font-style: normal;
  font-size: 19px!;
  line-height: 23px;
  text-transform: capitalize;
  color: #404040 !important;
}
.search-prod:focus{
  box-shadow: none !important;
  border-color: #d6d4d4 !important;
 }
.input-bg-product{
  border-right-style:none !important;

  background: rgba(237, 237, 237, 0.24) !important;

}
.dot{
  width: 38px;
  height: 38px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
}
.text-dot{
  font-family: 'Montserrat-ELight200';
font-style: normal;

font-size: 18px;
line-height: 22px;
text-transform: capitalize;
color: #404040;
}
.pagination-text:focus{
  border-radius:0px !important;

  font-family: 'Montserrat-L300'!important;
font-style: normal;

font-size: 28px !important;
line-height: 37px !important;
/* identical to box height */
box-shadow: none !important;
text-transform: capitalize;
background: #C6AC96 !important;
border: 0.4px solid #C6AC96 !important;
color: #FFFFFF !important;
}
.pagination-text{

  padding-inline: 23px !important;
  border-radius:0px !important;
  font-family: 'Montserrat-L300'!important;
font-style: normal;

font-size: 28px !important;
line-height: 37px !important;
/* identical to box height */
box-shadow: none !important;
text-transform: capitalize;
background: transparent !important;
border: 0.4px solid #404040 !important;
color: #404040 !important;

}
.pagination-forword:focus{
  border-radius:0px !important;

  font-family: 'Montserrat-L300'!important;
font-style: normal;

font-size: 28px !important;
line-height: 37px !important;
/* identical to box height */
box-shadow: none !important;
text-transform: capitalize;
background: #C6AC96 !important;
color: #FFFFFF !important;
border: none !important;
}
.pagination-forword{
  border-radius:0px !important;

  font-family: 'Montserrat-L300'!important;
font-style: normal;

font-size: 28px !important;
line-height: 37px !important;
/* identical to box height */
box-shadow: none !important;
text-transform: capitalize;
background: transparent !important;
color: #FFFFFF !important;
border: none !important;
}
.img-forward{
  height: 37px;}


  .modal.modal-fullscreen .modal-dialog,
.modal.modal-fullscreen .modal-content {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.modal.modal-fullscreen .modal-dialog {
  margin: 0;
  width: 100%;
  animation-duration:0.6s;
}
.modal.modal-fullscreen .modal-content {
  border: none;
  -moz-border-radius: 0;
  border-radius: 0;
  -webkit-box-shadow: inherit;
  -moz-box-shadow: inherit;
  -o-box-shadow: inherit;
  box-shadow: inherit;
}
.modal.modal-fullscreen.force-fullscreen .modal-body {
  padding: 0;
}
.modal.modal-fullscreen.force-fullscreen .modal-header,
.modal.modal-fullscreen.force-fullscreen .modal-footer {
  left: 0;
  position: absolute;
  right: 0;
}
accordion-button:focus {
  border-color: #212529 !important;
}
.option-selected{
font-family: 'Montserrat-M500'!important;
font-style: normal !important;

font-size: 17px !important;
line-height: 33px !important;
width: min-content !important;
text-transform: capitalize !important;
background-color: transparent !important;
border: none !important;
color: #404040 !important;
padding-top: 13px !important;
padding-bottom: 13px !important;
}
.option-selected:focus{
  box-shadow: none !important;
}

.accordion-bg{
  position: relative;
  background-color: transparent !important;
}
.accordion-bgg{
  position: relative;
  background-color: transparent !important;
}
.border-accordion{
  border-bottom: 0.4px solid #FFFFFF !important;
  border-top: 0.4px solid #FFFFFF !important;
}
.border-Bottom{
  border-bottom: 0.4px solid #CAC4D0 !important;

}
.accordion-bg:focus{
  box-shadow: none !important;
}
.subtxt-accordion-footer {
  font-family: 'Montserrat-Reg';
 cursor: pointer;
  font-size: 14px !important;
  text-decoration: none ;
  /* color: #C4C4C4; */
  color: #FFFFFF;
  display: grid;
margin-bottom: 15px;
}
.subtxt-accordion-footer:hover{
  color: #ede5e5;
}
.select-location-acc{
  font-family: 'Montserrat-Reg' !important;
  font-style: normal;

  font-size: 12px !important;
  line-height: 15px;
width: min-content !important;
  background-color: transparent !important;
border: none !important;
color: #FFFFFF !important;
background: url(../src/assets/images/ChevronDown.png) no-repeat !important;
background-position: right !important;
  color: #FFFFFF !important;
}
.code-accordion{
  text-decoration: none !important;
  font-family: 'Montserrat-Reg' !important;
font-style: normal !important;

font-size: 12px !important;
line-height: 10px;
color: #C4C4C4 !important;
}
.modal.left .modal-dialog,
.modal.right .modal-dialog {
  position: fixed;
  width: 320px;
  height: 100%;
  -webkit-transform: translate3d(0%, 0, 0);
      -ms-transform: translate3d(0%, 0, 0);
       -o-transform: translate3d(0%, 0, 0);
          transform: translate3d(0%, 0, 0);
}

.modal.left .modal-content,
.modal.right .modal-content {
  height: 100%;
  overflow-y: auto;
}

.modal.left .modal-body,
.modal.right .modal-body {
  padding: 15px 15px 80px;
}

/*Left*/
.modal.left.fade .modal-dialog{
  -webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
     -moz-transition: opacity 0.3s linear, left 0.3s ease-out;
       -o-transition: opacity 0.3s linear, left 0.3s ease-out;
          transition: opacity 0.3s linear, left 0.3s ease-out;
}

.modal.left.fade.in .modal-dialog{
  left: 0;
}
.fade{
  background-color:rgba(70, 70, 70, 0.53) !important; 
}
.border-radius{
border-radius: 0px !important;
}
.filters{
  font-family: 'Montserrat-M500';
font-style: normal;

font-size: 16px;
line-height: 29px;
text-transform: capitalize;

color: #1A1818;
}
.description-Title{
  font-family: 'Montserrat-M500';
font-style: normal;
font-size: 24px;
line-height: 29px;

color: #C6AC96;
}

.item{
  font-family: 'Montserrat-M500';
font-style: normal;
font-size: 24px;
line-height: 29px;

color: #C6AC96;
}

.accordion-footerTitle{
  font-family: 'Montserrat-M500'!important;
  font-style: normal !important;

font-size: 14px !important;
line-height: 17px !important;
  /* text-transform: uppercase; */
  
  color: #FFFFFF !important;
  /* color: #000000; */
}




.list{
  font-family: 'Montserrat-M500';
font-style: normal;
font-size: 14px;
line-height: 17px;
color: #404040;
}


.discover-link{
  font-family: 'Montserrat-Reg';
font-style: normal;

font-size: 12px;
line-height: 17px;
text-decoration-line: underline;
color: #000000;
}
.badge-icon{
  width: 34.49px;
  height: 42px;
}
.location-img{
  width: 26px;
  height: 26px;
}
.price-description{
  font-family: 'Montserrat-Reg';
font-style: normal;
font-size: 14px;
line-height: 17px;
color: #1A1818;
}
.signupTitle{
  font-family: 'Montserrat-M500';
font-style: normal;
font-size: 14px;
line-height: 33px;
color: #1A1818;
text-align: center;
}
.modal-signup{
  max-width: 900px !important;
}
.signup-image{
  width: 736px;
  height: 1103px;
}
.signupdesc{
font-family: 'Montserrat-Reg';
font-style: normal;

font-size: 12px;
line-height: 24px;
text-align: center;
color: #404040;
}
.btn-fb{
  
background: #1877F2;
border-radius: 10px;
border: none ;
font-family: 'Helvetica-Bold-Font700';

font-size: 10px;
line-height: 23px;
color: #FFFFFF;
padding-top: 5px;
padding-bottom: 5px;

padding-inline: 20px;
}
.fb{
  height: 14px;
}

.btn-google{
  border: 0.5px solid #404040;
  background: rgba(217, 217, 217, 0.00);
background: #FFFFFF;
font-family: 'Montserrat-M500';
align-items: center;
width: 100%;
height:40px;
justify-content: center;
display: flex;
font-size: 12px;
color: #404040;
text-transform: uppercase;
align-items: center;
}
.btn-googleotp{
  border: 0.5px solid #404040;
  background: rgba(217, 217, 217, 0.00);
background: #FFFFFF;
font-family: 'Montserrat-M500';
align-items: center;
width: 100%;
height: 30px;
justify-content: center;
display: flex;
font-size: 12px;
color: #404040;
text-transform: uppercase;
align-items: center;
}
.or{
  font-family: 'Montserrat-M500';
font-style: normal;
font-size: 12px;
text-align: center;
color: #404040;
}
.emailLabel{
  font-family: 'Montserrat-M500';
font-style: normal;
font-size: 12px;
text-align: center;
color: #404040;
}
.form-email{
  border-top: none !important;
  border-right: none !important;
  border-left: none !important;
  border-bottom: 0.5px solid black !important;
  border-radius: 0px !important;

}

.form-email:focus{
  border-top: none !important;
  border-right: none !important;
  border-left: none !important;
  border-bottom: 0.5px solid black !important;
  border-radius: 0px !important;}
.form-email:focus{
  box-shadow: none !important;
}
.form-email::placeholder{
  color: #404040;
font-family: 'Montserrat-M500';
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: normal;
}
.required{
  font-family: 'Montserrat-M500' !important;
margin-top: 0px !important;
font-size: 10px !important;
line-height: 20px;
color: #808080 !important;
}
.PhoneInputInput{
  border-top: none !important;
  border-right: none !important;
  border-left: none !important;
}
.selected-flag{
  background-color: white !important;
}
.react-tel-input .flag-dropdown {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-bottom: none !important;

}
.react-tel-input .form-control{
  border: 0.5px solid #404040 !important;
  
  border-radius: 0px !important;
  height: auto !important;
  padding-left: 30px;
}
.react-tel-input .form-control {
width: 100% !important;
  /* border-top: none !important;
  border-left: none !important;
  border-right: none !important; */
}
.form-control :focus{
  box-shadow: none !important
}
.react-tel-input .form-control::placeholder{
  font-family: 'Montserrat-Reg'!important;
font-style: normal !important;

font-size: 14px !important;
line-height: 29px !important;
color: #404040 !important;
}
.btn-join{
  font-family: 'Montserrat-M500';
font-style: normal;
font-size: 12px;
color: white;
border: none !important;
width: 100%;
height: 40px;
justify-content: center;
display: flex;
align-items: center;
border: 0.5px solid #C4C4C4;
background: #C4C4C4;
text-transform: uppercase;
}
.btn-join2{
  font-family: 'Montserrat-M500';
font-style: normal;
font-size: 15px;
color: #808080;
border: none !important;
width: 100%;
padding-top: 10px;
padding-bottom: 10px;
background: #E3E3E3;
border-radius: 10px;
}
.btn-join-black{
  font-family: 'Montserrat-M500';
font-style: normal;
font-size: 12px;
color: #ffffff;
width: 86%;
height: 37px;
border: 0.5px solid #C4C4C4;
background: #C4C4C4;
}
.btn-join-black-verify{
  font-family: 'Montserrat-M500';
font-style: normal;
font-size: 12px;
color: #ffffff;
border: none !important;
width: 86%;
height: 37px;
background: #C4C4C4;
}
.btn-join-black-verifyotp{
  font-family: 'Montserrat-M500';
  font-style: normal;
  font-size: 12px;
  color: #ffffff;
  border: none !important;
  width: 86%;
  height: 30px;
  background: #C4C4C4;
}
.swiper{
  z-index: 0 !important;
}
.error-text{
  font-family: 'Montserrat-M500' !important;
  margin-top: 0px !important;
  font-size: 10px !important;
  line-height: 20px;
  color: red;
  padding-right: 10px;
}
.error-text-coupon{
  font-family: 'Montserrat-M500' !important;
 
    font-size: 13px !important;
    line-height: 24px;
  margin-top: 0.25rem;
}
/* .btn-join:focus.btn-join:hover{
  color: #FFFFFF;
  background: #C6AC96;
} */
.login-link{
  color: #404040;
  font-family: 'Montserrat-M500';
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration-line: underline;
}

.link:hover{
  color: #C6AC96;
}
.login-linkforgotpass{
  color: #404040;
  font-family: 'Montserrat-M500';
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration-line: underline;
}

.learn-signup{
  font-family: 'Montserrat-M500';
font-style: normal;
font-size: 11px;
line-height: 22px;
color: #404040;
}
.close-modal{
  border: none !important;
  background-color: transparent !important;
}
.close-txt{
  font-family: 'Montserrat-M500';
font-style: normal;
font-size: 14px;
line-height: 22px;
color: #404040;

}

.border-none{
  border-radius: 0px !important;
}
.detail-txt{
  text-transform: uppercase;
  color: #1A1818;
  text-align: center;
  font-family: 'Montserrat-M500';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 23px;
}
.checkbox-txt{
  font-family: 'Montserrat-Reg';
  font-style: normal;
  font-size: 11px;
  line-height: 20px;
  
  color: #808080;
}
.show-filter{

  font-family: 'Montserrat';
  font-style: normal;
  font-size: 10px;
  line-height: 24px;
  text-align: center;
  color: #808080;
}

/* .form-check-input:checked {
  background-color: #97816d !important;
  border-color: #97816d !important;
} */
.form-check-input:focus{
  box-shadow: none !important;
}
.home-link{
  font-family: 'Montserrat-Reg';
font-style: normal;

font-size: 8px;
line-height: 10px;
color: #959595;
text-decoration: none;
}
.home-link:focus .home-link:hover{
  color: #97816d !important;

}
.prod-title{
font-family: 'Montserrat-Reg';
font-style: normal;

font-size: 24px;
line-height: 29px;

color: #C6AC96;

}
@media screen and (min-width: 1024px) {

}
@media screen and (max-width: 1024px) {
  
}
 @media screen and (max-width: 992px) {
 
  .txtProduct{
    font-family: 'Montserrat-M500';
    font-style: normal;
font-size: 11px;
line-height: 13px;
text-align: center;

color: #959595;
  }
  .bg-products {
    background: #F3F3F3;
    max-width: 100% !important;
  }
  .close-filter{
    background-color: transparent;
    border: none ;
  }
  .option-selected{
    background-color: white !important;
    font-style: normal !important;
  font-family: "Montserrat-M500" !important;
  font-size: 12px !important;
  line-height: 15px !important;
  color: #404040 !important;
  background-image: none !important;
  border: 0.5px solid #F5F5F5 !important;
  width: 100% !important;
  }
  
  
  .btn-selected{
    background-color: white ;
    border: 0.5px solid #F5F5F5;
    padding-top: 13px !important;
    padding-bottom: 13px !important;
   }
   .clear-filter{
    font-family: 'Montserrat-M500';
    font-style: normal;
  font-size: 11px;
  line-height: 13px;
  
  color: #959595;
  }

}

.clear-filter{
  font-family: 'Montserrat-M500';
font-style: normal;
font-size: 11px;
line-height: 13px;

color: #959595;
}
[type=submit]:not(:disabled), .btn-join:not(:disabled) {
  color: #FFFFFF;
  background: #C6AC96;
}
[type=submit]:not(:disabled), .btn-join2:not(:disabled) {
  color: #FFFFFF;
border: 0.5px solid #1A1818;
background: #1A1818;


}
[type=submit]:not(:disabled), .btn-send:not(:disabled) {
  color: #FFFFFF;
border: 0px;
background: #C6AC96;


}
#submit0:not(:disabled), .btn-join-black-verify:not(:disabled){
  color: #FFFFFF;
  background: #000000;
}
#submit0:not(:disabled), .btn-join-black-verifyotp:not(:disabled){
  color: #FFFFFF;
  background: #000000;
}
#verifyOtp:not(:disabled), .btn-join-black-verify:not(:disabled){
  color: #FFFFFF;
  background: #000000;
}
#verifyOtp:not(:disabled), .btn-join-black-verifyotp:not(:disabled){
  color: #FFFFFF;
  background: #000000;
}
/* [type=submit]:not(:disabled), .btn-join2:not(:disabled) {
  color: #FFFFFF;
  background: #C6AC96;
} */
.price-payment-details{
  font-family: 'Montserrat-M500';
  font-size: 18px;
  line-height: 22px;
  color: #404040;
}

.accordion-button:not(.collapsed) {
  color: none !important;
  background-color: transparent !important;
  box-shadow: none !important;
}
.accordion-buttonfaq:not(.collapsed)::after{
  background-image:url(./assets/images/Product/before2.svg) !important;
  transform: rotate(180deg);
}
.accordion-buttonfaq::after{
  background-image:url(./assets/images/Product/before2.svg) !important;
}
.addToBagModal{
  z-index: 1;

}
.success-msg{
  font-family: 'Montserrat-M500';
font-style: normal;
font-size: 15px;
line-height: 37px;
color: #404040;
}
.addToBag-dialog{
  max-width: 700px !important;}

  .rmCoupon-dialog{
    max-width: 400px !important;
    background-color: white;
  }
  .bagModal-txt1{
    font-family: 'Montserrat-Reg';
font-style: normal;

font-size: 16px;
line-height: 26px;
color: #000000;
  }
  .bagModal-txt2{
    font-family: 'Montserrat-Reg';
font-style: normal;

font-size: 13px;
line-height: 22px;
color: #404040;
  }
  .bg-addToBag{
    background: #EDEDED;
  }
  .bagModal-price{
  font-family: 'Montserrat-SemiB';
  font-style: normal;
  
  font-size: 14px;
  line-height: 22px;
  text-decoration-line: line-through;
  color: #404040;
  }
  .bagModal-price2{
    font-family: 'Montserrat-SemiB';
    font-style: normal;
    font-size: 17px;
    line-height: 26px;
    color: #DD0000;
    }
    .btn-continue{
      background: rgba(255, 255, 255, 0.24);
      border: 2px solid #404040;
      font-family: 'Montserrat-M500';
      font-style: normal;
      font-size: 11px;
      line-height: 26px;
      color: #404040;
      padding-right: 25px;
      padding-left: 25px;
      padding-top:5px;
      padding-bottom:5px;
    }
  .btn-proceed{
    font-family: 'Montserrat-M500';
    font-style: normal;
    font-size: 11px;
    line-height: 26px;
    color: #FFFFFF;
    background: #404040;
    padding-right: 25px;
    padding-left: 25px;
    padding-top:5px;
    padding-bottom:5px;
  }
  .bg-mybag{
    background-color: #EEEEEE;
  }
  .back-Link{
    font-family: 'Montserrat-Reg';
font-style: normal;

font-size: 20px;
line-height: 29px;
text-transform: capitalize;
text-decoration: none;
color: #404040;
  }
  .item-nbr{
    font-family: 'Montserrat-Reg';
font-style: normal;

font-size: 20px;
line-height: 29px;


color: #404040;
  }

.usernameMyBag{
  font-family: 'Montserrat-M500';
font-style: normal;
font-size: 18px;
line-height: 22px;
text-decoration-line: underline;
color: #404040;
}
.hr{
  border: 1px solid #404040 !important;
}
.img-bag{
  width: 117px;
  height: 178px;
}
.brandName-mybag{
  font-family: 'Montserrat-M500';
font-style: normal;
font-size: 18px;
line-height: 22px;
color: #404040;
}
.description-mybag{
  font-family: 'Montserrat-Reg';
font-style: normal;

font-size: 18px;
line-height: 22px;
color: #404040;
}
.price-mybag{
  font-family: 'Montserrat-M500';
font-style: normal;
font-size: 21px;
line-height: 26px;
color: #404040;
}
.price-mybag2{
  font-family: 'Montserrat-SemiB';
  font-size: 11px;
  line-height: 11px;
  letter-spacing: 0.11em;
  
  color: #000000;
}
.auth{
  font-family: 'Montserrat-Reg';
font-style: normal;

font-size: 16px;
line-height: 20px;
color: #404040;
}
.priceAuth{
  font-family: 'Montserrat-M500';

font-style: normal;
font-size: 18px;
line-height: 22px;
color: #404040;
}
.learnMore{
  font-family: 'Montserrat-Reg';
font-style: normal;

font-size: 14px;
line-height: 17px;
text-decoration-line: underline;
color: #C6AC96;
}
.titleBill{
  font-family: 'Montserrat-B700';
font-style: normal;

font-size: 18px;
line-height: 26px;
color: #404040;
text-decoration: none;
}
.bill-itemNymber{
  font-family: 'Montserrat-M500';
font-style: normal;

font-size: 15px;
line-height: 22px;
color: #777777;
}
.bill-subTotalTxt{
  font-family: 'Montserrat-B700';
  font-style: normal;

  font-size: 16px;
  line-height: 26px;
  color: #404040;
}
.next-bill{
  font-family: 'Montserrat-M500';
font-style: normal;
font-size: 18px;
line-height: 26px;
background: #404040;
width: 100%;
border: none ;
padding-top: 8px;
padding-bottom: 8px;
color: #F8F8F8;
}
.padding-bottom{
  padding-bottom: 200px;
}


.catg-payment{
  font-family: "Montserrat-Reg";
  font-size: 18px;
  line-height: 22px;
  color: #404040;
}
.catg-prcie-payment{
  font-family: 'Montserrat-M500';
font-style: normal;
font-size: 18px;
line-height: 22px;
color: #404040;
}
.padding-inline{
  padding-right:12rem!important;
  padding-left: 12rem!important;
}

.payment-tiltle{
  font-family: 'Montserrat-M500';
font-style: normal;

font-size: 24px;
line-height: 29px;
text-transform: capitalize;
color: #404040;
/* color: #404040; */
}
.catg-item{
  font-family: 'Montserrat-M500';
font-style: normal;
font-size: 14px;
line-height: 22px;
color: #777777;
}
.catg-prcie2-payment{
  font-family: 'Montserrat-M500';
font-style: normal;
font-size: 14px;
line-height: 22px;
color: #777777;
}
.pay-total{
  font-family: 'Montserrat-M500';
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */
  
  
  color: #777777;
}
.total-pay{
  font-family: 'Montserrat-M500';
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */
  
  
  color: #777777;
}
.delivery-total{
  font-family: 'Montserrat-M500';
  font-size: 14px;
  line-height: 22px;
  /* identical to box height */
  
  
  color: #777777;
}
.total-delivery{
  font-family: 'Montserrat-M500';
  font-size: 14px;
  line-height: 22px;
  /* identical to box height */
  
  
  color: #777777;
}
.pay-payment{
font-style: normal;
font-family: 'Montserrat-M500';
font-size: 20px;
line-height: 29px;
color: #FFFFFF;
background: #C6AC96;
border: none;
padding-inline:150px ;
padding-top: 10px;
padding-bottom: 10px;

}
.payment-conditon{
  font-family: 'Montserrat-M500';
font-style: normal;
font-size: 12px;
line-height: 20px;
color: #B2B2B2;
}
.link-privacy{
  font-family: 'Montserrat-M500';

font-style: normal;
font-size: 12px;
line-height: 20px;
color: #B2B2B2;
}
.link-privacy:hover{
  color: #97816d;

}
.input-promo::placeholder{
  font-family: 'Montserrat-Reg'!important;
  font-style: normal !important;
  font-size: 18px!important;
  line-height: 22px!important;
  color: #777777 !important
}
.input-promo{
  padding: 1rem 2.29rem !important;
  border: 0px !important;
border-radius: 0px !important;
height: 100px;
}
.form-control:focus {
  box-shadow: none !important;
  /* border-color: #ced4da !important; */
}
.aplly-border{
  border: 0px !important;
  font-family: 'Montserrat-M500' !important;
font-style: normal!important;

font-size: 14px!important;
line-height: 22px!important;
color: #C6AC96!important;
border-radius: 0px !important;
padding-right: 2.29rem !important;
/* padding-inline: 40px !important; */
}
.accordion-payment::after{
  background-image: url(./assets/images/payment/arrowAcordion.png) !important;
  background-size: 10px !important;
  margin-left: unset !important;

}
.accordion-paymentAr::after{
  background-image: url(./assets/images/payment/arrowAcordion.png) !important;
  background-size: 10px !important;
  margin-left: unset !important;
  transform: rotate(180deg);

}
.accordion-paymentAr{
  padding-inline-end: 40px;
  font-family: 'Montserrat-Reg'!important;
font-style: normal!important;
font-size: 18px!important;
line-height: 22px!important;
color: #777777!important;
height:100px !important;
padding: 1rem 2.25rem !important;
/* border-bottom: 10px solid #f8f8f8 !important; */

}
.accordion-payment{
  padding-inline-end: 40px;
  font-family: 'Montserrat-Reg'!important;
font-style: normal!important;
font-size: 18px!important;
line-height: 22px!important;
color: #777777!important;
height:100px !important;
padding: 1rem 2.25rem !important;
/* border-bottom: 10px solid #f8f8f8 !important; */

}
.padding-accrordion-right{
  padding-right : 2.25rem!important;

}
.padding-accrordion-left{
  padding-left : 2.25rem!important;

}


.accordion-button:focus {
  box-shadow: none !important;
}


.accordion-payment:not(.collapsed)::after {
  transform: rotate(-0deg) !important;
}
.accordion-paymentAr:not(.collapsed)::after {
  transform: rotate(-0deg) !important;
}
.accordion-catg::after{
  background-image: url(./assets/images/payment/arrowAcordion.png) !important;
  background-size: 10px !important;
  margin-left: unset !important;

}

.accordion-catg{
  padding-inline-end: 40px;
  font-family: 'Montserrat-Reg'!important;
font-style: normal!important;
font-size: 18px!important;
line-height: 22px!important;
color: #777777!important;
}
.border-list{
  border:0px !important;
}
.accordion-catg:not(.collapsed) {
  box-shadow: inset 0 -5px 0 rgb(0 0 0 / 13%) !important;
}
.accordion-catg{
  padding: 1.25rem 0.9rem !important;
}

.accordion-catg:not(.collapsed)::after {
  transform: rotate(-90deg) !important;
}
.accordion-item:focus{
  box-shadow: none !important;
}
/* .accordion-header-payment {
  border-bottom: 10px solid #f8f8f8;
} */
/* .accordion-payment:not(.collapsed) {
  border-bottom: 10px solid #f8f8f8;
  height: 80px !important;

} */
.bg-address{
  /* box-shadow: inset 0 -2px 0 rgb(0 0 0 / 13%) !important; */
  border-bottom: 10px solid #f8f8f8;

}

.accordion-body-address{
  padding: 0px !important;
  border-top: 10px solid #f8f8f8 !important;
}
.padding-border-address{
  /* padding: 2rem 1.9rem !important; */
    /* padding: 1rem 1.25rem !important; */
    padding: 1rem 2.25rem !important;
  height: 100px !important;
}
.margin-border-address{
  /* padding: 2rem 1.9rem !important; */
    /* padding: 1rem 1.25rem !important; */
    margin: 1rem 2.25rem !important;
  height: 100px !important;
}
.padding-border-address2{
  /* padding: 2rem 1.9rem !important; */
    /* padding: 1rem 1.25rem !important; */
    padding: 1rem 2.25rem !important;
}
.adress-payment{
  font-family: 'Montserrat-Reg'!important;
    font-style: normal!important;
    font-size: 18px!important;
    line-height: 22px!important;
    color: #777777!important;
}
.btn-0{
  border: none;
  background-color: white;
}
.edit{
  width: 25px;
  height: 25px;
}
.check-round:checked{
background-color: #C6AC96 !important;
}
/* .check-round[type=checkbox] {
  border-radius: 1em !important;
  border: 2px solid #404040 !important;
} */

/* .check-round[type=radio] {
  border-radius: 1em !important;
  border: 2px solid #404040 !important;
} */
/* .check-round:checked[type=radio] {
background-image: url(./assets/images/payment/checkEllipse.png) !important;

} */
.check-round[type=checkbox] {
  border-radius: 1em !important;
  border: 1px solid #404040 !important;
}
.check-round:checked[type=checkbox] {
background-image: url(./assets/images/payment/checkEllipse.png) !important;
/* background-size: 9px 8px; */

}
.signup .form-check-input:checked[type='checkbox']{
  background-image: url(./assets/images/check.png) !important;
  background-size: 14px 15px;
  
  }
.add-adress{
  width: 30px;
}
.bg-nav-phone{
background-color: white;

}
.checkbox-save-future{
  font-family: 'Montserrat-Reg' !important;
  font-style: normal !important;
  font-size: 14px !important;
  line-height: 26px !important;
  text-transform: capitalize !important;
  color: #000000 !important;
}
.accordion-button:focus {
border-color: white !important;
box-shadow: inset !important;
}

.add-newadress{
  font-family: 'Montserrat-M500';
font-style: normal;
font-size: 12px;
line-height: 15px;
color: #FFFFFF;
}
.bg-Addmyaddress{
  background: #C6AC96;
}
.btn-bg{
  background-color: transparent;
  border: none;
}
.padding-top-addnewAdress{
  margin-top: 110px !important;
  margin-bottom: 100px;
}
.plus-white{
  color: white;
}
.img-payment{
  width: 82px;
  height: 40px;
}
.address-location{
  font-family: 'Montserrat-M500';
font-style: normal;
font-size: 26px;
line-height: 37px;
letter-spacing: 0.07em;
color: #C6AC96;
}
.pin-txt{
  font-family: 'Montserrat-Reg';
font-style: normal;

font-size: 18px;
line-height: 22px;
letter-spacing: 0.07em;
color: #404040;
}
.map{
  height: 438px !important;
}
.pin-btn{
  font-family: 'Montserrat-Reg';
font-style: normal;
color: #000000;
font-size: 17px;
line-height: 26px;
text-align: center;
letter-spacing: 0.07em;
padding-top: 5px;
padding-bottom: 5px;
border: 2px solid #000000;
background-color: transparent;

}
.address-title{
  font-family: 'Montserrat-M500';
font-style: normal;
font-size: 26px;
line-height: 37px;
letter-spacing: 0.07em;
color: #C6AC96;
}
.form-country{
background-color: transparent !important;
font-family: 'Montserrat-Reg' !important;
font-style: normal!important;
font-size: 21px!important;
line-height: 26px!important;
letter-spacing: 0.07em!important;
color: #404040!important;
/* border: 1px solid #CACACA !important; */
background-position: right 0.75rem center !important;
background-size: 16px 12px !important;
border: none !important;
background-color: white !important;
padding: 0.375rem 0.75rem 0.375rem 0.75rem !important;
border-radius: 5px !important;
}
.ReactFlagsSelect-module_label__27pw9, .ReactFlagsSelect-module_secondaryLabel__37t1D{
  padding-right: 10px !important;
}
.form-city{
  background-color: white !important;
    font-family: 'Montserrat-Reg' !important;
    font-style: normal!important;
    font-size: 16px!important;
    line-height: 26px!important;
    letter-spacing: 0.07em!important;
    color: #404040!important;
    width: 300.03px;
    height: 45.09px;
    border: none;
        border-radius: 5px;
  }
  .form-city:focus{
    border: 0px solid!important;
  }
  .form-city:hover{
    border: 0px solid!important;

    
  }

.adress-line-desc{
  font-family: 'Montserrat-Reg';
  font-style: normal;

  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.07em;
  color: #404040;
}
.react-tel-input .flag-dropdown {
  background-color: transparent !important;
}
.form-adress{
  border: none !important;
  background-color: white !important;
  border-radius: 5px !important;
}
.form-adress:focus{
  background-color: white !important;
 
  border: 0px solid!important;
border-radius: 5px !important;
}
.selected-flag {
  background-color: transparent !important;
}
.save-adress{
  border: none;
  font-family: 'Montserrat-M500';
font-style: normal;
font-size: 20px;
line-height: 49px;
text-align: center;
letter-spacing: 0.07em;
color: #FFFFFF;
  background: #C6AC96;
}
.padding-bottom-save{
padding-bottom: 85px;
}
.map{
  height: 400px;
}
@media screen and (min-width: 1300px) {
  .fixedontop{
    position: fixed;
      top: 0;
      right: 0;
      left: 0;
      z-index: 1030;
  }
  .heighnav{
    height: 162px;
    
  }
}
 @media screen and (min-width: 1200px) {
  /* .fixedontop{
    position: fixed;
      top: 0;
      right: 0;
      left: 0;
      z-index: 1030;
  }
  .heighnav{
    height: 162px;
    
  } */
  
  .padding-inline-center{
padding-inline:20rem;
  }
 }
 .padding-bottom-aet-address{
  padding-bottom: 85px;
  }


 @media screen and (max-width: 1200px) {
  .padding-inline-center{
padding-inline:10rem;
  }


  
 }

 @media screen and (max-width: 755px) {
  .padding-inline-center{
    padding-right: 5.5rem!important;
    padding-left: 5.5rem!important;  }
 }

 .btn-clear-filters{
  background: #C6AC96;
  font-family: 'Montserrat-M500';
font-style: normal;
font-size: 28px;
line-height: 43px;
color: #FFFFFF;
border: none ;
padding-inline: 40px;
padding-top: 15px;
padding-bottom: 15px;
text-decoration: none;
 }
 .btn-clear-filters:hover{
  color: #FFFFFF;

 }
 .title-section{
  color: #262626;
  text-align: start;
  font-size: 40px;
  
  font-family: "Montserrat-Reg";
  font-style:normal; 
  /* font-weight: 500; */

  line-height: 41px;
  letter-spacing: 3.3px;
 }
 .sub-section{
  font-family: 'Montserrat-Reg';
font-style: normal;
font-size: 14px;
color: #000000;
 }
 .sub-sectionEmptybag {
  font-family: 'Montserrat-Reg';
font-style: normal;
font-size: 35px;
line-height: 43px;
/* identical to box height */

text-align: center;
letter-spacing: 0.07em;

color: white;
 }
 .sub-sectionEmptybag {
  font-family: 'Montserrat-Reg';
font-style: normal;
font-size: 35px;
line-height: 43px;
/* identical to box height */

text-align: center;
letter-spacing: 0.07em;

color: white;
 }
 .sub-sectionEmptybag2 {
  font-family: 'Montserrat-ELight200';
font-style: normal;
font-size: 25px;
line-height: 43px;
/* identical to box height */

text-align: center;
letter-spacing: 0.07em;

color: white;
 }
 .sub-section2{
  font-family: 'Montserrat-Reg';
font-style: normal;

font-size: 20px;
line-height: 41px;

color: #000000;
 }

.brand-image-txt{
  font-family: 'Montserrat-M500';
font-style: normal;
font-size: 17px;
line-height: 24px;
text-transform: uppercase;
color: #404040;
}
.pricee{
  font-family: "Montserrat-M500";
  font-size: 17px;
  line-height: 30px;
color: #C6AC96;
}
.price{
  font-family: "Montserrat-Reg";
  font-size: 17px;
/* line-height: 10px; */
  padding-top: 5px;
  padding-bottom: 5px;
  color: #1A1818;
}
.libas-logo{
  width: 157px;
height: 58.3px;

}
.comment{
  font-family: 'Montserrat-Reg';
font-style: normal;

font-size: 14px;
line-height: 22px;
color: #404040;
}
.reviews{
  font-family: 'Montserrat-M500';
  font-style: normal;
  font-size: 16px;
  font-family: 'Montserrat';
font-style: normal;
color: #C6AC96;
}
.trusted-seller {

  font-family: 'Montserrat-M500';
  font-style: normal;

  font-size: 21px;
  line-height: 26px;
  
  color: #C6AC96;
}
.trusted-seller-img{
  width: 
  23.55px;
  height: 
  23.55px;
}
.item-sold{
  font-family: 'Montserrat-Reg';
font-style: normal;

font-size: 14px;
line-height: 13px;

color: #000000;
}
.link-to-user{

  font-family: 'Montserrat-Reg';
font-style: normal;
font-size: 16px;
line-height: 22px;
text-decoration-line: underline;
color: #000000;
}
.link-to-user:hover{

  color: #000000;
}
.btn-comm{
  transform:translate(512px,-81px);
    /* padding-right: 40px; */
}
.plusbtn{
  cursor: pointer;
  width: 45px;
/* height: 100px; */
}

.card{

  cursor: pointer;}

/* .card-order:hover{


  -webkit-transform:scale(1.15);
  -moz-transform:scale(1.15);
  -ms-transform:scale(1.15);
  -o-transform:scale(1.15);
  transform:scale(1.15);
} */

.banner-img{
  width: 689.36px !important;
  height: 697.06px !important;
}
.position-btn{
  z-index: 1;
  top: 50%;
  right: 12px;
  position: fixed;
}
.position-btnAr{
  z-index: 1;
  top: 50%;
  left: 12px;
  position: fixed;
}
.position-btn2{
  z-index: 1;
  top: 30%;
  right: 29px;
  position: absolute;
}
.position-btn2Ar{
  z-index: 1;
  top: 30%;
  left: 29px;
  position: absolute;
}
.position-btn3{
  z-index: 1;
  top: 30%;
  right: 29px;
  position: absolute;
}
.buttonH {
  text-transform: uppercase;
}
.buttonH:hover{
  background: #C6AC96;
  color: white;
}
.shared-img{
  width: 433.78px !important;
  height: 406.53px !important
}
.shared-img:hover {
  opacity: 0.8 !important;
}
.notifaction{
  width: 22px;
height: 26.7px;
}
.shopping-bag{
  width: 24px;
  height: 26.08px;
}
.shopping-prof{
  width: 25px;
height: 25px;
}
.brand-subtxt2 {
  font-family: 'Montserrat-M500';
font-style: normal;
font-size: 24px;
line-height: 29px;
text-decoration: none;
color: #404040;
padding-bottom: 15px;
cursor: pointer;
}
.brand-subtxt3{
  font-family: 'Montserrat-L300';
font-style: normal;
font-size: 24px;
line-height: 29px;
text-decoration: none;
color: #404040;
padding-bottom: 15px;

}
.brand-subtxt3:focus,.brand-subtxt3:hover {
  color:  #C6AC96;
 }
.brand-subtxt2:focus,.brand-subtxt2:hover {
 color:  #C6AC96;
}
.brand-letters{
  font-family: 'Montserrat-Reg';
  font-style: normal;
  font-size: 27px;
  line-height: 33px;
  text-decoration: none;
  color: #404040;
}
.brand-letters:focus,.brand-letters:hover {
  color:  #C6AC96;
 }
 .border-prof{
  border-radius: 50%;
 }
 .btn-store2{
  width: 29.88px;
height: 29.88px;
 }
 .btn-tiktok{
  width: 22.88px;
height: 22.88px;
margin: 5px;
 }
 .btn-store3{
  width: 102.4px;
  height: 35.05px!important;
  
 }
 .div-width{
  width: 3rem;
 }
 .width-visa{
  width: 480px;
  height: 23px;
}
.bg-downloadModal{
  content: url(./assets/images/downloadImgModal.png);
  width: 100%;
}
.modal-download{
  max-width: 55em!important;
height: 758.62px !important;
}
.modal-locations{
  max-width: 35em!important;
height: 758.62px !important;
}
.show-modal-download{
  position: fixed;
  left: 0%;
  right: 0;
  top: 3%;
  margin: 0 auto;
  z-index: 5;
}
.modal-locations{
  position: fixed;
  left: 0%;
  right: 0;
  top: 30%;
  margin: 0 auto;
  z-index: 5;
}
.position-btndownload{
  position: absolute;
 top: 5%;
  right: 2%;
 
}
.position-btnxoffer{
  position: absolute;
 top: 3%;
  right: 2%;
 
}
.position-btndownload3{
  position: absolute;
 top: 36%;
 left: 2%;
 bottom: 0%;
}
.position-locationtxt{
  align-items: center;
  align-content: center;
  align-self: center;
  /* position: absolute; */
 /* top: 50%;
 bottom: 0%; */
}
.position-btndownload4{
  /* position: absolute;
  top: 40%;
  right: 15%;
  bottom: 0%; */
}
.position-btndownload5{
  /* position: absolute;
  top: 40%;
  right: 2%;
  bottom: 0%; */
}
.download-modal-title{
  font-family: 'Montserrat-Reg';
font-style: normal;

font-size: 23px;
line-height: 44px;
/* identical to box height */

letter-spacing: 0.11em;

color: #000000;
}
.download-modal-sub{
  font-family: 'Montserrat-M500';
font-style: normal;
font-size:19px;
line-height: 37px;
letter-spacing: 0.11em;

color: #000000;
}
.btn-store-download{
  width: 148px;
height: 60.76px;
}
.QrCode{
  width: 100px;
height: 100px;
}
.download-content{
  border-radius: 30px !important;
  /* background-image: url(./assets/images/downloadImgModal.png);
  background-size: contain;
  height: 100%;
  background-repeat: no-repeat; */
}
.bg-shared2{
  background: #F9F9F9;
}
.shared-img2{
  width: 58.89px;
  height: 66.17px;
}
.shared-img3{
  width: 72.2px;
height: 55.95px;
}
.shared-img4{
  width: 58.89px;
  height: 66.17px;
}
.shared-txt{
  font-family: 'Montserrat-M500';
font-style: normal;
font-size: 20px;
line-height: 41px;
/* identical to box height, or 205% */
text-decoration: none;
text-align: center;
letter-spacing: 0.11em;

color: #000000;
}
.shared-txt:hover{
  
color: #000000;
}
.card-width-shared{
  width: 400px;
  }
  .card-body-product{
    /* justify-content: space-evenly; */
    align-items: center;
    /* display: grid; */
    padding: 0!important;
  }
.card-imageProduct{
  margin-top: 47px;
  padding-right: 1rem!important;
  padding-left: 1rem!important;
}
.modal-dialog-centered{
  border-radius: 0px !important;
  /* border: 2px solid #404040 !important */
}
/* @media (min-width: 576px)
{.modal-dialog-centered{
    max-width: 500px;
    margin: -0.7rem  auto !important;
}} */
.modal-dialog-delete{
    border: 0px solid white !important

}
.modal-content{

  border: 0px solid white !important

}
.delete-modal{
  border-radius: 0px !important;

}
.btn-plus-Payment{
  width: 25px;
height: 25px;
}
.checkarrow {
  background-color: transparent  !important;
  border: 1px solid #404040 !important;
  
  /* background-image: url(./assets/images/Check.svg) !important;
  border: 1px solid #404040 !important; */

}
.checkarrow:checked[type=checkbox]{
  background-color: #C6AC96 !important;
  background-image: none !important;
  border: 0px solid #404040 !important;
}
.check-round{
  width: 25px !important;
  height: 25px !important;
  background-color: transparent !important;

}
.check-round:checked[type=checkbox] {
  background-color: white !important;
  background-image: url(./assets/images/download.svg) !important;
  border: 1px solid #404040 !important;

}
.signup2{
  width: 25px !important;
  height: 25px !important;
  background-color: transparent !important;
}
.signup2:checked[type=checkbox] {
  background-color: white !important;
  background-image: url(./assets/images/download.svg) !important;
  border: 1px solid #404040 !important;

}
.form-check-input{
  width: 20px !important;
  height: 20px !important;

}
/* .form-check-input:checked[type=checkbox] {
  background-color: white !important;
  background-image: url(./assets/images/download.svg) !important;
  border: 1px solid #404040 !important;

} */


.checkarrow:checked[type=checkbox] {
  background-image: url(./assets/images/Check.svg) !important;
}
/* .form-check-input {
  border: 1px solid #e7e6e9 !important;
  width: 1.1rem !important;
  height: 1.1rem !important;
} */
/* .form-check-input:checked[type=radio] {
  background-image: url(./assets/images/radiobtn.svg) !important;
  border: 2px solid #404040 !important;

} */
form-check-input {
  border: 2px solid #404040 !important;
  border-color: #404040 !important;
  width: 30px!important;
  height: 30px !important;
}

.form-check-input:focus {
  border: 1px solid #e7e6e9 !important;
  box-shadow: none !important;
}
/* .form-check-input:checked[type='checkbox'] {
  background-color: transparent !important;
  border-color: #404040 !important;

} */
.card-chekcout{
  height: 340px;
}
.padding-bottom-mybag{
  padding-bottom: 300px;
}
.accordion-width{
  width: 61%;
}
.accordion-width2{
  width: 72%;
}
@media screen and (max-width: 990px) {

  .padding-bottom-mybag{
    padding-bottom: 0px;
  }
  /* .pos-emptybagbtn{
    left: 50%;
    transform: translate(-50%, -50%);
    bottom: -200px;
   } */
   .accordion-width{
    width: 100%;
}
.accordion-width2{
  width: 100%;
}
}
.pay-total2{
  font-family: "Montserrat-B700";
font-size: 21px;
line-height: 26px;
color: #404040;
}
.total-pay2{
  font-family: "Montserrat-B700";
font-size: 21px;
line-height: 26px;
color: #404040;
}
.pos-bannerBox{
  top: 76%;
  background: rgba(255, 255, 255, 0.8);
}
.banner-title{
  font-family: 'Montserrat';
font-style: normal;
font-weight: 429;
font-size: 22px;
line-height: 30px;
color: #000000;
}
.banner-title2{
  font-family: 'Montserrat-M500';
font-size: 20px;
line-height: 24px;

color: #000000
}
.ReactFlagsSelect-module_selectBtn__19wW7:after{
  background-image: url(./assets/images/radio.svg) !important;
}
.shopping-bag-btn::after{
display: none !important;
}
.burger{
  width: 25.5px;
height: 24.54px;
}
.burger:hover{
  border: none !important;

}
.burger:focus{
  border: none !important;
  
}
.homepage-icon{
  width: 25px !important;
  height: 25px !important;
}
.landingpage-icon{
  width: 30px !important;
  height: 30px !important;
}

.title-phone{
  font-family: 'Montserrat-M500';
  font-style: normal;
  font-size: 35px;
  line-height: 43px;
  text-transform: capitalize;
  color: #C6AC96;
}
.card-order{
  cursor: pointer;}
  .card3{
    cursor: pointer;
  }
.card-product{
  cursor: pointer;
}
.swiper-button-next:after, .swiper-button-prev:after {
color: #000000;
  font-size: 31px !important;
}
.pos-arrow{
  left: 0%;
}
.pos-count{
  right: 0%;
}
.pos-title {
  top:6rem;
}
.next-bill:hover{
  background: #C6AC96;
  color: white;
  border: none !important;

}
.pin-btn:hover{
  background: #C6AC96;
  color: white;
  border: none !important;

}
.btn-1:hover{
  background: #C6AC96;
  color: white;
  border: none !important;

}
.btn2:hover{
  background: #C6AC96;
  color: white;
  border: none !important;

}
.btn1:hover{
  background: #C6AC96;
  color: white;
  border: none !important;

} 
.btn-howtosell:hover{
  background: #C6AC96;
  color: white;
  border: none !important;
}
.btn-goback:hover{
  background: #C6AC96 !important;
  color: white !important;
  border: none !important;

} 
.btn-Contact:hover{
  background: #C6AC96 !important;
  color: white !important;
  border: none !important;
} 
.btn-del:hover{
  background: #C6AC96 !important;
  color: white !important;
  border: none !important;
} 
.btn-keep:hover{
  background: #C6AC96 !important;
  color: white !important;
  border: none !important;
}  
.btn-comment:hover{
  background: #C6AC96 !important;
  color: white !important;
  border: none !important;
}  
.btn-del {
  border: none;
}
.x-close{
  width: 11px;
  height: 11px;
}
.x-close2{
  width:8px;
  height: 8px;
}
.card-number{
  font-family: 'Montserrat-Reg';
    font-size: 11px;
    top: -5px;
    /* bottom: -135px; */
    right: 4px;
    background-color: rgba(198,172,150,255);
    border-radius: 50%;
    width: 17px;
    height: 17px;
    color: white;
}
.share-img{
  width: 25px;
  height: 29px;
}
.nbr-likes{
  font-family: 'Montserrat-Reg';
  font-size: 18px;
  line-height: 26px;
  color: black;
}
.check{
  
  width: 15px;
  height: 15px;

}
.cursor-home{
  cursor: pointer;
}
.carousel-control-next-icon{
  width: 2rem !important;
  height: 2rem !important;
}
.carousel-control-prev-icon{
  width: 2rem !important;
  height: 2rem !important;
}
.title-quality{
  font-family: 'Montserrat-Reg';
  font-style: normal;
  font-size: 30px;
  line-height: 45px;
  /* text-transform: capitalize; */
  /* identical to box height, or 141% */
  
  letter-spacing: 0.11em;
  
  color: #000000;
}
.txt-privacy{
  font-family: 'Montserrat-Reg';
font-size: 20px;
line-height: 30px;
/* identical to box height, or 141% */

letter-spacing: 0.11em;

color: #000000;
}
.txt-privacy:hover{
  color: #000000;

}
.loading{
  width: 80px;
  /* height: 200px; */
}
.loading-center{
  margin: auto;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loading-centercomp{
  margin: auto;
  width: 100%;
  /* height: 143px; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.title-privacy{
  font-family: 'Montserrat-M500';
font-style: normal;

font-size: 24px;
line-height: 45px;
/* identical to box height, or 141% */

letter-spacing: 0.11em;

color: #000000;
}
.txt-privacy{
  font-family: 'Montserrat-Reg';
font-size: 20px;
line-height: 30px;
/* identical to box height, or 141% */

letter-spacing: 0.11em;

color: #000000;
}
.txt-contact{
  font-family: 'Montserrat-M500';
  font-size: 20px;
line-height: 30px;
/* identical to box height, or 141% */

letter-spacing: 0.11em;

color: #000000;
}
.txt-contact32:hover{
  
color:#C6AC96  ;
}
.title-privacy2{
  font-family: 'Montserrat-M500';
font-style: normal;

font-size: 21px;
line-height: 45px;
/* identical to box height, or 141% */

letter-spacing: 0.11em;

color: #C6AC96;
}
@media screen and (max-width: 530px) {
  .title-privacy2{
      font-size: 14px;
      line-height: 25px;
  }
  .txt-privacy{
      font-size: 14px;
line-height: 21px;
/* or 179% */

letter-spacing: 0.11em;

color: #000000;
  }
  .txt-contact{
      font-family: 'Montserrat-M500';
      font-size: 14px;
line-height: 21px;
/* or 179% */

letter-spacing: 0.11em;

color: #000000;
  }
  .title-privacy{
      font-family: 'Montserrat-M500';
font-style: normal;

font-size: 14px;
line-height: 25px;
/* or 179% */

letter-spacing: 0.11em;

color: #000000;

  }
 
}
.title-quality{
  font-family: 'Montserrat-Reg';
  font-style: normal;
  font-size: 30px;
  line-height: 45px;
  /* identical to box height, or 141% */
  
  letter-spacing: 0.11em;
  
  color: #000000;
}
.title-quality2{
  font-family: 'Montserrat-M500';
  font-style: normal;
  font-size: 30px;
  line-height: 45px;
  /* identical to box height, or 141% */
  
  letter-spacing: 0.11em;
  
  color: #000000;
}
.btn-send{
  background: #CFBBA7;
  border-radius: 7px;
  font-family: 'Montserrat-M500';
  font-style: normal;
  
  font-size: 18px!important;
  line-height: 21px!important;

  text-align: center!important;
  letter-spacing: 0.11em!important;
  border: none;
  color: #000000 !important;
  padding-left:19px;
  padding-right: 19px;
 padding-top: 7px;
 padding-bottom: 7px;

}

.input-pos{
  position: absolute;
  
  right: 12px;
  bottom: 15px;

}
.input_width{
  width: 525px !important;
}
.input_forminput_form{
  border: 2px solid #C6AC96 !important;
  border-radius: 13px !important;

}
.form-static:focus{
  border: 2px solid #C6AC96 !important;

}
.form-static{
  font-family: "Montserrat-Reg" !important; 
font-size: 17px!important;
line-height: 21px!important;
/* or 124% */

letter-spacing: 0.11em;

  border: 2px solid #C6AC96 !important;
  border-radius: 9px !important;

}
.form-static::placeholder{
  color: #959595!important;

}
@media screen and (max-width: 530px) {
  .form-static{
      font-weight: 400!important;
font-size: 12px!important;
line-height: 21px!important;
border-radius: 5px !important;
border: 1px solid #C6AC96 !important;

  }
  .input_width {
      width: 310px !important;
  }
  .btn-send{
      font-family: "Montserrat-M500" !important;
      padding-left: 20px;
      padding-right: 20px;
      font-size: 8px!important;
      line-height: 21px!important;
  }
  .title-quality{

      font-family: "Montserrat-Reg" !important;
  font-size: 16px;
  line-height: 22px;
  /* or 179% */
  letter-spacing: 0.11em;
  }
  .title-quality2{
      font-family: "Montserrat-M500" !important;
      font-size: 16px;
      line-height: 22px;
      /* or 179% */
    
      letter-spacing: 0.11em;
  }
}
.circle-notofication{
  height: 8px;
  width: 8px;
  border-radius: 50%;
  display: inline-block;

  border: 0px;
  background-color: #C6AC96;
}
.circle-notoficationOpen{
  height: 8px;
  width: 8px;
  border-radius: 50%;
  display: inline-block;

  border: 0px;
  background-color: white;
}
.logo-notification{
  width: 61px;
  height: 61px;
  border-radius: 50%;
}
.txt-notification{
  font-family: "Montserrat-M500" !important;
  font-size: 14px;
  line-height: 22px;
  color: #404040;
}
.txt-notification2{
  font-family: "Montserrat-ELight200" !important;
  font-size: 12px;
  line-height: 22px;
  color: #404040;
}
.dropdown-nav2{
width: 424px;  
overflow-y: scroll;
    height: 316px;
}
.dropdown-nav2Ar{
  width: 424px;  
  overflow-y: scroll;
      height: 316px;
  }
.logo-notification2{
  border: 4px solid white;
    border-radius: 8px;
    width: 48px;
    height: 48px;
}
.bg-circlesOpen{
  background: #F3EFEF;
width: 141.73px;
height: 141.73px;
border-radius: 50%;

}
.bg-orders{
  background: #DFDFDF;
}
.txt-notopend{
  font-family: 'Montserrat-M500';
  font-size: 14px;
  color: #404040;
}
.txt-opend{
  font-family: 'Montserrat-L300';
  font-size: 14px;
  color: #404040;
}
.txt-notopend2{

  font-size: 8px;
  font-family: 'Montserrat-ELight200';
  color: #404040;
}
.pin{
  width: 10px;
  height: 10px !important;
}
.circle{
  border-radius: 50%;
  width: 90.77px;
  height: 90.77px;
  background-color: white;
 
}
.sub-title3{
  font-family: 'Montserrat-M500';
font-style: normal;

font-size: 18px;
line-height: 22px;
color: #404040;
}
.title-section2{
  /* font-family: 'Cormo'; */
  font-family: "Montserrat-Reg";
font-style: normal;

font-size:40px;
    line-height: 67px;

color: #000000
}
.title-section22{
  font-family: "Montserrat-Reg";
  /* font-family: 'Cormo'; */

font-size: 40px;
line-height: 67px;

color: #000000
}
.crypt-img{
  width: 294px;
  height: 294px;
  transform: scale(1, 1);
  transition: transform 0.4s;

}
.img-topPicks{
  transform: scale(1, 1);
  transition: transform 0.4s;
}
.product-img{
  transform: scale(1, 1);
  transition: transform 0.4s;
}
.cremedelacremeproduct{
  transform: scale(1, 1);
  transition: transform 0.4s;
}
.cremedelacremeproduct:hover{
  transform: scale(1.05, 1.05);
  }
.product-img:hover{
  transform: scale(1.05, 1.05);
  }
.crypt-img:hover{
  transform: scale(1.05, 1.05);
  }
  .img-topPicks:hover{
    transform: scale(1.05, 1.05);
    }
.crypt-txt{
  font-family: 'Montserrat-Reg';
font-style: normal;
font-size: 20px;
line-height: 29px;
color: #000000;
width: 290px;
height: 122px;
text-align: start;
}
.crypt_sub-date{
  font-family: 'Montserrat-Reg';
  padding-top: 5%;
  color: #808080;
  font-size: 16px;
}
.crypttxtAr{
  font-family: 'Montserrat-M500';
  font-style: normal;
  font-size: 19px;
  line-height: 29px;
  color: #000000;
  width: 290px;
  height: 122px;
  text-align: center;
}
.crypt-txt2{
  font-family: 'Montserrat-M500';
font-style: normal;
font-size: 15px;
/* line-height: 11px; */
/* text-decoration-line: underline; */
color: #000000;
cursor: pointer;
/* text-decoration-line: underline; */

text-align: center;
text-transform: uppercase;

}
a.left
{
    position: relative;
}
a.left:before {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #FFF;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
}

.crypt-txt2link {
  display: inline-block;
  position: relative;
}

.crypt-txt2link::before  {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 1px;
  bottom: 0;
  text-decoration-line: underline;

  left: 0;
  background-color: #1A1818;
  transform: scaleX(0);
  transition: transform 0.1s ease;
}
img.homepage-icon.img-fluid:hover {
  /* filter: invert(69%) sepia(4%) saturate(1896%) hue-rotate(346deg) brightness(101%) contrast(91%); */
}
.crypt-txt2link:hover::before {
  transform: scaleX(1);
  text-decoration-line: none;

}
/* .mainblog-img{
  width: 403px;
height: 403px;
}
.blog-txt{
  font-family: 'Montserrat-SemiB';
font-size: 22px;
line-height: 41px;
letter-spacing: 0.02em;
text-transform: uppercase;
color: #000000;
}
.btn-blog{
  font-family: 'Montserrat-Reg';
font-style: normal;
font-size: 22px;
line-height: 41px;
letter-spacing: 0.02em;
text-transform: uppercase;
color: #FFFFFF;
background: #C6AC96;
width: 183px;
text-align: center;

} */
.blog1-img{
  width: 555px;
height: 115px;
}
.crypt-title{
  font-family: 'Montserrat-SemiB';
font-style: normal;
font-size: 35px !important;
line-height: 41px;
letter-spacing: 0.02em;
text-transform: uppercase;

color: #000000;

}
.crypteid-title{
  font-family: 'Montserrat-SemiB';
font-style: normal;
font-size: 28px !important;
line-height: 41px;
letter-spacing: 0.02em;
text-transform: uppercase;

color: #000000;

}
.crypt-titlenew{
  font-family: 'Montserrat-M500';
  font-style: normal;
  font-size: 35px !important;
  line-height: 41px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  
  color: #000000;
}
.crypt-titlenew600{
  font-family: 'Montserrat-SemiB';
  font-style: normal;
  font-size: 30px;
  line-height: 41px;
  
  letter-spacing: 0.02em;
  
  color: #000000;
  /* text-transform: uppercase; */
  
}
.copyWritertxt{
  font-family: 'Montserrat-M500';
font-style: normal;
    text-transform: uppercase;
font-size: 15px;
line-height: 41px;
text-align: center;
letter-spacing: 0.02em;
text-transform: uppercase;

color: #000000;
}
.copyWritertxteid{
  font-family: 'Montserrat-M500';
font-style: normal;
    text-transform: uppercase;
font-size: 15px;
line-height: 41px;
text-align: center;
letter-spacing: 0.02em;
text-transform: uppercase;

color: #000000;
}
.copyWritertxt3{
  font-family: 'Montserrat-M500';
font-style: normal;
    text-transform: uppercase;
font-size: 15px;
line-height: 41px;
text-align: center;
letter-spacing: 0.02em;
text-transform: uppercase;

color: #000000;
}
.blogf-img{
  width: 649px;
height: 649px;
}
.blogf2-img{
  width: 949px;
height: 949px;
}
.crypt-title2 {
  font-family: 'Montserrat-SemiB';
font-style: italic;
font-size: 30px;
line-height: 40px;
display: flex;
align-items: center;
letter-spacing: 0.11em;

color: #000000;
}
.crypt-title3 {
  font-family: 'Montserrat-SemiB';
font-style: normal;
font-size: 30px;
line-height: 40px;
display: flex;
align-items: center;
letter-spacing: 0.02em;
text-transform: uppercase;

color: #000000;
}
.crypt-sub{
  
font-family: 'Montserrat-Reg' ;
font-style: normal;
font-size: 22px;
line-height: 40px;
font-weight: 400;

letter-spacing: 0.02em;
color: #000000;
}
.howtosell-txt{
  font-family: 'Montserrat-Reg';
font-style: normal;
font-size: 32px;
line-height: 41px;
text-align: center;
letter-spacing: 0.11em;
color: #000000;
}
.howtosell-title{
  font-family: 'Montserrat-SemiB';
font-style: normal;
font-size: 40px;
line-height: 41px;
text-align: center;
letter-spacing: 0.11em;
text-transform: uppercase;
color: #000000;
}
.howtosell-title2{
  font-family: 'Montserrat-B700';
font-style: normal;
font-size: 32px;
line-height: 40px;
letter-spacing: 0.11em;
color: #000000;
}
.howtosell-txt2{
  font-family: 'Montserrat-Reg';
font-style: normal;
font-size: 28px;
line-height: 40px;
letter-spacing: 0.11em;
color: #000000;
}
.howtosell-txt3{
  font-family: 'Montserrat-Reg';
font-style: normal;
font-size: 28px;
line-height: 39px;
letter-spacing: 0.04em;
color: #000000;
text-decoration: none;

}
.howtosell-txt3:hover{
  font-family: 'Montserrat-Reg';
font-style: normal;
font-size: 28px;
line-height: 39px;
letter-spacing: 0.04em;
color: #000000;
text-decoration: none;
}
.howtosellbag{
  width: 506px;
  height: 506px;

}
.btn-howtosell{
  width: 506px;
  color: black;
  border: 1px solid black;
  background-color: white;
  font-family: "Montserrat-SemiB";
  font-size: 20px;
  line-height: 24px;
  height: 78px;
}

.howtosellbag2{
  width: 506px;
  height: 506px;
}
.blog3-img2{
  width: 954px;

}
.bloglux-img2{
  width: 529px;

}
.blog3-img{
  width: 954px;
height: 808px;
}
.blogeid-img{
  width: 633px;
  height: 820px;
}
.bloglux-img{
  width: 529px;
height: 808px;
}

.steps-img{
  width: 927px;
  height: 745px;
}
.steps-imgfnext{
  width: 100%;
  height: 745px;
}
.steps-imgnew{
  width: 544px;
  height: 679px;
}
.steps-img2{
  width: 927px;

}
.LV-img{
  width: 1027px;

}
.instagram-media{
  background: white;
  max-width: 540px;
  width: calc(100% - 2px);
  border-radius: 3px;
  border: 1px solid rgb(219, 219, 219);
  box-shadow: none;
  display: block;
  margin: 0px 0px 12px;
  min-width: 326px;
  padding: 0px;
  min-height: 899px;
  max-height: 1500px;
  /* height: 900px !important; */
}
.instagram-media3{
  background: white;
  max-width: 540px;
  width: calc(100% - 2px);
  border-radius: 3px;
  border: 1px solid rgb(219, 219, 219);
  box-shadow: none;
  display: block;
  margin: 0px 0px 12px;
  min-width: 326px;
  padding: 0px;
  min-height: 768px;
  max-height: 1500px;
  /* height: 900px !important; */
}
.instagram-media2{
  background: white;
  max-width: 540px;
  width: calc(100% - 2px);
  border-radius: 3px;
  border: 1px solid rgb(219, 219, 219);
  box-shadow: none;
  display: block;
  margin: 0px 0px 12px;
  min-width: 326px;
  padding: 0px;
  min-height: 650px;
  max-height: 1000px;
  /* height: 650px !important; */
}
.btn2{
  color: black;
  border: 1px solid  black;
  background-color: white;
}
.btn-general{
  font-family: "Montserrat-SemiB";
  font-size: 20px;
  line-height: 24px;
  width: 262.63px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.searchtitle{
  font-family: 'Montserrat-SemiB';
font-style: normal;
font-size: 13px;
line-height: 16px;
/* identical to box height */

letter-spacing: 0.11em;

color: #000000;

}
.searchtxt{
  font-family: 'Montserrat-Reg';
font-style: normal;
font-weight: 400;
font-size: 13px;
line-height: 16px;
letter-spacing: 0.11em;
cursor: pointer;
color: #000000;
}
.searchtoppicks{
  border-left:1px solid #959595;
}
.img-topPicksseacrh{
  width: 74px;
  height: 74px;
}
.model-search{
  font-family: 'Montserrat-Reg';
font-style: normal;
font-weight: 400;
font-size: 11px;
line-height: 11px;
letter-spacing: 0.11em;

color: #000000
}
.brand-search{
  font-family: 'Montserrat-M500';
  font-style: normal;

  font-size: 13px;
  line-height: 13px;
  letter-spacing: 0.11em;
  
  color: #000000;
}
@media screen and (max-width: 525px) {
  .instagram-media{
    min-width: auto;
    max-width: inherit;
    min-height: 748px;
    max-height: 1500px;
/* height: 550px !important; */
  }
  .instagram-media3{
    min-width: auto;
    max-width: inherit;
    min-height: 748px;
    max-height: 1500px;
/* height: 550px !important; */
  }
  .instagram-media2{
    min-width: auto;
    max-width: inherit;
    /* height: 450px !important; */
    min-height: 505px;

      }
}
.applepay{
  width: 85px;
    height: 40px;

}
.steps-img-auth{
  width: 100%;
  height: 743px;}
  .underline{
    text-decoration: underline;
    cursor: pointer;
  }
  .underlinenblue{
    color: black;
    text-decoration: underline;
    cursor: pointer;
    font-family: 'Montserrat-M500';
  }
  .underlinenblue:hover{
    color: #C6AC96;
  }
  .plusbtn2{
    width: 33px;
  /* height: 100px; */
  }
  .tabby-desk {
    width: 435px;
}

/* @media screen and (min-width: 540px) {
  .next{
    height: 35px;
  }
} */
.btn404{
  font-family: 'Montserrat-SemiB';
font-style: normal;
font-size: 13px;
line-height: 16px;
text-transform: uppercase;
border: 0px;
background-color: white;
color: #000000;
padding-top: 13px;
    padding-bottom: 13px;
width: 181px;
}
.btn404-pos{
  position: absolute;
  bottom: 14px;
  left: 50%;
  transform: translate(-50%, -50%);
}
.posttabytxt{
  font-family: 'Montserrat-M500';
  color: #404040;
  font-size: 13px;
  line-height: 40px;
}
.posttabytxt2{
  font-family: 'Montserrat-M500';
 color: #404040;
  font-size: 16px;
}
.widthtaby{
  width: 487px;
  border: 1px solid #C6AC96;
}
.widthtaby2{
  width: 507px;
  border: 0.5px solid #C6AC96;
  border-radius: 8px;
}
.tabby{
  width: 
  70px;
  height: 39px;
}
.info-icon{
  width: 12px;
  height: 
  11.65px;
}
.postpay{
  width: 
  78px;
  height: 27px;
}
.bordertabby{
  border-right:  1px solid #C6AC96;
}
.bordertabbyAr{
  border-left:  1px solid #C6AC96;
}
.editprofile{
  font-family: 'Montserrat-SemiB';
font-style: normal;
font-size: 20px;
line-height: 29px;
width: 320px;
height: 61px;
color: #FFFFFF;
background: #1A1818;
border: 0;
}
.editprofile:focus{
  background: #C6AC96;
  border: none;
  color: white;
}
.editprofile:hover{
  background: #C6AC96;
  border: none;
  color: white;
}
.follow:focus{
  background: #C6AC96;
  border: none;
  color: white;
}
.follow:hover{
  background: #C6AC96;
  border: none;
  color: white;
}
.follow{
  font-family: 'Montserrat-SemiB';
font-style: normal;
font-size: 20px;
line-height: 29px;
width: 320px;
height: 61px;
color: #FFFFFF;
background: #1A1818;
border: 0;
}
.followerslist{
  font-family: 'Montserrat-Reg';
font-style: normal;
font-weight: 400;
font-size: 30px;
line-height: 39px;

color: #000000;

}
.copyWritertxt2{
  font-family: 'Montserrat-M500';
font-style: normal;
font-size: 13px;
line-height: 41px;
text-align: center;
letter-spacing: 0.02em;
text-transform: uppercase;
color: #000000;
}
.issue4{
  width: 100%;
height: 805px !important
}
.issue5{
  width: 100%;
  height: 962px !important
}
@media screen and (max-width: 1133px) {
  .issue5{
    width: 100%;
    height: 286px!important
  }
  .profileimg {
    width: 171px;
    height: 171px;
    border-radius: 50%;
}
  .issue4{
    width: 100%;
    height: 372px !important;
  }
  .copyWritertxt2{
    font-size: 5px;
    line-height: 14px;
  }
  .followerslist{
    font-family: 'Montserrat-Reg';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 39px;
  
  color: #000000;
  
  }
  .profiletxt1{
    font-size: 14px;
line-height: 18px;
  }
  .profiletxt2{
    font-size: 12px;
    line-height: 16px;
  }
  .profiletxt3{
    font-size: 12px;
    line-height: 16px;
  }
  .locationprof{
    width: 11px;
height: 10px;
  }
  .follow{
    font-family: 'Montserrat-SemiB';
  font-style: normal;
  font-size: 20px;
  line-height: 29px;
  width: 200px;
  height: 41px;
  color: #FFFFFF;
  background: #1A1818;
  border: 0;
  }
  .posttabytxt{
    font-family: 'Montserrat-M500';
    color: #404040;
  
    font-size: 14px;
  }
  .posttabytxt2{
    font-family: 'Montserrat-M500';
   color: #404040;
    font-size: 14px;
  }
  .widthtaby2{
    width: 100%;
    border: 0.5px solid #C6AC96;
    height: 66.46px;
  }
  /* .tabby{
    width: 
    78px;
    height: 19px;
  } */
  .info-icon{
    width: 11px;
    height: 10.65px;
  }
  /* .postpay{
    width: 
    78px;
    height: 27px;
  } */
  .btn404{
    font-family: 'Montserrat-SemiB';
  font-style: normal;
  font-size: 10px;
  line-height: 6px;
  text-transform: uppercase;
  border: 0px;
  background-color: white;
  color: #000000;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 101px;

  }
  .btn404-pos{
    position: absolute;
    bottom: 23px;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
@media screen and (max-width: 780px) {
  .textBlog
  {
    font-size: 14px!important;
  }
  .btn404{
    font-family: 'Montserrat-SemiB';
  font-style: normal;
  font-size: 5px;
  line-height: 10px;
  text-transform: uppercase;
  border: 0px;
  background-color: white;
  color: #000000;
  padding-top: 10px;
  padding-bottom: 10px;
  }
  .btn404-pos{
    position: absolute;
    bottom: 60px;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.img-selected{
  width: 550px !important;
  height:550px !important;
}
.notf_none{
  font-family: 'Montserrat-M500';
  color: #E3E3E3;
  font-size: 16px
}
.notf_none:hover{
  color: #E3E3E3;

}
.notf_none_title{
  font-family: 'Montserrat-M500';
  color: #C6AC96;
  font-size: 21px
}
.notf_none_title:hover{
  color: #C6AC96;

}
.imgAr{
  width: 429px;
  height: 200px;

}
.marginSelcted{
  cursor: pointer;
  margin-right: 20px;
  width: 90px !important;
  height: 90px !important;
}
.text-right{
  text-align: right;
  letter-spacing: 0em;
}
b, strong {
  font-weight: 600 !important
}
.posttaby{
  background-color: #EAE1D9;
}
.tabby-title-modal{
  width: 183.95px;
height: 70.32px;
}
.tabby-title2-modal{
  font-family: 'Montserrat-M500';
font-style: normal;
font-size: 20px;
line-height: 24px;
text-align: center;

color: #000000;
}
.tabby-title3-modal{
  font-family: 'Montserrat-M500';
  font-style: normal;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #000000;
  height: 68px;
}
.tabby-title4-modal{
  font-family: 'Montserrat-Reg';
  font-style: normal;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #000000;
}
.tabby-img-modal{
  width: 75.5px;
height: 76.3px;
}
.btn-tabby{
  background: #C6AC96;
  font-family: 'Montserrat-M500';
font-style: normal;
font-size: 17px;
line-height: 21px;
/* identical to box height */
width: 336.95px;
height: 40.71px;
text-align: center;
border: 0px;
color: #FFFFFF;
}
.postpay-title-modal{
  width: 243px;
height: 58px;
}

@media screen and (max-width: 430px) {

  .profiletxt1{
    font-size: 14px;
line-height: 18px;
  }
  .profiletxt2{
    font-size: 12px;
    line-height: 16px;
  }
  .profiletxt3{
    font-size: 12px;
    line-height: 16px;
  }
  .locationprof{
    width: 11px;
height: 10px;
  }
}
.btn-offer{
  background: #FFFFFF;
border: 1px solid #404040;

font-family: 'Montserrat-Reg';
font-style: normal;
width: 272px;
font-size: 20px;
line-height: 29px;

color: #000000;
}
.image-container{
  display: flex;
  flex-direction: column;
  padding: 0; /* Remove any padding */
  margin: 0; /* Remove any margin */
  position: absolute;
  bottom: 50%;
  left: 8%;
  width: 80%;

}
.btn-2Image{
  margin-top: 20px;
  position: relative;
  background: transparent;
border: 1px solid #404040;

font-family: 'Montserrat-Reg';
font-style: normal;
width: 10vw;
height: 3.5vw;
font-size: 1.2vw;
line-height: 29px;

color: #000000;
}
.image-title{
  position: relative;
  font-family: 'Montserrat-Reg';
  font-size: 1.2vw;
}
.image-subtitle{
  position: relative;
  font-family: 'Montserrat-M500';
  font-size: 2.5vw;
}
.editproftitle{
  font-family: 'Montserrat-M500';
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 24px;
color: #C6AC96;
}
.profileimgedit{
  width: 67.02px;
height: 67.02px;
border-radius: 50%;
}
.editprofname{
  font-family: 'Montserrat-Reg';
font-style: normal;
font-weight: 400;
font-size: 15px;
line-height: 18px;
color: #000000;
}
.editprofname2{
  font-family: 'Montserrat-Reg';
font-style: normal;
font-weight: 400;
font-size: 15px;
line-height: 18px;

color: #404040;
}
.editprof3{
  font-family: 'Montserrat-M500';
font-style: normal;
font-weight: 500;
font-size: 15px;
line-height: 18px;

color: #C6AC96;
}
.editprof4{
  font-family: 'Montserrat-Reg';
font-style: normal;
font-weight: 400;
font-size: 15px;
line-height: 18px;

color: #000000;
}
.saveEdit{
  font-family: 'Montserrat-M500';
font-style: normal;
font-weight: 500;
font-size: 15px;
line-height: 18px;
color: #FFFFFF;
width: 180px;
height: 47px;
background: #404040;
border: 0px;
}
.lazy-load-image-loaded{
 /* width: 100% !important; */
  text-align: center !important;
  /* background-color: red !important;
  color: red ; */
  /* background-image: url(./assets/images/palceholderimage.webp) !important */
    }
    /* .carousel-product{
      height: 100%;
    } */
    .libasLogo{
      width: 140px;
      height: 55px;
    }
    .lazy-img{
      width: 320px;
      height: 320px;
    }
    .text-startAr{
      text-align: right;
    }
    .text-lg-left{
      text-align: right;
    }
    .text-startAr2{
      text-align: right;
    }
    .issue3{
      width: 738px;
height: 619px;
    }
   .rotateArrow{
    transform: rotate(180deg);
   }
   .padding-cards{
padding-inline: 2.3rem!important;
   }
   .containerimage{
    position: relative;
  text-align: center;
   }
   .centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .colorfocus{
   
  color:  #C6AC96 !important
  }
.txt-accordionsize{
  font-family: 'Montserrat-Reg';
font-style: normal;
font-weight: 400;
font-size: 13px;
line-height: 16px;
text-transform: capitalize;

color: #000000;

}
.circlesize{
  font-family: 'Montserrat-Reg';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 31px;
  text-transform: capitalize;
  width: 31px !important;
  height: 31px;
  border-radius: 50%;
  margin: auto;

  text-align: center;
  color: #000000;
}
.txtsize{
  font-family: 'Montserrat-Reg';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 31px;
  text-transform: capitalize;
  /* width: 31px !important;
  height: 31px; */
  color: #000000;

}
.txtsizeselected{
  font-family: 'Montserrat-Reg';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 31px;
  text-transform: capitalize;
  /* width: 31px !important;
  height: 31px; */
  color: #C6AC96;

}
.circlesizecolornotselctedbabies{
  background: rgba(234, 234, 234, 0.4);

}
.circlesizecolornotselcted{

  background: rgba(234, 234, 234, 0.4);

  }
.circlesizecolorselect{
color: white;
background-color:  rgba(198, 172, 150, 0.74) !important
}
.babiesizecolorselect{
  color: white;
  }
.size{
  font-family: 'Montserrat-Reg';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 18px;
text-transform: capitalize;

color: #000000;
}
.widthsize{
  width: 83%;
}
.padding-accrordion{
  padding: 1rem 2.25rem !important;
}

.arrow {
  width: 40px;
  height: 40px;
  /* position: absolute; */
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

/* Styles for the left arrow in RTL mode */
.left-arrow {
  left: 0;
}

/* Styles for the right arrow in RTL mode */
.right-arrow {
  right: 0;
}
.howtosell3{
  width: 845px;

}
.faq-txt{
  font-family: "Montserrat-Reg"!important;
  /* text-transform: uppercase; */
  font-size: 27px!important;
  line-height: 35px!important;
  letter-spacing: 0.11em;
  color: black!important;
}
.faq-txt2{
  font-family: 'Montserrat-Reg';
font-style: normal;
font-weight: 400;
font-size: 22px !important;
line-height: 45px;
/* or 150% */

letter-spacing: 0.11em;

color: #000000;
}
.faq-txt2:hover{
  color: #000000;
}
.loading{
  width:80px;
}
.placeholder-image{
  width: 320px;
  height: 320px;

}


.placeholder-imagebanner{
  width: 100%;
  height: 540px;
}
.media-icons2 {
  width:31px;
height: 31px;
}
.position-txt{
  position: absolute;
  bottom: 6%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.position-txt2{
  position: absolute;
  bottom: 4%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.mainblogtxt{
  font-family: 'Montserrat-M500';
font-style: italic;
font-weight: 500;
font-size: 28px;
line-height: 41px;
/* or 146% */

text-align: center;
letter-spacing: 0.11em;
text-transform: uppercase;

color: #000000;

}
.mainblogtxt2{
  font-family: 'Montserrat-M500';
font-style: italic;
font-weight: 500;
font-size: 24px;
line-height: 41px;
/* or 146% */

text-align: center;
letter-spacing: 0.11em;
text-transform: uppercase;

color: #000000;

}
.containermain {
  position: relative;
  text-align: center;
  
}
.textBlog
{
  font-style: italic!important;
}
.mainblogpadding{
padding-top: 28%;
padding-bottom: 4%;
}
.steppers{
  width: 66px;
height: 66px;
}
.stpppers-title{
  font-family: 'Montserrat-SemiB';
font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 24px;
/* or 140% */


/* color: #404040; */
}
.stpppers-title3 {
  font-family: 'Montserrat-Reg';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  /* or 140% */
  
  
  color: #404040;
  
}
.stpppers-title2{
  font-family: 'Montserrat-M500';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  /* or 156% */
  
  
  color: #404040;
}
.reactanglesteppers{
  width: 4.5px;
}
.disabled-div {
  pointer-events: none;
  opacity: 0.5; /* optional */
}
.colorequal{

  
  color: #C6AC96 !important;}
  .colorbiger{
    color: #404040 !important
  }
  .elsecolor{
    color: #C4C4C4 !important
  }
  .viewmore{
    font-family: 'Montserrat-M500';
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 14px;
/* or 140% */

text-decoration-line: underline;

color: #404040;
  }
  .failedbox{
    font-family: 'Montserrat-SemiB';
font-style: normal;
font-weight: 600;
font-size: 12px;
line-height: 11px;
padding-top: 10px;
    padding-bottom: 10px;
    width: 89px;
    text-align: center;
background: rgba(255, 0, 0, 0.16);
color: #E54343;
  }
  .orderfailed{
    font-family: 'Montserrat-SemiB';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 11px;
   
    color: red;
  }
  .orderreson{
    font-family: 'Montserrat-M500';
    font-style: normal;
    
    font-size: 16px;
    line-height: 11px;
   
    color: #404040;
  }
  .header {
    position: relative;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
   
    z-index: 1;
  }
  /* .header a {
    font-family: 'Montserrat-M500';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 10px;


color: #000000;
  } */
  .header ul {
    list-style: none;
  }
 
  .menu-items {
    font-family: 'Montserrat-M500';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 10px;
    display: flex;
    align-items: center;
  }
  .menu-items span {
    /* padding: 0.5rem 1rem; */
    transition: background 0.3s ease-in-out;
  }

  .menu-items span:hover {
    /* background-color: #ff652f; */
    font-family: 'Montserrat-M500'!important;
    border-bottom: 1.5px solid #C6AC96;
    /* padding-top: 10px; */
    color: #1A1818;
    }
  
  .menu-item {
   
    /* padding-bottom: 15px; */
    font-family: 'Montserrat-Reg';
    color: #808080;
    text-decoration: none;
    display: flex;
    justify-content: space-between;
    width: 100%;
    
  }
  .menuhover :hover {
    text-decoration: none;
    /* font-family: 'Montserrat-SemiB'; */
    color: #1A1818;
  }
 
  .mega-menu {
    position: absolute;
    left: 0;
    width: 100%;
    top:100px;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease;
  }
  .mega-menu .content {
    background: white;
  
  }

  .content .col {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    line-height: 3rem;
  }
  

  .content .col h2 {
    /* color: #ff652f; */
    font-size: 1.2rem;
    line-height: 3rem;
    font-weight: bold;
  }
  .content .col p {
    line-height: 1.2rem;
  }
  .content .col .mega-links {
    border-left: 1px solid #1a3246;
  }
  .content .col .read-more {
    display: inline-block;
    padding-top: 1rem;
    /* color: #427dad; */
    transition: color 0.3s ease;
  }
  .col .mega-links li,
  .col .mega-links li a {
    padding: 0 1rem;
  }
  .menu-items span:hover .mega-menu {
    top: 73px;
    opacity: 1;
    visibility: visible;
  }
  /* .bordermegamenu :hover{
    font-family: 'Montserrat-M500'!important;
    border-bottom: 1.5px solid #C6AC96;
    color: #1A1818;
  } */
  .mega-menuCLick {
    visibility: hidden !important;
  }
.titlemega{
  font-family: 'Montserrat-M500';
font-style: normal;
font-weight: 500;
font-size: 15px;
line-height: 30px;
/* identical to box height, or 67% */

text-decoration: none;
color:#1A1818;
}
.subtitlemega{
  text-decoration:  none !important;
  font-family: 'Montserrat-Reg';
font-style: normal;
font-weight: 400;
font-size: 14px;
color:#1A1818;

}
.subtitlemega:hover ,.subtitlemega:focus{
  color: #C6AC96;
  font-size: 16px;
}
.padding-sectionmega{
  padding-left: 100px;
}
.padding-sectionmegaAr{
  padding-right: 100px;
}
.viewallbrands{
  font-family: 'Montserrat-M500';
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 10px;
color: #1A1818;
padding-inline: 20px;
padding-top: 8px;
padding-bottom: 8px;
border: 1px solid #C6AC96;
cursor: pointer;
}
.viewallbrands:hover{
  background: #C6AC96;
  color: white;
  border: 0px;
}
.input-group-textfa-search{
  border:0px !important;
  /* border-bottom: 1px solid #404040 !important */
}
.input-group-textfa{
  border:0px !important;
  border-bottom: 1px solid #404040 !important;
  border-radius: 3px;
}
.navbar-linksSell{
  font-family: 'Montserrat-Reg';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 10px;
justify-content: center;
display: flex;
/* padding-inline: 20px; */
padding-top: 17px;
width: 110px;
padding-bottom: 17px;
background: #C6AC96;
border-radius: 3px;
color: #FFFFFF;
cursor: pointer;
}
.location-set-btn{
  font-family: 'Montserrat-Reg';
font-style: normal;
font-weight: 500;
font-size: 14px;
justify-content: center;
display: flex;
/* padding-inline: 20px; */
padding-top: 8px;
width: 40rem;
height: 40px;
margin: 23px;
padding-bottom: 8px;
background: #C6AC96;
color: #FFFFFF;
cursor: pointer;
}

.sortstylen{
  color: #1A1818;

}
.sortstyleselected{
  color: #C6AC96 !important;

}
.sortstyle{
  font-family: 'Montserrat-Reg';
  font-style: normal;

  font-size: 16px;
  line-height: 22px;
  text-transform: capitalize;
  text-decoration: none;
  color: #1A1818;

}

.sortstyle:hover {
  color: #97816d;
}
.bgsort{
  background-color: #C6AC96 ;

}
.sortdropdown
{
  border-radius: 0px !important;
  border: 0px !important;
  width: 208px  !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important
}
.sortbyttitle{
  font-family: 'Montserrat-M500';
  font-style: normal;
  font-size: 16px;
  /* line-height: 29px; */
  text-transform: capitalize;
  color: #1A1818;
background-color: transparent;
border: 0px;
}

.text-endAr{
  text-align: left;
}
.searchmenu{
  font-family: 'Montserrat-M500';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 39px;
/* identical to box height, or 71% */


color: #1A1818;
}
.searchmenu:focus ,.searchmenu:hover{
 
  border-bottom: 1px solid #C6AC96;
}
.Previous{
  width: 7.28px;
  height: 12.54px;
}
.PreviousAr{
  width: 7.28px;
  height: 12.54px;
  transform: rotate(180deg);
}
.searchmenucrypt{
  font-family: 'Cormo';
font-weight: 500;
font-size: 20px;
line-height: 39px;
/* identical to box height, or 50% */


color: #1A1818;
}
.searchmenucrypt:focus ,.searchmenucrypt:hover{
 
  border-bottom: 1px solid #C6AC96;
}
.searchmenutitle{
  font-family: 'Montserrat-M500';
font-style: normal;
font-weight: 500;
font-size: 17px;
line-height: 39px;
color: #1A1818;
}
.searchmenunew{
  font-family: 'Montserrat-M500';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 39px;
  color: #1A1818;
  cursor: pointer;
}
.searchmenunew:hover , .searchmenunew:focus{
 
  color: #C6AC96;
}
.fa-search-brand2 {
  top: 10px;
 
}

.fa-search-brand2country {
  top: 10px;
 
}

/* .css-1aarvou-DropdownHandleComponent{
  content: url(../../assets/images/arrowopen.svg) !important; 

} */
.trackparcel{
  font-family: 'Montserrat-M500';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 14px;
color: #FFFFFF;
  text-decoration: none;
  background: #1A1818;
  padding-inline: 7px;
  padding-top: 3px;
  padding-bottom: 3px;
cursor: pointer;
}
.trackparcel:hover{
  color: #FFFFFF;

}
.track{
  width: 100%;
}
.sortbyttitle{
  content: url(./assets/images/arrowopen.svg) !important; 

}
.sortbyttitle::after {
  content:"" !important; 
  /* transform: rotate(180deg) !important; */

  border: 0px !important;
}
.selectedsort{
  /* border-bottom: 0.5px solid #1A1818 !important; */

}
/* .underlinedropdown{
  border-bottom: 0.5px solid #1A1818;
} */
/* .open::after {
  content: url(./assets/images/arrowopen.svg) !important; 

} */
.arrowsort{
  height: 8px;
  width: 13px;

}
.arrowsort2{
  height: 8px;
  width: 13px;
  transform: rotate(180deg);

}
.homepagesubtxt{
  color: #1A1818;
text-align: center;
font-size: 22px;
font-family: "Montserrat-Reg";
font-style: normal;
font-weight: 400;
line-height: normal;
/* text-transform: uppercase; */
}
.banner{
background-color: #FBF5F0;
color: #262626;
text-align: center;
font-size: 18px;
font-family: "Montserrat-L300";
font-style: normal;
line-height: auto;

/* text-transform: uppercase; */
}
.trust-banner{
  height: 38vh;
  min-height: 340px;
  background-color: #FBF5F0;
  color: #1A1818;
text-align: center;
font-size: 24px;
font-family: "Montserrat-Reg";
font-style: normal;
line-height: normal;

/* text-transform: uppercase; */
}
.trust-maintitle-banner{
  height: 5vw;
text-align: center;
font-size: 40px;
font-family: "Montserrat-Reg";
font-style: normal;
line-height: auto;
padding-top: 39px;
padding-bottom: 24px;
/* text-transform: uppercase; */
}
.trust-image-banner{
  width: 76px;
}
.trust-subtitle-banner{
  height: 5vw;
text-align: center;
font-size: 14px;
font-family: "Montserrat-Reg";
font-style: normal;
line-height: 142%;
width: 278px;
/* text-transform: uppercase; */
}
.bannerIn { 
  background-color: #F4F0EC80; 
  color: #1A1818; 
  text-align: center; 
  font-size: 22px; 
  font-family: "Montserrat-Reg"; 
  font-style: normal; 
  font-weight: 400; 
  line-height: normal; 
}
.bannerInPhone { 
  background-color: #F4F0EC80; 
  color: #1A1818; 
  text-align: center; 
  font-size: 18px; 
  font-family: "Montserrat-Reg"; 
  font-style: normal; 
  font-weight: 400; 
  line-height: normal; 
}
/* .bannerIn{
  background-color: #F4F0EC80;
  color: #1A1818;
text-align: center;
font-size: 22px;
font-family: "Montserrat-Reg";
font-style: normal;
font-weight: 400;
line-height: normal;
text-transform: uppercase;
} */
.multiusewidth{
  width: 155px;
  cursor: pointer;
}
.categories-img{
  width: 200px;
  height: 200px;
  transform: scale(1, 1);
  transition: transform 0.4s;
  
}
.rounded-img{
  width: 150px;
  height: 150px;
  border-radius: 50%;
  transform: scale(1, 1);
  transition: transform 0.4s;
}
.mainblog-img{
  transform: scale(1, 1);
  transition: transform 0.4s;
}
.mainblog-img:hover{
  transform: scale(1.05, 1.05);
}
.btn-shop{
  transform: scale(1, 1);
  transition: transform 0.4s;
}
.btn-order{
  transform: scale(1, 1);
  transition: transform 0.4s;
}
.btn-store2{
  transform: scale(1, 1);
  transition: transform 0.4s;
}
.btn-tiktok{
  transform: scale(1, 1);
  transition: transform 0.4s;
 }
.btn-store2:hover{
  transform: scale(1.05, 1.05);
}
.btn-store3{
  transform: scale(1, 1);
  transition: transform 0.4s;
}
.btn-store3:hover{
  transform: scale(1.05, 1.05);
}
.navbar-linksSell  {
  transform: scale(1, 1);
  transition: transform 0.4s;
}
.btn-offer {
  transform: scale(1, 1);
  transition: transform 0.4s;
}
.btn-2Image {
  transform: scale(1, 1);
  transition: transform 0.4s;
}
.btn-offer:hover {
  transform: scale(1.05, 1.05);

}
.btn-2Image:hover {
  transform: scale(1.05, 1.05);

}
.btn-refer{
  transform: scale(1, 1);
  transition: transform 0.4s;
}
.btn-successrefer{
  transform: scale(1, 1);
  transition: transform 0.4s;
}
.btn-referhome{
  transform: scale(1, 1);
  transition: transform 0.4s;
}
.btn-sellhome{
  transform: scale(1, 1);
  transition: transform 0.4s;
}
.trackbtn{
  transform: scale(1, 1);
  transition: transform 0.4s;
}
.btnsharelink{
  transform: scale(1, 1);
  transition: transform 0.4s;
}
.btnsharelink:hover {
  transform: scale(1.05, 1.05);

}
.btn-referhome:hover {
  transform: scale(1.05, 1.05);

}
.btn-sellhome{
  transform: scale(1.05, 1.05);

}
.trackbtn:hover {
  transform: scale(1.05, 1.05);

}
.btn-refer:hover {
  transform: scale(1.05, 1.05);

}
.btnsuccessrefer:hover {
  transform: scale(1.05, 1.05);

}
.btn-send{
  transform: scale(1, 1);
  transition: transform 0.4s;
}
.btn-send:hover {
  transform: scale(1.05, 1.05);

}
.btn-general{
  transform: scale(1, 1);
  transition: transform 0.4s;
}
.btn-general:hover {
  transform: scale(1.05, 1.05);

}
.navbar-linksSell:hover{
  transform: scale(1.05, 1.05);

}
.btn-order:hover{
  transform: scale(1.05, 1.05);

}
.btn-shop:hover{
  transform: scale(1.05, 1.05);

}
/* .text-block{
  transform: scale(1, 1);
  transition: transform 0.4s;
}
.text-block:hover{
  transform: scale(1.05, 1.05);

} */
.text-block2{
  transform: scale(1, 1);
  transition: transform 0.2s;
}
.text-block2:hover{
  transform: scale(1.05, 1.05);
}
.vintage{
  transform: scale(1, 1);
  transition: transform 0.4s;
}
.vintage:hover{
  transform: scale(1.05, 1.05);
}
.authimg{
  transform: scale(1, 1);
  transition: transform 0.4s;
}
.authimg:hover{
  transform: scale(1.05, 1.05);
}
.categories-img:hover{
  transform: scale(1.05, 1.05);
}
.rounded-img:hover{
  transform: scale(1.05, 1.05);
}
.card{
  cursor: pointer;
}
.img-profile{
  transform: scale(1, 1);
  transition: transform 0.4s;
}
.img-profile:hover{
  transform: scale(1.05, 1.05);
}
.signupaboutus{
  transform: scale(1, 1);
  transition: transform 0.4s;
}
.signupaboutus:hover{
  transform: scale(1.05, 1.05);
}
.btnhomepage{
  font-style: normal;
    font-family: 'Montserrat-M500';
    font-size: 20px;
    line-height: 15px;
    text-align: center;
    color: #000000;
    background-color: transparent;
    border: 1px solid #000;
    width: 290.33px;
    height: 68.68px;
}
.containercategorie {
  position: relative;
}

.categories-img {
  opacity: 1;
  display: block;
 
  transition: .5s ease;
  backface-visibility: hidden;
}
.middle {
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.containercategorie:hover .categories-img {
  /* opacity: 0.5; */
}

.containercategorie:hover .middle {
  opacity: 1;
}
/* .containercategorie img {
  transition: transform 1s, filter 2s ease-in-out;
  filter: blur(2px);
  transform: scale(1.2);
} */

/* The Transformation */
/* .containercategorie:hover img {
  filter: blur(2px);
  transform: scale(1.2);
} */
.text {
  background-color: #04AA6D;
  color: white;
  font-size: 16px;
  padding: 16px 32px;
}
.cremedelacreme{

    position: absolute;
    bottom: -32px;
    left: 50%;
    transform: translate(-50%, -50%);
}

.cremedelacremeimg{
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.cremedelacremeproduct{
  width: 204px;
  height: 204px;
}
.cremedelacremeproductheight{
  height: 274px;

}
.cremedelacremeusername{
  color: #1A1818;
text-align: center;
font-family: "Montserrat-Reg";
font-size: 17px;
font-style: normal;
text-transform: uppercase;
}

.btnscrolltop{
  position: fixed; 
   /* width: 100%; */
   right: 0.7%;
   bottom: 40px;
   height: 12px;
   font-size: 3rem;
   z-index: 1;
   cursor: pointer;
   color: green;
}
[aos="fade"] {
  opacity: 0;
  transition-property: opacity;
}

[aos="fade"].crypt-sub {
  opacity: 1;
}

/* .vintage:hover {
  opacity: 0.5;
} */
.soldimgcolor{
  transition: .5s ease;
  opacity: 0.5;

}

.containerimage {
  position: relative;
}


.bannertitle{
  color: #1A1818;
text-align: center;
font-family: "Montserrat-M500";
font-size: 23px;
font-style: normal;
font-weight: 500;
line-height: 16px;
}
.bannersubtitle{
  color: #1A1818;
  text-align: center;
  font-family: "Montserrat-M500";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  height: 46px;
  display: grid;
  align-content: space-around;
}
.bannerbtn{
  color: #1A1818;
  text-align: center;
  font-family: "Montserrat-M500";
  font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: 16px;
text-decoration-line: underline;
cursor: pointer;
}
.text-block-discount { 
  left: 50%; 
  transform: translate(-50%, -50%); 
  position: absolute; height: 111px; 
  bottom: 25vh; 
  justify-content: center; 
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.02); 
  color: #1A1818; display: grid; 
  align-content: space-around; 
  width: 65%; 
}
.text-block-discount-phone { 
  left: 50%; 
  transform: translate(-50%, -50%); 
  position: absolute; 
  height: 121px; 
  bottom: 30%; 
  justify-content: center; 
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.02); 
  color: #1A1818; 
  display: grid; 
  align-content: space-around; 
  width: 80%; 
}
/* .text-block-discount {
   
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  height: 111px;
  bottom: 200px;
  justify-content: center;
  right: 32%;
  background-color: black;
  background: #FFF;
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.02);
  color: #1A1818;
  display: grid;
  align-content: space-around;
  width: 60%;
} */
.text-block {
   
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  height: 111px;
  bottom: -14px;
  justify-content: center;
  /* right: 32%; */
  background-color: black;
  background: #F4F0EC80;
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.02);
  color: #1A1818;
  display: grid;
  align-content: space-around;
  width: 300px;
}
.text-blockrefer {
  width: 813px;
height: 282px;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  bottom: 31px;
  justify-content: center;
  /* right: 32%; */
  background-color: black;
  background: rgba(255, 255, 255, 0.78);
    color: #1A1818;
  display: grid;
  align-content: space-around;
}
.currencyWidth{
  width: 220px;
}
.travel-txt{
  color: white;
  text-align: center;
  font-family: "Montserrat-M500";
  font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 26px;
}
.modal-travel.modal-content  {
  -webkit-border-radius: 0px !important;
  -moz-border-radius: 0px !important;
  border-radius: 12px !important; 
}

.modal-travelbg{
  background-color: #00e676;
  /* border-radius: 12px; */

}
.modal-travelbg .modal-content{
  border-radius: 12px;
}
.modal-travelbg .modal-body{
  border-radius: 12px;
}
:root {
  --main-color: #ecf0f1;
  --point-color: #C6AC96;
  --size: 3px;
  --phonesize: 2px;
}

.loader2 {
  overflow: hidden;
  /* justify-content: center;
  width: 100%;
  height: 100%; */
  top: 0; left: 0;
  display: flex;
  align-items: center;
  align-content: center; 
  justify-content: center;  
  z-index: 100000;
}

.loader__element {
  border-radius: 100%;
  border: var(--size) solid var(--point-color);
  margin: calc(var(--size)*2);
}

.loader__element:nth-child(1) {
  animation: preloader .6s ease-in-out alternate infinite;
}
.loader__element:nth-child(2) {
  animation: preloader .6s ease-in-out alternate .2s infinite;
}

.loader__element:nth-child(3) {
  animation: preloader .6s ease-in-out alternate .4s infinite;
}

@keyframes preloader {
  100% { transform: scale(2); }
}
.travelmode .modal-content{
  margin-inline: 1rem;
}
.signinmodal .modal-content{
  height: fit-content;

}
.signinimg{
  height: 563px !important;
  width: 319px !important
}
.signwidth .modal-dialog {
  max-width: 846px;
}

@media screen and (max-width: 540px){
  .signwidth .modal-dialog {
    max-width: auto;
    padding-inline: 38px;
  }
  }
@media screen and (max-width: 406px){
  .signwidth .modal-dialog {
      max-width: auto;
      padding-inline: 27px  !important;
  }
  }
  @media screen and (max-width: 360px){
    .signwidth .modal-dialog {
        max-width: auto;
        padding-inline: 16px  !important;
    }
    }
.signwidth .modal-content {
  border-radius: 0px !important;
}
/* max-width: 1140px; */
.togglebbtn{
  color: #1A1818;
font-family: 'Montserrat-Reg';
font-size: 22px;
font-style: normal;
font-weight: 400;
line-height: 31px; 
text-transform: uppercase;
cursor: pointer;
}
.forgetpass{

  color: #404040;
font-family: 'Montserrat-M500';
font-size: 10px;
font-style: normal;
font-weight: 500;
line-height: normal;
text-decoration-line: underline;
margin-top: 0.25rem;
cursor: pointer;
}
.btn-login{
background: #1A1818;
color: white;
font-family: 'Montserrat-M500';
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: normal;height: 40px;
justify-content: center;
cursor: pointer;
}
.togglesign{
  border-bottom: 2px solid #C6AC96;
}
.roleximgcrypt{
height: 519px !important;
}

.form-email{
  padding: 0rem 0.75rem !important;
}
.form-signup{
  border: 0.5px solid #404040 !important;

border-radius: 0px !important;
padding: 0.275rem 0.75rem !important;

}
.form-signupotp{
  border: 0.5px solid #404040 !important;

  border-radius: 0px !important;
  padding: 0.1rem 0.75rem !important;
}
.form-signupotp:focus{
  border:  0.5px solid #404040 !important;

border-radius: 0px !important;
padding: 0.1rem 0.75rem !important;

}
.form-signup:focus{
  border:  0.5px solid #404040 !important;

border-radius: 0px !important;
padding: 0.275rem 0.75rem !important;

}
.form-signup::placeholder{
  color: #404040;
  font-family: 'Montserrat-M500';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  align-items: center !important;
  display: flex;
  

}
.form-signupotp::placeholder{
  color: #404040;
  font-family: 'Montserrat-M500';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  align-items: center !important;
  display: flex;
  

}
.formlogin2{
  border: 0.5px solid #404040 !important;
  -webkit-border:0.5px solid #404040 !important ;
border-radius: 0px !important;
padding: 0.575rem 0.75rem !important;

}

.formlogin2:focus{
  border: 0.5px solid #404040 !important;

border-radius: 0px !important;
padding: 0.575rem 0.75rem !important;

}
.formloginshow{
  border: 0.5px solid #404040 !important;

border-radius: 0px !important;
padding: 0.575rem 0.75rem !important;
border-right: 0px !important;

}
.formloginshowAr{
  border: 0.5px solid #404040 !important;

  border-radius: 0px !important;
  padding: 0.575rem 0.75rem !important;
  border-left: 0px !important;
}
.formloginshowAr:focus{
  border: 0.5px solid #404040 !important;
  
  border-radius: 0px !important;
  padding: 0.575rem 0.75rem !important;
  border-left: 0px !important;
}
.formloginshow:focus{
  border: 0.5px solid #404040 !important;
  
  border-radius: 0px !important;
  padding: 0.575rem 0.75rem !important;
  border-right: 0px !important;
}
.faform-loginshow {
  border: 0.5px solid #404040 !important;
  border-left: 0px !important;
  font-size: 10px !important;
  color: #404040 !important;
  cursor: pointer;
  text-transform: uppercase;
}
.faform-loginshowAr {
  border: 0.5px solid #404040 !important;
  border-right: 0px !important;
  font-size: 10px !important;
  color: #404040 !important;
  cursor: pointer;
  text-transform: uppercase;
}
.faform-loginshowAr:focus{
  border: 0px  !important;

}
.faform-loginshow:focus{
  border: 0px  !important;

}
.formlogin2::placeholder{
  color: #404040;
  font-family: 'Montserrat-M500';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  align-items: center !important;
  display: flex;

}
.formloginshow::placeholder{
  color: #404040;
  font-family: 'Montserrat-M500';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  align-items: center !important;
  display: flex;

}
.formloginshowAr::placeholder{
  color: #404040;
  font-family: 'Montserrat-M500';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  align-items: center !important;
  display: flex;

}
.widthrefer{
  width: 73%;
}
.widththxrefer{
  width: 55%;


}
.refertxt{
  color: #1A1818;
text-align: center;
font-family: 'Montserrat-M500';
font-size: 30px;
font-style: normal;
font-weight: 500;
line-height: normal;
}
.refertxt2{
  color: #1A1818;
  text-align: center;
  font-family: 'Cormo';
  font-size: 36px;
  font-weight: 500;
  line-height: normal;
}
.refersubtxt{
  color: #1A1818;
text-align: center;
font-family: 'Montserrat-M500';
font-size: 28px;
font-style: normal;
font-weight: 500;
line-height: 60px;
}
.image-containerr {
  position: relative;
  max-width: 100%;
  overflow: hidden;
}
.widthreferhome{
  position: relative;
  overflow: hidden; /* Hide overflowing content */
  width: 80%;
}

.imgp {
  max-width: 100%;
  height: auto;
  display: block;
}

.text-overlay {
  position: absolute;
  top: 15%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 0.78)  ;
  color: white;
  width: 55%;
  height: 282px;
  text-align: center;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}
/* .text-overlay h2{
  transform: scale(1, 1);
  transition: transform 0.2s;
}
.text-overlay h2:hover{
  transform: scale(1.05, 1.05);
} */
.text-overlayinvitefriends {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background: rgba(255, 255, 255, 0.80);
  box-sizing: border-box;
  align-content: center;
  flex-wrap: wrap;
  }
  .overlayvideo{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background: rgba(255, 255, 255, 0.3);
    box-sizing: border-box;
    align-content: center;
    flex-wrap: wrap;
  }
  .text-overlayaboutUs {
    position: absolute;
    top: -26px;
    left: 3rem;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
    box-sizing: border-box;
    align-content: center;
    flex-wrap: wrap;
    }
    .text-overlayaboutUs{
      position: absolute;
    top: -26px;
    right: 3rem;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
    box-sizing: border-box;
    align-content: center;
    flex-wrap: wrap;
    }
    .text-overlayaboutUsAr{
      position: absolute;
      top: -26px;
      right: 3rem;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-end;
      box-sizing: border-box;
      align-content: center;
      flex-wrap: wrap;
    }
  .text-overlayinvitefriendshome{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    box-sizing: border-box;
    flex-wrap: wrap;
    align-content: space-around;
  }

  .text-overlayinvitefriends2 {
    position: absolute;
    top: 100px;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    align-content: center;
    flex-wrap: wrap;
    }
  .text-overlaythx {
    top: 19%;
    align-items: center;
    background-color: hsla(0,0%,100%,.78);
    color: #fff;
    display: flex;
    height: 282px;
    justify-content: center;
    left: 50%;
    position: absolute;
    text-align: center;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    width: 55%;
    }
.text-overlay1 {
  position: absolute;
  top: 4%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 0.78)  ;
  color: white;
  width: 409px;
  height: 55px;
  text-align: center;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.bgcreditearned{
  background-color: rgba(255, 255, 255, 0.78)  ;
  width: 409px;
  height: 55px;
  text-align: center;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.text-overlay2 {
  position: absolute;
  top: 4%;
  right: -2%;

  transform: translate(-50%, -50%);
  border-radius: 24px;
  background: #1A1818;
  color: white;
  width: 123px;
  height: 29px;
  text-align: center;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.text-overlayx {
  position: absolute;
  top: 8%;
    right: 2%;

  transform: translate(-50%, -50%);
  
}

.text-overlayxx {
  position: absolute;
  top: 4%;
    right: 2%;

  transform: translate(-50%, -50%);
  
}
.referalModal .modal-content{
  border-radius: 0px !important;
}
.successreferalModal .modal-content{
  border-radius: 0px !important;
}
.trackbtn{
  color: #FFF;
font-family: 'Montserrat-M500';
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: normal;
cursor: pointer;
}
.text-overlay h2 {
  margin-top: 0;
  color: #1A1818;
font-family: 'Montserrat-M500';
font-size: 28px;
font-style: normal;
font-weight: 500;
line-height: normal;
}

.text-overlay p {
  margin-bottom: 0;
  color: #1A1818;
text-align: center;
font-family: 'Cormo';
font-size: 60px;
font-weight: 500;
line-height: normal
}
.text-overlaythx p {
  margin-bottom: 0;
  color: #1A1818;
text-align: center;
font-family: 'Cormo';
font-size: 60px;
font-weight: 500;
line-height: normal
}
.creditearned{
  color: #1A1818;
font-family: 'Montserrat-M500';
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;
}
.REFERRED{
  color: #1A1818;
font-family: 'Montserrat-M500';
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;
}
.referlink{
  width: 600px;
height: 77px;
background-color: white;
color: #1A1818;
text-align: center;
font-family: 'Montserrat-M500';
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: 20px;
align-items: center;
}
.sharereferal{
  width: 23.034px;
height: 23px;
}
.copiedreferal{
  font-size: 15px;
  color: #1A1818;
}
.center-box {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 20px;
  box-sizing: border-box;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.refralmodaltitle{
  color: #1A1818;
font-family: 'Montserrat-M500';
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
text-align: center;
}
.refralmodalsubtitle{
  color: #1A1818;
text-align: center;
font-family: 'Cormo';
font-size: 34px;
    width: 225px;
    font-weight: 500;
line-height: normal;
}
.btn-refer{
  color: white;
font-family: 'Montserrat-Reg';
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
background: #000;
width: 153px;
height: 39px;
align-items: center;
display: flex;
cursor: pointer;
}
.btn-successrefer{
  color: white;
  font-family: 'Montserrat-Reg';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background: #000;
  width: 156px;
  height: 39px;
  align-items: center;
  display: flex;
  cursor: pointer;
}
.btn-referhome{
  color: #000000;
  font-size: 15px;
 
  width: 229.281px;
  height: 54.236px;
  font-family: 'Montserrat-Reg';
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background: white;
 
  align-items: center;
  display: flex;
  cursor: pointer;
}
.btn-sellhome{
  color: #000000;
  font-size: 15px;
  width: 229.281px;
  height: 54.236px;
  font-family: 'Montserrat-M500';
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background: white;
 
  align-items: center;
  display: flex;
  cursor: pointer;
}
.referalModal .modal-dialog {
  max-width: 370px;
}
.successreferalModal .modal-dialog {
  max-width: 370px;
}

.refralmodalsubtitlethx {
  color: #1A1818;
    text-align: center;
    font-family: 'Cormo';
    font-size: 28px;
    width: 316px;
    font-weight: 500;
    line-height: normal;
}
.refralmodalsubtitlethx2{
  color: #1A1818;
font-family: 'Montserrat-M500';
font-size: 12px;
font-style: normal;
line-height: 30px;
font-variant: small-caps;
}
.background-imageinvite {
  position: relative;
      width: 100%;
      height: 100vh; /* Adjust to your preferred height */
      background: linear-gradient(0deg, rgba(255 255 255 / 54%),  rgba(255, 255, 255, 0.80)), url("./assets/images/ReferalProgram/refer1.webp");
      /* background: rgba(255, 255, 255, 0.80); */
      background-repeat:no-repeat;
      background-size: cover;

 
}
.inviteimg{
  background: linear-gradient(0deg, rgba(255 255 255 / 54%),  rgba(255, 255, 255, 0.80));

}
.overlay-textinvite {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
}
.invitetitle{
  color: #1A1818;
font-family: 'Montserrat-M500';
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: normal;
}
.text-overlayinvite {
  position: absolute;
  top: 15%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;

  text-align: center;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.text-overlayinvite2{
  position: absolute;
  top: 15%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;

  text-align: center;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: #1A1818;

}
.invitetitle2{
  font-family: 'Montserrat-M500';
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: #1A1818;
  text-align: center;
  font-family: 'Cormo';
  font-size: 55px;
  font-weight: 500;
  line-height: normal;
}
.text-overlayinvite3{
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
 
  text-align: center;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.invitetitle3{
  color: #1A1818;
text-align: center;
font-family: 'Montserrat-M500';
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: 35px; 
}
.img1212 {
  max-width: 100%;
  height: auto;
  display: block;
}


.image-container2 {
  position: relative;
  max-width: 100%;
}



/* .centered-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 200px;
  height: 200px;
} */

.thxrefer{
  color: #1A1818;
text-align: center;
font-family: 'Montserrat-M500';
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: 50px; 
}
.btnsharelink{
  background: #C6AC96;
  align-items: center;
  justify-content: center;
  display: flex;
  width: 194px;
height: 46px;
color: #FFF;
font-family: 'Montserrat-M500';
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: normal;
cursor: pointer;
}

.descriptionlinkwidth{
  width:490px;
height: 280px;
}
.descriptionlink{
  justify-content: center;
    display: grid;
    align-content: space-evenly;
  color: #1A1818;
text-align: center;
font-family: 'Montserrat-M500';
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: 30px;
}
/* .parallax {
  min-height: 299px; 
background-image: url(./assets/images/ReferalProgram/refermodal.webp);
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: 100%;
} */
.prof-imgrfer{
  width: 37px;
height: 37px;
border-radius: 50%;
}
.referusername{
  color: #1A1818;
text-align: center;
font-family: 'Montserrat-M500';
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: 35px; 
}
.followfriends{
  width: 100px;
height: 30px;
display: flex;
justify-content: center;
align-items: center;
background: #C6AC96;
color: #FFF;
font-family: 'Montserrat-M500';
font-size: 15px;
font-style: normal;
font-weight: 500;
line-height: normal;
border: none;

}
.widthreferhomebox{
  display: grid;
    align-content: center;
    justify-content: space-around;

  width: 90%;
  height: 70%;
}
.refraltitle{
  color: #1A1818;
font-family: 'Montserrat-M500';
font-size: 28px;
font-style: normal;
font-weight: 500;
line-height: normal;
}
.iframeytb{
  width: 1406px;
  height: 818px;;
}

.responsive-iframe {
  position: absolute;
  top: 0;
  width: 75%;
  height: 75%;
}
.referprice{
  color: #1A1818;
text-align: center;
font-family: 'Cinz';
font-size: 36px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.thxrefer1{
  color: #1A1818;
text-align: center;
font-family: 'Cormo';
font-size: 32px;
font-weight: 500;
line-height: normal;
}
.parallax-container {
  min-height: 299px; 
background-image: url(./assets/images/ReferalProgram/refermodal.webp);
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: 100%;
}

.parallax-content {
  text-align: center;
}
.parallaxtop {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: stretch;
  justify-content: space-around;
  align-content: stretch;

}
.parallaxtopAr{
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: stretch;
  justify-content: space-around;
  align-content: stretch;
}
.parallax-imagenew {
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.overlay-boxnew {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.parallaxtop {
  
  position: absolute;
  top: 20px;
  left: -18px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: stretch;
  justify-content: flex-end;
  align-content: stretch;
  flex-wrap: nowrap;
  flex-direction: row;
}

.parallaxtopAr {
  
  position: absolute;
  top: 20px;
  right: -18px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: stretch;
  justify-content: flex-end;
  align-content: stretch;
  flex-wrap: nowrap;
  flex-direction: row;
}
.text-overlayparallax {
  


  border-radius: 24px;
  background: #1A1818;
  color: white;
  width: 123px;
  height: 29px;
  text-align: center;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.translated-box {
width: 100%;
  position: relative;
  bottom: 100%; /* Positioned above the image */
  /* left: 50%;
  transform: translate(-50%, 113%); 
  */
}
.successrefer{
  color: #1A1818;
text-align: center;
font-family: 'Cormo';
font-size: 34px;
font-weight: 500;
line-height: normal;
}
.successrefertxt{
  color: #1A1818;
text-align: center;
font-family: 'Montserrat-M500';
font-size: 17px;
font-style: normal;
font-weight: 500;
line-height: 30px; /* 166.667% */
text-transform: uppercase;
}
.phoneinput .selected-flag  .arrow{
  right: 20px !important;
  left: 0px !important;
}
.phoneinput .form-control{
padding-right: 40px !important
}
.phoneinput .country-list .flag{
  margin-left: 7px;
}
/* .phoneinput{
background-color: red;
} */
.sub-aboutsUsTitle{
  font-family: 'Montserrat-Reg';
font-size: 22px;
line-height: 43px; /* 195.455% */
letter-spacing: 2.42px;
color: #000000;
}
.sub-aboutsUsTitle2{
  color: #000;
  text-align: center;
  font-family: 'Montserrat-M500';
  font-size: 24px;
  font-style: italic;
  font-weight: 500;
  line-height: 57px; /* 203.571% */
  letter-spacing: 3.08px;
}
.sub-aboutsUsTitle3 {
  color: #000;
text-align: center;
font-family: 'Montserrat-SemiB';
font-size: 24px;
font-style: normal;
font-weight: 600;
line-height: 30px; /* 107.143% */
letter-spacing: 3.08px;
}
.aboutustitle{
  color: #000;
font-family: 'Montserrat-Reg';
font-size: 30px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 5.5px;
text-transform: uppercase;
}
.aboutustxtsections{
  color: #000;
  font-family: 'Montserrat-Reg';
  font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: 30px; /* 195.455% */
letter-spacing: 2.42px;
}
.signupaboutus{
  text-transform: uppercase;
  cursor: pointer;
  width: 260px;
  height: 80px;
align-items: center;
display: flex;
color: #FFF;
text-align: center;
align-items: center;
justify-content: center;
font-family: 'Montserrat-SemiB';
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: normal;
background-color: #1A1818;
}
.selectedcountrytitle::after{
border: 0px !important;
}
.selectedcountrytitle{
  font-family: 'Montserrat-Reg' !important;
    font-style: normal!important;
    font-size: 16px!important;
    letter-spacing: 0.07em!important;
    color: #404040!important;
    background-position: right 0.75rem center !important;
    border: none !important;
    background-color: white !important;
    padding: 0.375rem 0.75rem 0.375rem 0.75rem !important;
}
.selectedcountrytitle-footer{
  font-family: 'Montserrat-Reg' !important;
    font-style: normal!important;
    font-size: 12px!important;
    letter-spacing: 0.07em!important;
    color: #FFFFFF!important;
    background-position: right 0.75rem center !important;
    border: none !important;
    background-color: white !important;
    padding: 0.375rem 0.75rem 0.375rem 0.75rem !important;
}
.selectedcountry{
  font-family: 'Montserrat-Reg'!important;
    font-style: normal!important;
    /* font-weight: 400!important; */
    font-size: 15px!important;
    line-height: 26px!important;
    text-transform: capitalize!important;
    color: #404040!important;
    padding: 5px 10px;
    cursor: pointer;
    border-bottom: 1px solid #fff;
}
.selectedcountryAr{
  text-align: right;
}
.selectedcountry:hover{
  background: #eaeaea !important;
  color: #404040!important;
}
.dropdowncountry{
  transform:translate3d(0px, 40.6667px, 0px) !important;
  border-radius: 0px !important;
  border: 1px solid #ccc;
  padding: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  background: #fff;
  border-radius: 2px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  max-height: 300px;
  overflow: auto;
  z-index: 9;
}
/* .h100{
  height: 100% !important;
} */
.bgactive{
  border-radius: 50%;
  width: 10px;
  height: 10px ;
}
.activetxt{
  font-family: "Montserrat-M500";
  font-size :16px;
  color: #1A1818;
}
.img-profile{
  width: 185px;
  height: 185px;
}
.realfakeimg{
  width: 100% ;
height: 538px !important;
}
.newdropsheight{
  height: 420px;
}
.designerheight{
  height:143px;
}
.cremeheight{
  height:143px;

}
.multiusecomp{
  height: 153px;
  width: 155px;
}
.falltrendimg{
  width: 900px;
}
.heightai{
  height: 204px;
  align-items: center;
  display: flex;

}
.aiauthcircle{
  padding: 5px;
  width: 139px;
  height: 139px;
  border-radius: 50%;
  border: 1px solid #C6AC96;
  text-align: center;
  justify-content: center;
  background: linear-gradient(0deg, #FFF 0%, #FFF 100%),
   linear-gradient(0deg, #FFF 0%, #FFF 100%), #FFF;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  text-align: center;
  display: grid;
  /* align-content: center; */
}
.autimgpos{
  position: absolute;
  top: 34px;
  left: 50%;
  transform: translate(-70%, -50%);
}
.autimgpos2{
  position: absolute;
  top: 34px;
  left: 50%;
  transform: translate(-81%, -50%);
}
.autimgpos3{
  position: absolute;
  top: 34px;
  left: 50%;
  transform: translate(-57%, -50%);
}
.autimgposAr{
  position: absolute;
  top: 34px;
  left: 50%;
  transform: translate(-24%, -50%);
}
.autimgpos2Ar{
  position: absolute;
  top: 34px;
  left: 50%;
  transform: translate(-31%, -50%);
}
.autimgpos3Ar{
  position: absolute;
  top: 34px;
  left: 50%;
  transform: translate(-57%, -50%);
}
.autimg{
width: 25px;
height: 25px;
/* border-radius: 50%; */
}
.aiauthtxt{
  color: #000;
  text-align: center;
  font-family: 'Montserrat-M500';
  font-size: 9px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;

}
.authtxt2{

}
.aiauthtitle{
  color: #C6AC96;

text-align: center;
font-family: 'Montserrat-M500';
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 15px;
}
.vectorrotate{
  transform: rotate(180deg);
}
.layers{
  width: 800px;
}
.whitebag{
  width: 23vw;
  height: 447px;
}
.downloadlibastxt{
  color: #000;

text-align: center;
font-family: 'Montserrat-M500';
font-size: 30px;
font-style: normal;
font-weight: 500;
line-height: 40px; /* 133.333% */
letter-spacing: 3.3px;
text-transform: uppercase;
}
.phone{
  width:34vw;
height: 559.226px;
}
.phonerow{
  position: absolute;
  right: 0%;
  bottom: -5%;
}
.whitebagrow{
  position: absolute;
  left: 0%;
  top: -0%;
}
.shoesrow{
  position: absolute;
  left: 0%;
  top: -80px;
}
.shoesrowAr{
  position: absolute;
  right: 0%;
  top: -80px;
  transform: rotateY(180deg);
}
.downloadlibasdesc{
  color: #000;

text-align: center;
font-family: 'Montserrat-M500';
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 30px; /* 187.5% */
letter-spacing: 1.76px;
width: 620px;
}
.asleaitxt{
  /* padding-right: 10px; */
  color: #000;

text-align: center;
font-family: 'Montserrat-M500';
font-size: 30px;
font-style: normal;
font-weight: 500;
line-height: 68px; /* 136.667% */
letter-spacing: 3.3px;
text-transform: uppercase;
/* width: 702px; */
}
.asleaitxt2{
  color: #000;

  text-align: center;
  font-family: 'Montserrat-M500';
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px; /* 166.667% */
  letter-spacing: 1.98px;
}
.widthalsehomebox{
 
    display: grid;
      align-content:space-evenly;
      justify-content: space-around;
  
      width: 64%;
      height: 58%;
  
}

@media screen and (max-width: 966px) {
  .downloadlibasdesc{
    font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 20px; /* 200% */
letter-spacing: 1.1px;
width: 100%;
  }
  .phonerow {
    position: absolute;
    right: 0%;
    bottom: 41%;
}
  .downloadlibastxt{
    font-size: 18px;
    line-height: 20px; /* 125% */
    letter-spacing: 1.76px;
    }
    .asleaitxt2{
      font-size: 16px;
      line-height: 15px;
  letter-spacing: 1.1px;
    }
    .asleaitxt{
      font-size: 20px;
  line-height: 20px; /* 125% */
  letter-spacing: 1.76px;
  /* width: 100%; */
    }
}
.asleaiimg{
  height: 715px;
}
.miumiushoes{
  width: 33vw;
height: 462.781px;
}
.chanelbag{
  width: 45vw;

}
@media screen and (min-width: 1300px) {

  .chanelbag{
    width: 361.884px;
  }
  .miumiushoes{
    width: 307.561px;
  height: 762.781px;
  }

}

.chanelbagrow{
  position: absolute;
  right: 0%;
  bottom: -328px;
}
.chanelbagrowAr{
  position: absolute;
  left: 0%;
  transform: rotateY(180deg);
  bottom: -328px;
}
.widthsellwithus{
  position: relative;
  overflow: hidden; /* Hide overflowing content */
  width: 80%;
}
.text-overlayvideo{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  flex-wrap: wrap;
  align-content: space-around;
}
.authentication{
  color: #000;
font-family: 'Montserrat-Reg';
font-size: 32px;
font-style: normal;
font-weight: 500;
line-height: 30px; /* 93.75% */
letter-spacing: 3.52px;
/* text-transform: uppercase; */
}
.authenticationdesc{
  color: #000;

text-align: center;
font-family: 'Montserrat-M500';
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: 30px; /* 166.667% */
letter-spacing: 1.98px;
text-transform: uppercase;
}
.widthauth{
  width: 64vw;
}
.widthauth2{
  width: 84vw;

}
.authtxt{
  color: #000;
text-align: center;
font-family: 'Montserrat-M500';
font-size: 1.3vw;
font-style: normal;
font-weight: 500;
line-height: 20px; /* 100% */
letter-spacing: 2.2px;
text-transform: uppercase;
}
.authtxt2{
  color: #000;
  text-align: center;
  font-family: 'Montserrat-M500';
  font-size: 1.3vw;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 100% */
  letter-spacing: 2.2px;
  text-transform: uppercase;
}

.widthauthhomebox{
 background: rgba(255, 255, 255, 0.36);
  /* display: grid;
  align-content: space-evenly; */
  width: 70%;
  /* height: 40%; */

}
.widthauthhomeboxhover{
  background: rgba(255, 255, 255, 0.61);

  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.authtxtonhover{
  color: #000;

text-align: center;
font-family: 'Montserrat-M500';
font-size: 1.3vw;
font-style: normal;
font-weight: 500;
line-height: 30px; /* 187.5% */
letter-spacing: 1.76px;
text-transform: uppercase;
}
.selltxt{
  color: #000;

text-align: center;
font-family: 'Montserrat-M500';
font-size: 1.5vw;
font-style: normal;
font-weight: 500;
line-height: 40px; /* 181.818% */
text-transform: uppercase;
}
.selltitle{
  color: #000;

font-family: 'Montserrat-M500';
font-size: 3vw;
font-style: normal;
font-weight: 500;
line-height: 30px; /* 75% */
text-transform: uppercase;
}
.text-overlaysell{
  position: absolute;
      padding-left: 13vw;
  top: 0;
  left: 0;
  display: grid;
  width: 100%;
  height: 100%;
  justify-content: start;
  align-content: space-evenly;
}
.whysell{
  color: #000;
padding-left: 6rem;
padding-right: 6rem;
text-align: center;
font-family: 'Montserrat-Reg';
font-size: 3vw;
font-style: normal;
font-weight: 500;
line-height: normal;
/* text-transform: uppercase; */
}
.whysell2{
  color: #000;

  font-family: 'Montserrat-Reg';
  font-size: 2vw;
font-style: normal;
font-weight: 500;
line-height: normal;
/* text-transform: uppercase; */
}
.whysell3{
  color: #000;
  font-family: 'Montserrat-Reg';
font-size: 1.3vw;
font-style: normal;
font-weight: 500;
line-height: normal;
/* text-transform: uppercase; */
}
.bgheight{
  height: 904px !important;
}
.gifttitile{
  color: #000;
padding-top: 90px;
text-align: center;
font-family: 'Montserrat-M500';
font-size: 2.8vw;
font-style: normal;
font-weight: 500;
line-height: 60px; /* 150% */
text-transform: uppercase;
}
.gifttitile2{
  color: #000;

text-align: center;
font-family: 'Montserrat-Reg';
font-size: 1.8vw;
font-style: normal;
font-weight: 400;
line-height: 35px;
width: 72vw;
}
.videomobile{
 
  width: 100%;
  height:707px ;
}
.paddingleftsell{
  
  padding-left: 6rem;
  padding-right: 6rem;

}
.paddingtopsell{
  margin-top: 9rem;
  padding-top: 81px;
  padding-bottom: 81px;

}
.bgimage{
  /* min-height: 150vh;  */
  /* background-image: url(./assets/images/Homepage2/SellWithUs/bgsell.webp); */
    
    background-size: cover;
    width: 100%;
  }

  .impactnumber{
    color: #1F9052;
font-family: 'Montserrat-M500';
font-size: 3vw;
font-style: normal;
font-weight: 500;
line-height: 70px;
letter-spacing: 4.4px;
text-transform: uppercase;
  }
  .impactnumber2{
    color: #000;

text-align: center;
font-family: 'Montserrat-M500';
font-size: 3vw;
font-style: normal;
font-weight: 500;
line-height: 70px; /* 175% */
letter-spacing: 4.4px;
text-transform: uppercase;
  }

  .greenbag{
    position: absolute;
    top: 0;
    right: 0;
z-index: -1;
    }
    /* .greenbagimg{
      width: 45vw;
    } */
  .sellimage{
    background-image: url(./assets/images/Homepage2/SellWithUs/bg.webp);
      
    background-size: cover;
    width: 90%;
    justify-content: center;
    display: flex;
  }
  .bggreen{
    background-image: url(./assets/images/Homepage2/DownloadLibas/greenbag.webp);
    background-size: cover;

  }
  .paddingtopsellwithus{
    padding-top: 80px;
    /* margin-bottom: 100px; */

  }
  .paddingbottomsellwithus{
    margin-bottom: 80px;
    /* margin-bottom: 100px; */

  }
  .paddindauth{
    padding-top: 13px;
    /* height: 30%; */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .heightauth{
    padding-top: 28px;
    padding-bottom: 28px;

    display: flex;
    justify-content: center;
    align-items: center;
  }
  .authtxt3{
    color: #000;

font-family: 'Montserrat-M500';
font-size: 22px;
font-style: normal;
font-weight: 500;
line-height: 30px; /* 136.364% */
letter-spacing: 2.42px;
text-transform: uppercase;
  }
  .missionimg{
    width: 469px;
height: 420px;
  }
  .missiontitile{
    color: #000;

font-family: 'Montserrat-M500';
font-size: 3vw;
font-style: normal;
font-weight: 500;
line-height: 60px; /* 150% */
letter-spacing: 4.4px;
text-transform: uppercase;
  }
  .missiontxt{
    color: #000;

font-family: 'Montserrat-M500';
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 30px; /* 187.5% */
letter-spacing: 1.76px;
  }
  .containerimgoverlay{
    position: relative;
    width: 100%;
    margin: auto;
  }
  .greenbagimg {
    width: 100%;
    height: auto;
    display: block;
  }

  .overlay {
    position: absolute;
    top: 33%;
    left: 50%;
    transform: translate(-50%, -50%);

  }
  .topoverlay{
    padding-top: 33%;

  }
  .searchnavbarwidth{
    width: 80%;
  }
  .authimg{
    width: 387px;
    height: 304px;
  }
  .videomobile{

      width: 100%;
      height:707px ;
    }
/* /////////////////////////////////////////////////////////////////////////////////////////////////////////// */
 @media screen and (max-width: 540px) {
  .scroll {
    overflow-x: auto; /* Enable horizontal scrolling */
    -ms-overflow-style: none;  /* Hide scrollbar in Internet Explorer and Edge */
    scrollbar-width: none;  /* Hide scrollbar in Firefox */
  }
  
  .scroll::-webkit-scrollbar {
    display: none;  /* Hide scrollbar in WebKit browsers (Chrome, Safari) */
  }
  
  .custom-indicators-container { position: absolute; bottom: 1%; right: 2%; display: flex; justify-content: center; align-items: center;display: none; }

.custom-indicators {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 7vw;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: .5;
  transition: opacity .6s ease;
}

.custom-indicators.active {
  opacity: 1;
  width: 8vw;
}


.carousel-txt {
  padding-inline-start: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  color: wheat;
}

.carousel-txt-title {
  padding-top: 0px;
  font-family: 'Karla-Regular';
  display: flex;
  color: #C6AC96;
  font-size: 7vw;
  margin-bottom: 0px;
}
.carousel-txt-title-hr{
  margin-top: 0px;
  margin-left: 10px;  
  /* border-top: 5px solid; */
  /* border: 1px solid #C6AC96 !important; */
  color: #C6AC96;
  background-color: #C6AC96;
  width: 12%;
  height: 0.5px;
  align-self: center;
  margin-bottom: 9px;
  

}

.carousel-txt-subtitle {
  margin-top: 0px;
  margin-inline-end: 15px;
  font-family: 'Montserrat-Reg';
  font-size: 9vw;
  color: white;
 
}

.img-fit-height {
  height: 100%;
  object-fit: cover; /* This will make the image fit the height while maintaining its aspect ratio */
}

.sellBtn {
  position: absolute;
  bottom: 10%;
  left: 25%;
  right: 25%;
}

.carousel-btn{
  font-family: 'Marcellus-Regular';
font-style: normal;
font-size: 3.5vw;
line-height: 10px;
justify-content: center;
display: flex;
/* padding-inline: 20px; */
padding-top: 4%;
width: 40%;
padding-bottom: 3%;
background: #C6AC96;
color: #FFFFFF;
cursor: pointer;
/* width: 163px; */
height: 40px;
}

.carousel-btn  {
  transform: scale(1, 1);
  transition: transform 0.4s;
}

.carousel-btn:hover{
  transform: scale(1.05, 1.05);

}
  .videomobile{
      width: 320px;
      height:696px ;
    }
  .authimg{
    width: 197px;
    height: 166px;
  }
  .phone{
    width:31vw;
  height: 559.226px;
  }
  .topoverlay{
    padding-top: 60%;

  }
  .impactnumber2{
    font-size: 13px;
font-style: normal;
font-weight: 500;
line-height: 30px; /* 187.5% */
letter-spacing: 1.76px;
text-transform: uppercase;
  }
  .impactnumber{
    font-size: 13px;
font-style: normal;
font-weight: 500;
line-height: 30px; /* 187.5% */
letter-spacing: 1.76px;
text-transform: uppercase;
  }
  .missiontitile{
  
font-size: 16px;

line-height: 30px; /* 187.5% */
letter-spacing: 1.76px;
  }
  .missiontxt{
   text-align: center;
font-size: 12px;
line-height: 20px;
letter-spacing: 1.1px;
  }
  .missionimg{
    width: 222px;
    height: 227px;
  }
  .authtxt3{
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 200% */
    letter-spacing: 1.1px;
  }
  .paddindauth{
    padding-top: 18px;
    /* height: 30%; */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .heightauth{
    padding-top: 32px;
    padding-bottom: 10px;

    display: flex;
    justify-content: center;
    align-items: center;
  }
  .whitebag{
    width:28vw;
    height: 447px;
  }
  .shoesrow{
    position: absolute;
    left: 0%;
    top: -58px;
  }
  .shoesrowAr{
    position: absolute;
    right: 0%;
    top: -58px;
    transform: rotateY(180deg);
  }
  .paddingtopsellwithus{
    padding-top: 30px;
    
    /* margin-bottom: 100px; */

  }
  .paddingbottomsellwithus{
    margin-bottom: 30px;
    /* margin-bottom: 100px; */

  }
  .chanelbag{
  width: 46vw;

}
  .sellimage{
    background-image: url(./assets/images/Homepage2/SellWithUs/bgmobile.webp);
      
    background-size: cover;
    width: 90%;
    justify-content: center;
    display: flex;
  }
  .bgimage{
    /* min-height: 150vh;  */
    /* background-image: url(./assets/images/Homepage2/SellWithUs/bgsellmobile.webp); */
      
      background-size: cover;
      width: 100%;
    }
  .text-overlaysell{
    padding-left: 10vw;

    width: 90%;
   
  }
  .whysell{
    font-size: 18px;
    padding-left: 0rem;
    padding-right: 0rem;

  }
  .whysell2{
    font-size: 16px;
  }
  .whysell3{
    font-size: 12px;

  }
  .paddingtopsell{
    margin-top: 55px;
    width: 90% !important;
    padding-top: 33px;
    padding-bottom: 33px;
  }
  .paddingleftsell{
  
    padding-left: 1.2rem;
    padding-right: 1.2rem;
  
  }
  .chanelbagrow{
    position: absolute;
    right: 0%;
    bottom: -136px;
  }
  .chanelbagrowAr{
    position: absolute;
    left: 0%;
    transform: rotateY(180deg);
    bottom: -136px;
  }
  .videomobile{
    width: 320px;
    height:696px ;
  }
  .gifttitile{
    font-size: 16px;
    line-height: 30px;
    padding-top: 50px;
  }
  .gifttitile2{
    font-size: 14px;
    line-height: 20px;
    width: 82vw;

  }
  .bgheight{
    height: 650px !important;
  }
  .widthsellwithus{
    width: 90%;
  }
  .selltitle{
    font-size: 16px;
  }
  .selltxt{
    font-size: 12px;

line-height: 20px; /* 200% */
  }
  .btn-sellhome{
   
    font-size: 12px;
   
    font-family: 'Montserrat-Reg';
    width: 145px;
    height: 33px;
  }
  .widthauth{
    width: 84vw;
  }
  .widthauth2{
    width: 94vw;
  
  }
  .widthauthhomebox{
    background: rgba(255, 255, 255, 0.6);

    /* display: grid;
    align-content: space-between; */
    width: 90%;
    height: 90%;
  
  }
  .authtxtonhover{
    font-size: 1.3vw;
    line-height: 10px; /* 200% */
    letter-spacing: 1.1px;
  }
  .authtxt{
    font-size:3vw;

line-height: 20px; /* 200% */
letter-spacing: 1.1px;
  }
  .authtxt2{
    font-size:3.2vw;

line-height: 20px; /* 200% */
letter-spacing: 1.1px;
  }
  .authenticationdesc{
    font-size: 12px;
line-height: 20px; /* 200% */
letter-spacing: 1.1px;
  }
  .authentication{
    font-size: 16px;
    line-height: 20px; /* 125% */
    letter-spacing: 1.76px;
  }
  .whitebagrow{
    position: absolute;
    left: 0%;
    top: -6%;
  }
  .widthalsehomebox{

    width: 85%;
    height: 76%;
  }
  .asleaitxt2{
    font-size: 12px;
    line-height: 15px;
letter-spacing: 1.1px;
  }
  .asleaitxt{
    font-size: 17px;
line-height: 30px; /* 125% */
letter-spacing: 1.76px;
/* width: 316px; */
  }
  .downloadlibasdesc{
    font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: 20px; /* 200% */
letter-spacing: 1.1px;
width: 100%;
  }
  .phonerow {
    position: absolute;
    right: 0%;
    bottom: 45%;
}
  .downloadlibastxt{
    font-size: 16px;
    line-height: 20px; /* 125% */
    letter-spacing: 1.76px;
    }
  .layers{
    width: 100%;
  }
  .heightai{
    height: 135px;

  }
  .autimgposAr{
    position: absolute;
    top: 18px;
    left: 50%;
    transform: translate(-24%, -50%);
  }
  .autimgpos2Ar{
    position: absolute;
    top: 18px;
    left: 50%;
    transform: translate(-31%, -50%);
  }
  .autimgpos3Ar{
    position: absolute;
    top: 18px;
    left: 50%;
    transform: translate(-57%, -50%);
  }
  .autimgpos{
    position: absolute;
    top: 18px;
    left: 50%;
    transform: translate(-81%, -50%);
  }
  .autimgpos2{
    position: absolute;
    top: 18px;
    left: 50%;
    transform: translate(-78%, -50%);
  }
  .autimgpos3{
    position: absolute;
    top: 18px;
    left: 50%;
    transform: translate(-57%, -50%);
  }
  .autimg{
    width: 23px;
height: 23px;
  }
  .aiauthcircle{
    width: 100px;
height: 100px;
  }
  .aiauthtxt{
    color: #000;
    text-align: center;
    font-family: 'Montserrat-M500';
    font-size: 7px;
    font-style: normal;
    font-weight: 500;
    line-height: 8px;
  }
  .aiauthtitle{
    color: #C6AC96;
  
  text-align: center;
  font-family: 'Montserrat-M500';
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 15px;
  }
  .falltrendimg{
    width: 100%;
  }
  .placeholder-imagecarousel{
    width: 100%;
    height: 300px;
  
  }
  .multiusecomp{
    width: 90px;
    height: 89px;
  }
  .cremeheight{
    height: 174px;

  }
  .designerheight{
    height: 133.33px;
  }
  .newdropsheight{
    height:  340px;
  }
  img {
    aspect-ratio: auto 640 / 360;
  }

  
  
  
  
  .img-profile{
    width: 111.29px;
height: 114.31px;
}
  .activetxt{
    font-family: "Montserrat-M500";
    font-size :11px;
    color: #1A1818;
  }
  .bgactive{
    border-radius: 50%;
    width: 6px;
    height: 6px ;
  }
  .h100{
    height: auto !important;
  }
  .selectedcountry{
    font-size: 12px !important;

  }
 
  .selectedcountrytitle{
    font-size: 12px !important;

  }
  .signupaboutus{
    width: 119.496px;
height: 36px;
font-size: 13px;
  }
  .aligncenter{
    align-items: center;
  }
  .aboutustxtsections{
    font-family: 'Montserrat-Reg';
    font-size: 12px;
line-height: 20px; /* 200% */
letter-spacing: 1.1px;
  }

  .aboutustitle{
    line-height: 29px; /* 207.143% */
letter-spacing: 1.54px;
font-size: 13px;
align-items: center;
text-align: center;
  }
  .sub-aboutsUsTitle3{
    font-size: 15px;
font-style: normal;
font-weight: 500;
line-height: 28px; /* 215.385% */
letter-spacing: 1.43px;
  }
  .sub-aboutsUsTitle2{
    font-size: 15px;
font-style: italic;
font-weight: 500;
line-height: 30px; /* 230.769% */
letter-spacing: 1.43px;
}
.sub-aboutsUsTitle{
    font-family: 'Montserrat-Reg';
    font-size: 15px;
font-style: normal;
font-weight: 400;
line-height: 30px; /* 230.769% */
letter-spacing: 1.43px;
}
.aboutsUsTitle{
    font-family: "Montserrat-M500";
    
font-size: 20px;
line-height: 29px;
}
  .successrefer{
  
  font-size: 32px;
  
  font-weight: 500;
  line-height: normal;
  }
  .referprice{
font-size: 20px;
  }
  .parallax-container {
    min-height: 299px; 
  background-image: url(./assets/images/ReferalProgram/refermodal.webp);
    /* background-attachment: fixed; */
    background-repeat: no-repeat;
    background-size: 100%;
  }
  .responsive-iframe {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
  }
  .widthreferhome{
    width: 100%;
  }
  .widthreferhomebox{
    width: 79%;
    height: 65%;
  }
  .text-overlayinvitefriendshome{
    
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    flex-wrap: wrap;
    align-content: space-around;
  }
  .refraltitle{
    font-size:16px;

  }
  .followfriends{
    width: 75px;
height: 24px;
font-size: 12px;
  }
  .referusername{
    font-size: 14px;
  }
  .prof-imgrfer{
    width: 31px;
  height: 31px;
  }
  .text-overlayxx {
    position: absolute;
    top: 3%;
      right: 2%;
  
    transform: translate(-50%, -50%);
    
  }
  .descriptionlinkwidth{
    width: 75%;
    height: 190px;

  }
  .descriptionlink{
    font-size: 11px;

line-height: 20px; 
  }
  .invitedwidth{
    width: 100%;
  }
  .text-overlaythx {
    top: 23%;
}
  .text-overlayinvitefriends {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background: #D9D9D9;;
    box-sizing: border-box;
    align-content: center;
    flex-wrap: wrap;
  }
  .overlayvideo {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    /* background: #D9D9D9;; */
    box-sizing: border-box;
    align-content: center;
    flex-wrap: wrap;
  }
  .text-overlayaboutUs {
    position: absolute;
    top: -9px;
    left: 1rem;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
    box-sizing: border-box;
    align-content: center;
    flex-wrap: wrap;
  }
  .text-overlayinvitefriends2{
    position: absolute;
    top: 37px;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
    flex-wrap: wrap;
    align-content: space-between;
  }
  .thxrefer1{
    color: #1A1818;
text-align: center;
font-family: 'Cormo';
font-size: 32px;
font-weight: 500;
line-height: normal;
  }
  .btnsharelink{
    width: 180px;
height: 40px;
font-size: 13px;

  }
  .thxrefer{
    font-size: 12px;
line-height: 20px;
  }
  .text-overlay2 {
    position: absolute;
    top: 2%;
    right: -10%;
  }
  .text-overlay1 {
    position: relative;
    bottom: 100%; /* Positioned above the image */
    left: 50%;
    transform: translate(-50%, -33%); /* Adjust the translation value as needed */
  }
  .translated-box {
    position: relative;
    bottom: 100%; /* Positioned above the image */
    /* left: 50%;
    transform: translate(-50%, 113%); 
    */
  }
  .translated-box2 {
    position: relative;
    bottom: 100%; /* Positioned above the image */
    left: 50%;
    transform: translate(-50%, -18%); /* Adjust the translation value as needed */
   
  }
  .text-overlayinvite{
    position: absolute;
    top: 11% !important;
  }
  .text-overlayinvite2{
    position: absolute;
    top: 31% !important;
  }
  .text-overlayinvite3{
    position: absolute;
    top: 41% !important;
  }
  .invitetitle{
    font-size: 14px;

  }
  .invitetitle3{
    font-size: 14px;
    width: 232px;

  }
  .invitetitle2{
    font-size:36px;
  }

  .refralmodalsubtitlethx2{
    font-size: 12px;
    line-height: 20px;
  }
  .referalModal .modal-dialog {
    max-width: auto;
  }
  .successreferalModal .modal-dialog {
    max-width: auto;
  }
  /* .imgreferal{
    height: 275.709px;
  } */
  .btn-refer{
    font-family: 'Montserrat-M500';
    width: 125px;
height: 30px;
font-size: 12px;
  }
  .btn-referhome{
    font-family: 'Montserrat-Reg';
    font-size: 11px;
    line-height: 15px;
    width: 135px;
    height: 33px;
    color: #000000;
  }
  .refralmodalsubtitle{
   
    font-size: 35px;
    width: 227px;

  }
  .refralmodalsubtitlethx{
    font-size: 28px;
  }
  .referalModal .modal-dialog {
    padding-inline: 20px !important;
  }
  .successreferalModal .modal-dialog {
    padding-inline: 20px !important;
  }
  .refralmodaltitle{
    font-size: 16px;

  }
  .image-containerr {
    position: relative;
    overflow: hidden; /* Hide overflowing content */
  }
 
  
  .image {
    width: 100%;
    height: auto;
  }
  
  .overlay-box {
    /* position: absolute; */
    box-sizing: border-box;
    width: 100%; /* Half of the container width */
    height: 200%; /* Double the container height */
    overflow-y: scroll;
  }
  
  .box1 {
    top: 0;
    left: 0;
    transform: translate(0%, -50%);
  
  }

  .referalabsolute{
    z-index: 1;
      position: absolute;
      bottom: -320px;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  .text-overlay3 {
    position: absolute;
    bottom: 15%;
   
    transform: translate(-50%, -50%);
   
    text-align: center;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
  }
  .copiedreferal{
    font-size: 9px;
  }
  .sharereferal{
    width: 15.034px;
   
  }
  .referlink{
    width: 75%;
    height: 42px;
    font-size: 11px;
  }
  .text-overlay1{

width: 93%;
  }
  .creditearned{
    font-size: 12px;
  }
  .REFERRED{
    font-size: 12px;

  }
  .refersubtxt{
    font-size: 11px;
    line-height: 20px;
  }
  
  .widthrefer{
    width: 100%;
  }
  .widththxrefer{
    width: 100%;
  }
  .refertxt{
    font-size: 14px;
  }
  .refertxt2{
    font-size: 20px;
    width: 182px !important

  }
  .btn-join{
  
  font-size: 10px;

  height: 26px;

  }
  .detail-txt{
    font-size: 12px;
    line-height: 23px;
  }
  .required{
    line-height: 14px;
    font-size: 7px !important;
  }
  .faform-loginshow {
    border: 1px solid #404040 !important;
    border-left: 0px !important;
    font-size: 7px !important;
    color: #404040 !important;
    cursor: pointer;
  }
  .faform-loginshowAr {
    border: 1px solid #404040 !important;
    font-size: 7px !important;
    color: #404040 !important;
    cursor: pointer;
  }
  .learn-signup{
    
  font-size: 8px;
 
  }
  .form-email{
    padding: 0.075rem 0.35rem !important;
  }
  .form-email::placeholder{
  
  font-size: 9px;
 
  }
  .btn-join-black{
    font-size: 12px;
    height: 28px;
  }
  .btn-join-black-verify{
    font-size: 12px;
  height: 28px;
  }
.form-signup{
  padding: 0rem 0.35rem !important;
  border: 1px solid #404040 !important;


}
.form-signupotp{
  padding: 0rem 0.35rem !important;
  border: 1px solid #404040 !important;


}
.form-signupotp:focus{
  border: 1px solid #404040 !important;

padding: 0rem 0.35rem !important;
}
.form-signup:focus{
  border: 1px solid #404040 !important;

padding: 0rem 0.35rem !important;
}
.react-tel-input .selected-flag{
  padding: 0rem 0.35rem !important;

}
/* .react-tel-input .form-control::placeholder{
  font-size: 10px !important;
} */
.react-tel-input .form-control::placeholder{
  font-size: 10px !important;
}

.form-signup::placeholder{
  font-size: 10px !important;
}
.form-signupotp::placeholder{
  font-size: 10px !important;
}
.react-tel-input .form-control{
  border: 1px solid #404040 !important;
  padding-top: 0rem  !important;
  padding-bottom: 0rem  !important;

}
  .react-tel-input{
    font-size:8px !important;

  }
  .error-text{
   
    line-height: 14px;

      font-size:7px !important;
     
  }
  .roleximgcrypt{
    width: 100% !important;
  height: auto !important;
  }
  .fb{
height: 9px;
  }
  .faform-loginshowAr {

    font-size: 10px !important;
    color: #404040 !important;
  
  }
  .faform-loginshow {

    font-size: 10px !important;
    color: #404040 !important;
  
  }
  .formlogin2{
    padding: 0.075rem  0.35rem !important;
    border: 1px solid #404040 !important;

  }
  .formloginshow{
    border: 1px solid #404040 !important;
    padding: 0.075rem  0.35rem !important;
    border-right: 0px !important;

  }
  .formloginshowAr{
    border: 1px solid #404040 !important;
    padding: 0.075rem  0.35rem !important;

  }
  .formloginshowAr:focus{
    padding: 0.075rem  0.35rem !important;

    border: 1px solid #404040 !important;


  }
  .formloginshow:focus{
    padding: 0.075rem  0.35rem !important;
    border-right: 0px !important;

    border: 1px solid #404040 !important;


  }
  .formlogin2:focus{
    padding: 0.075rem  0.35rem !important;
    border: 1px solid #404040 !important;

  }

  .btn-login{
   
  font-size: 12px;
 height: 29px;
  
  }
  .or{
    font-size: 10px;
  }
 
  .emailLabel{
    font-size: 12px;
  }
  .formlogin2::placeholder{
    font-size: 10px !important;

  }
  .formloginshow::placeholder{
    font-size: 10px !important;

  }
  .formloginshowAr::placeholder{
    font-size: 10px !important;

  }
  .forgetpass{
    font-size: 9px;
  }
  .togglebbtn{
    font-size: 17px;
  }
  .loader__element {
    border-radius: 100%;
    border: var(--phonesize) solid var(--point-color);
    margin: calc(var(--phonesize)*2);
  }
  
  .circlesize{
    font-family: 'Montserrat-M500';

    font-size: 12px;
   
  }
  .size{
    font-family: 'Montserrat-M500';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  text-transform: capitalize;
  
  color: #000000;
  }
  .bannersubtitle{
    color: #1A1818;
    text-align: center;
    font-family: "Montserrat-M500";
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    height: 36px;
    display: grid;
    align-content: space-around;
  }
  .carousel-inner{
    overflow: initial !important;
  }
  .text-block{
    height: 111px;

    align-content: center;
    margin: auto;
        bottom: -104px;
  }
  .text-blockrefer{
    height: 111px;

    align-content: center;
    margin: auto;
        bottom: -104px;
  }
  .btnscrolltop{
    position: fixed; 
     /* width: 100%; */
     /* right: 2%; */
     bottom: 40px;
     height: 8px;
     font-size: 3rem;
     z-index: 1;
     cursor: pointer;
     color: green;
  }
  .card-body-product {
    /* justify-content: space-evenly; */
    align-items: center;
    /* display: grid; */
    padding: 0!important;
    padding-bottom: 10px;
    margin-top: 10px;
    /* align-content: space-evenly; */
}
  /* .heighnav{
    height: 65px;
  } */
  .cremedelacreme{

    position: absolute;
    bottom: -37px;
    left: 50%;
    transform: translate(-50%, -50%);
}
  .cremedelacremeusername{
    font-size: 12px;
  }
  .cremedelacremeimg{
    width: 38px;
    height: 38px;
  }
  .cremedelacremeproduct{
    width: 122px;
    height: 122px;
  }
  .cremedelacremeproductheight{
    height: 174px;
  
  }
  .categories-img{
    width: 72px;
height: 72px;
  }
  .rounded-img{
    width: 64px;
height: 64px;
    border-radius: 50%;
  }
  .homepagesubtxt{
    font-size: 14px;
  }
  .banner{
    font-family: "Montserrat-Reg";
    line-height: 140%;
  font-size: 16px;
  /* text-transform: uppercase; */
  }
  .trust-banner{
    height: 760px;
    font-size: 18px;

    padding-bottom: 100px;
  
    /* text-transform: uppercase; */
    }
    .trust-maintitle-banner{
    font-size: 24px;
    padding-bottom: 31px;
    padding-top: 28px;
    /* text-transform: uppercase; */
    }
    .trust-image-banner{
      width: 48px;
    }

    .trust-subtitle-banner{
      font-size: 12px;
      /* text-transform: uppercase; */
      }
  .multiusewidth{
    width: 90px;
  }
  .arrowsort {
    height: 6px;
    width: 12px;
}
  .arrowsort2 {
    height: 6px;
    width: 12px;
    transform: rotate(180deg);
}
  .track{
    width: 50px ;
  }
  .trackparcel{
    font-family: 'Montserrat-M500';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #FFFFFF;
    text-decoration: none;
    background: #1A1818;
    padding-inline: 10px;
    padding-top: 3px;
    padding-bottom: 3px;
  
  }
  .sortdropdown {
    width: 100% !important;
    min-width: 100% !important;
    transform: translate3d(0px, 28.5px, 0px) !important;
    inset: 0px 1.5px auto auto !important
}
  .sortbyttitle{
    font-family: 'Montserrat-Reg';

    font-size: 12px;
    line-height: 15px;
 
  }
  .sortstyle{
    color: #1A1818;
    font-size: 13px;
    line-height: 22px;
    text-align: center;
    /* justify-content: center; */
    width: 100%;
    font-family: 'Montserrat-M500';
    padding-top:12px;
    padding-bottom: 12px;
   
  }
  .fa-search-brand{

    /* top: 17px; */
    left: 20px;
    /* width: 23px; */
    /* height: 18px; */
    width: 22px;
    height: 14px;
 }
 .fa-search-brandAr{

  /* top: 17px; */
  right: 20px;
  /* width: 23px; */
  /* height: 18px; */
  width: 22px;
  height: 14px;
}
  .orderfailed{
    font-family: 'Montserrat-SemiB';
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 11px;
   
    color: red;
  }
  .orderreson{
   
    font-size:10px;
    line-height: 11px;
   
    color: #404040;
  }
  .failedbox{
    padding-top: 6px;
    padding-bottom: 6px;
    width: 69px;
font-size: 9px;
line-height: 11px;

  }
  .arrow2{
    width:11px ;
    height:9.92px ;
  
  
  }
  .viewmore{
    font-family: 'Montserrat-M500';
font-style: normal;
font-weight: 500;
font-size: 10px;
line-height: 14px;
/* or 140% */

text-decoration-line: underline;

color: #404040;
  }

  .steppers{
    width: 50px;
  height: 50px;
  }
  .stpppers-title3{
    font-family: 'Montserrat-Reg';
font-style: normal;
font-weight: 400;
font-size: 10px;
line-height: 18px;
/* or 140% */


color: #404040;
  }
  .stpppers-title2{
  
    font-weight: 500;
    font-size: 13px;
line-height: 18px;
    /* or 156% */
    
    
    color: #404040;
  }
  .stpppers-title{
  
  font-weight: 600;
  font-size: 14px;
line-height: 18px;
  /* color: #404040; */
  }
  .mainblogpadding{
    padding-top: 15%;
    padding-bottom: 0%;
    }
 .textBlog
  {
    font-size: 8px!important;
  } .textBlog
  {
    font-size: 8px!important;
  }
  .mainblogtxt{
    font-size: 9px;
line-height: 12px;
  }
  .media-icons2 {
    width: 20px;
height: 20px;
  }
  .loading{
    width: 36px;
  }
  .centered2Ar {
    top: 50%;
    /* left: 0%; */
    right: -12%;
    transform: translate(-50%, -50%);
    position: absolute;
}
  .centered2 {
    top: 50%;
    left: 36%;
    transform: translate(-50%, -50%);
    position: absolute;
  }
  .howtoselltitle{
    font-size: 20px;
    line-height: 20px;
    font-family: 'Montserrat-M500';

  }
  .howtoselltitle2 {
    font-size: 7px;
    line-height: 11px;
    font-family: 'Montserrat-Reg';

  }
  .faq-txt{
    font-family: "Montserrat-SemiB"!important;
    font-size: 16px!important;
    line-height: 20px!important;
  
  }
  .faq-txt2{
    font-family: "Montserrat-Reg";
    font-size: 12px!important;
    line-height: 20px!important;
  
  }
  .howtosell3{
    width: 100%;
  
  }
  .text-lg-left{
    text-align: center;
  }
  .padding-accrordion{
    padding: 1.25rem 1rem !important;

  }
.title-accordionsize{
font-size:16px !important
}
.title-accordionTops{
  color: #404040 !important;
font-family: 'Montserrat-M500' !important;
font-size: 15px !important;
font-style: normal;
font-weight: 300;
line-height: normal;
text-transform: capitalize;

}
 .pay-payment{
padding-inline: 0px;
 }

  .padding-cards{
    padding-inline: 1rem!important;
       }
  .badge-icon{
    width: 30.06px;
    height: 24.69px;
  }
  .text-startAr2{
    text-align: center !important;
  }
  .issue4{
    width: 100%;
    height: 232px !important;
  }
  .issue3{
    width: 100%;
height: 100%;
  }
.lazy-load-toppicks{
  border-radius: 10%;
}
  .lazy-load-image-loaded{
/* width: 100% ;
height: 100% ; */
text-align: center !important;
/* background-color: red !important;
color: red ; */
/* background-image: url(./assets/images/palceholderimage.webp) !important */
  }
  .card-imageProduct{
    margin-top: 45px;
    padding-right: 1rem!important;
    padding-left: 1rem!important;
  }
  .saveEdit{
    font-size: 12px;
    line-height: 15px;
    width: 113px;
height: 30px;
  }
  .editprof4{
    font-size: 9px;
line-height: 11px;
  }
  .editprof3{
    font-size: 9px;
line-height: 11px;
  }
  .editprofname2{
    font-size: 9px;
line-height: 11px;
  }
  .editprofname{
    font-size: 9px;
    line-height: 11px;
  }
  .profileimgedit{
    width: 36px;
    height: 36px;
  }
  .editproftitle{
    font-size: 13px;
    line-height: 16px;
  }
  .followerslist{
   
  font-size: 14px;
 
  
  }
  .share{
    width: 20px;
    height: 24px;
  }
  .follow{
   
  font-size: 16px;
  line-height: 29px;
  width: 120px;
  height: 31px;
 
  }
  .profileimg{
    width: 60px;
    height: 60px;
  }
  .postpay-title-modal{
    width: 164px;
    height: 40px;
  }
  .btn-tabby{
    width: 100%;
    font-size: 17px;
    height: 40.71px;

line-height: 21px;
  }
  .tabby-img-modal{
    width: 49.55px;
    height: 50.08px;
  }
  .tabby-title2-modal{
  
  font-size: 20px;
  line-height: 24px;
 
  }
  .tabby-title3-modal{
    height: fit-content;
    font-size: 16px;
    line-height: 20px
   
  }
  .tabby-title4-modal{
    font-size: 14px;
    line-height: 17px;
    padding-bottom: 14px;
    padding-top: 14px;
  }
  .tabby-title-modal{
    width: 116.23px;
  height: 70.32px;
  }
  .widthtaby{
    width: 100%;
    border: 1px solid #C6AC96;
  }
  .widthtaby2{
    width: 100%;
    border: 0.5px solid #C6AC96;
    height: 36.46px;
  }

  .posttabytxt{
    font-size: 10px;
line-height: 10px;

    
  }
  .posttabytxt2{
 
    font-size: 10px;
    line-height: 10px;
    
  }

  .tabby{
    width: 
    60px;
    height: 24px !important;
  }
  .info-icon{
    width: 11px;
    height: 10.65px;

}
  
  .postpay{
    width: 
    60px;
    height: 14.33px !important;
  }
  .bordertabby{
    border-right:  1px solid #C6AC96;
  }
  .bordertabbyAr{
    border-left:  1px solid #C6AC96;
  }
  .img-selected{
  width: 100%!important;
    height: auto !important;
  }
  .btn404{
    font-family: 'Montserrat-SemiB';
  font-style: normal;
  font-size: 5px;
  line-height: 6px;
  text-transform: uppercase;
  border: 0px;
  background-color: white;
  color: #000000;
  width: 66.89px;;
  padding-top: 5px;
  padding-bottom: 5px;
  }
  .btn404-pos{
    position: absolute;
    bottom: 29px;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .plusbtn2{
    width: 13px;
  /* height: 100px; */
  }
  .fa-search-brand2{

    position: absolute;
    top: 10px;
    width: 16.021px;
    height: 15.998px;
 }
 .fa-search-brand2country{
  position: absolute;
  top: 12px;
  width: 13px;
  height: 13px;

}
  .applepay{
    width: 75px;
      height: 30px;
  
  }
  .btn-seacrh{
    background: #C6AC96;
    font-family: 'Montserrat-Reg';
  font-style: normal;
  font-size: 11px;
  line-height: 11px;
  /* identical to box height */
  border: 0px;
  height: 
  26px;
  letter-spacing: 0.11em;
  width: 203px;
  color: #FFFFFF;
  }
  .btn-google{
    border: 1px solid #404040;

    font-size: 10px;
    height: 26px;
  }
  .btn-googleotp{
    border: 1px solid #404040;

    font-size: 10px;
    height: 26px;

}
  .btn2{
    width: 210.02px;
height: 54.36px;
}
.btn-general{
  font-size: 13px;
  line-height: 16px;

}
  /* .instagram-media{
    min-width: auto;
    max-width: inherit;
    min-height: 800px;
    max-height: 1500px;
  } */

  .blog3-img{
    width: 392px;
  }
  .blogeid-img{
    width: 208px;
height: 260px;
  }
  .bloglux-img{
    width: 392px;
  }
  .steps-img{
    width: 467px;
    height: 725px;
  }
  .steps-imgnew{
    width: 234px;
height: 292px;
  }
  .steps-img2{
    width: 100%;
  
  }
  .LV-img{
    width: 467px;
    height: 725px;  
  }
  .blog3-img2{
    width: 392px;
  }
  .bloglux-img2 {
    width: 392px;
 
  }
  .howtosellbag2{
    width: 307px;
    height: 307px;
  }
  .howtosellbag{
    width: 307px;
    height: 307px;
  }
  .btn-howtosell{
    color: black;
    border: 1px solid black;
    background-color: white;
    font-family: "Montserrat-SemiB";
    font-size: 13px;
    line-height: 16px;
    width: 190.02px;
    height: 54.36px;

  }
  .howtosell-txt3{
    font-size: 13px;
  }
  .howtosell-txt3:hover{
    font-family: 'Montserrat-Reg';
  font-style: normal;
  font-size: 13px;
  line-height: 39px;
  letter-spacing: 0.04em;
  color: #000000;
  text-decoration: none;
  }
  .howtosell-txt2{

    font-size: 13px;
line-height: 20px;
  }
  .howtosell-title2{
    font-style: italic;
    font-size: 12px;
    line-height: 20px;
  }
  .howtosell-title{
    font-size: 15px;
line-height: 41px;
  }
  .howtosell-txt{
    font-size: 12px;
line-height: 15px;
  }
  .crypt-sub{
    font-size: 18px;
line-height: 30px;
  }
  .crypt-title2{
    font-size: 19.5px;
line-height: 30px;
  }
  .crypt-title3{
    font-size: 19.5px;
line-height: 30px;
  }
  .copyWritertxt2{
    font-size: 8px;
    line-height: 10px;
  }
  .blogf-img{
    width: 227px;
    height: 227px;
  }
  .blogf2-img{
    width: 267px;
    height: 267px;
  }
  .copyWritertxt{
    font-size: 7px;
line-height: 31px;
  }
  .copyWritertxteid{
    font-size: 5px;
line-height: 31px;
  }
  .copyWritertxt3{
    font-size: 10px;
line-height: 21px;
  }
  .crypt-title{
    font-family: 'Montserrat-SemiB';
  font-style: normal;
  font-size: 16px !important;
line-height: 30px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  
  color: #000000;
  
  }
  .crypteid-title{
    font-family: 'Montserrat-SemiB';
  font-style: normal;
  font-size: 14px !important;
line-height: 20px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  
  color: #000000;
  
  }
  .crypt-titlenew{
    font-family: 'Montserrat-M500';
  font-style: normal;
  font-size: 16px !important;
line-height: 30px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  
  color: #000000;
  
  }
  .crypt-titlenew600
{
  font-family: 'Montserrat-SemiB';
  font-style: normal;
  font-size: 16px !important;
line-height: 30px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  
  color: #000000;
  
}
  .blog1-img{
    width: 335px;
    height: 67px;
  }
  .height-txt-blog{
height: 78px;
  }
  .btn-blog{
    font-family: 'Montserrat-Reg';
  font-style: normal;
  font-size: 9px;  line-height: 21px;
  letter-spacing: 0.02em;
  /* text-transform: uppercase; */
  color: #FFFFFF;
  background: #C6AC96;
  width: 91px;
  }
  .blog-txt{
    flex-wrap: wrap;
    font-family: 'Montserrat-SemiB';
    font-size: 10px;
    line-height: 20px;
  /* text-transform: uppercase; */
  color: #000000;
  width: 157px;
  }
  .mainblog-img{
    width: 157px;
    height: 157px;
  }
 
  .mainblog-menu{
  
    font-size: 10px;
    line-height: 11px;
    width: 97px;
    justify-content: center;
    display: flex;

  }
   .mainblog-menu2{
  
   
    width:100px;
    

  }
  .mainblog-menu1{
    font-size: 10px;
    line-height: 11px;
    width: 67px;
    justify-content: center;
    display: flex;

  }
  .mainblog-menu{
    font-size: 10px;
    line-height: 11px;
    width:81px;
    justify-content: center;
    display: flex;
  }
  .mainblog-menuall{
    font-size: 10px;
    line-height: 11px;
    width: 54px;
    justify-content: center;
    display: flex;

  }
  .crypt-txt2{

    font-family: 'Montserrat-M500';
  font-style: normal;
  font-size: 12px;
  text-decoration-line: underline;
  color: #000000;
  }
  .crypt-txt{
    font-family: 'Montserrat-Reg';
  font-style: normal;
  height: 70px;
  width: 160px;

  font-size: 12px;
  line-height: 18px;
  
  color: #000000;
  }
  .crypt_sub-date{

    font-size: 14px;
  }
  .crypttxtAr{
    font-family: 'Montserrat-M500';
  font-style: normal;
  height: 70px;
  width: 160px;

  font-size: 14px;
  line-height: 18px;
  
  color: #000000;
  }
  .crypt-img{
    width: 160px;
  height: 160px;
  }
  .title-section2{
    font-family: "Montserrat-Reg";
    /* font-family: 'Cormo'; */
  font-style: normal;
  font-size: 24px;
  line-height: 39px;
  
  
  color: #000000
  }
  .title-section22{
    font-family: "Montserrat-Reg";
    /* font-family: 'Cormo'; */
  font-size: 24px;
  line-height: 39px;
  color: #000000
  }
  .sub-title3{
    font-family: 'Montserrat-M500';

    font-size: 12px;
}
  .circle{
    width: 61px;
  height: 61px;
}
  .pin{
    width:9px;
    height: 9px !important;
  }
  .circle-notofication{
    height: 6px;
    width: 6px;

  }
  .circle-notoficationOpen{
    height: 6px;
    width: 6px;

  }
  .bg-circlesOpen{
    width:  44px;
    height: 44px;
}

  /* .loading{
    width: 60px;
    height: 60px;
  } */
  .txt-privacy{
    font-size: 14px;
line-height: 21px;
/* or 179% */

letter-spacing: 0.11em;

color: #000000;
}
  .title-quality{

    font-family: "Montserrat-Reg" !important;
font-size: 16px;
line-height: 22px;
/* or 179% */
letter-spacing: 0.11em;
}
  .carousel-control-next-icon{
    width: 1rem !important;
    height: 1rem !important;
  }
  .carousel-control-prev-icon{
    width: 1rem !important;
    height: 1rem !important;
  }
  .check{
  
    width: 12px;
    height: 12px;
  
  }
  .nbr-likes{
    font-family: 'Montserrat-Reg';
    font-size: 14px;
    color: black;
  }
  .share-img{
    width: 15px;
    height: 19px;
  }
  
  .close-txt{
    font-size: 10px;
  }
  .signupTitle {
    font-size: 12px;
}
.login-link{
  font-size: 13px;
  }
  .login-linkforgotpass{
    font-size: 10px;

  }
  .signupdesc{
    font-family: 'Montserrat-Reg';
    font-style: normal;
    
    font-size: 10px;
    line-height: 15px;
    text-align: center;
    color: #808080;
    }
  /* .signupTitle{
    font-family: 'Montserrat-M500';
  font-style: normal;
  font-size: 25px;
  line-height: 43px;
  color: #C6AC96;
  } */
  .bg-emptybags{
    background: #F9F5F6;
  }
  .item-nbr{
font-family: "Montserrat-Reg";
font-size: 10px;
line-height: 12px;

color: #404040;
  }
  .back-arrows{
    width: 8.5px;
height: 8.5px;
  }
  .icons-card {
    top: 4px;
    right: 0;
    align-items: center;
    padding-right: 25px;
}
.icons-trusted{
  top: 6px;
    left: 0;
    align-items: center;
    padding-left: 25px;
}
.trustedicon{
  width: 12px ;
  height: 12px;
}
  .title-phone{
    font-family: 'Montserrat-M500';
    font-style: normal;
  font-size: 18px;
  line-height: 22px;
  color: #C6AC96;
  }
  .orderTitle{
    font-family: 'Montserrat-Reg';
font-style: normal;

font-size: 12px;
line-height: 15px;
/* identical to box height */


color: #404040;
  }
  .img-topPicks{
   
      border-radius: 10%;
     
    width: 
    99px !important;
  height: 
  99px !important;
  }
  .homepage-icon{
    width: 18.34px !important;
    height: 18.34px !important;
  }
  
  .landingpage-icon{
    width: 25px !important;
    height: 25px !important;
  }
  
  .img-payment{
    width: 51px;
    height: 40px;
  }
  .btn-widthpayment{
    width: 100% !important;
  }
  .payment-conditon{
    font-family: 'Montserrat-M500';
    font-style: normal;
  font-size: 10px;
  line-height: 20px;
  color: #B2B2B2;
  }
  .link-privacy{
    font-family: 'Montserrat-M500';
    font-style: normal;
  font-size: 10px;
  line-height: 20px;
  color: #B2B2B2;
  }
  .pay-payment{
    font-family: 'Montserrat-M500';
    font-size: 16px;
line-height: 20px;
height: 54px;
/* width: 
319px; */
  }
  .pay-total2{
   
    font-size: 16px;
    line-height: 20px;
  }
  .total-pay2{
 
    font-size: 16px;
    line-height: 20px;
  }
  .pay-total{
    font-family: 'Montserrat-M500';
    font-size: 14px;
line-height: 17px;
color: #777777;
  }
  .total-pay{
    font-family: 'Montserrat-M500';
    
font-size: 14px;
line-height: 17px;
color: #777777;
  }

  .input-promo{
    height: 59px  !important;
  }
  .accordion-width{
    width: 100%;
  }
  .accordion-width2{
    width: 100%;
  }
  .padding-bottom-mybag{
    padding-bottom: 0px;
  }
  .card-chekcout{
    height: 
    auto;
  }
  .accordion-payment::after {
    background-size: 8px !important;
    /* margin-left: unset !important; */
}
.accordion-paymentAr::after {
  background-size: 8px !important;
  /* margin-left: unset !important; */
}
.margin-border-address{
  height: 59px !important;
  margin: 1.25rem 1rem !important;
}
  .padding-border-address{
    height: 59px !important;
    padding: 1.25rem 1rem !important;
  }
  .padding-border-address2{
    padding: 1.25rem 1rem !important;
  }
  .edit{
    width: 20px;
    height: 20px;
  }
  .form-check-input{
    width: 20px !important;
    height: 20px !important;
  
  }

  .btn-plus-Payment{
    width: 20px;
  height: 20px;
  }


  /* .card-order{
    overflow: hidden;
  } */
  .shared-txt{
    font-family: 'Montserrat-M500';
font-size: 8px;
line-height: 41px;
  }
  .shared-img2 {
    width: 61.41px;
    height: 68.34px;
}
.shared-img3 {
  width: 52.18px;
  height: 40.43px;
}
.shared-img4 {
  width: 40.46px;
  height: 45.46px;
}
  .card-width-shared{
    width: 400px;
    }
  .width-visa{
    width: 280px;
    height: 23px;
  }
  .div-width{
    width: 2rem;
   }
  .brand-letters{
    font-family: "Montserrat-Reg";
font-size: 17px;
line-height: 21px;
  }
  .brand-subtxt2{
    font-family: 'Montserrat-M500';
font-size: 12px;
line-height: 15px;

color: #404040
  }
  .brand-subtxt3{
    font-family: 'Montserrat-M500';
font-size: 12px;
line-height: 15px;

  }
  .txt-accordion2{
    font-family: 'Montserrat-M500';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 30px;
    color: #1A1818;
    cursor: pointer;

  }

  .title-accordion{
    font-weight: 500 !important;
    font-size: 17px !important;
  }
  .title-accordionfilter{
    font-weight: 500 !important;
    font-size: 16px !important;
  }
  .title-accordionsize{
    color: #404040 !important;
font-family: 'Montserrat-M500' !important;
font-size: 15px !important;
font-style: normal;
font-weight: 300;
line-height: normal;
text-transform: capitalize;
  }
  .shared-img{
    width: 303.99px!important;
    height: 282.57px!important;
  }
  .position-btn2{
    z-index: 1;
    top: 1%;
    right: 15px;
    position: absolute;
  }  .position-btn2Ar{
    z-index: 1;
    top: 1%;
    left: 15px;
    position: absolute;
  }


 
  .position-btn3{
    z-index: 1;
    top: 12%;
    right: 29px;
    position: absolute;
  }
  /* .card-order:hover{

    
  
    -webkit-transform:scale(1);
    -moz-transform:scale(1);
    -ms-transform:scale(1);
    -o-transform:scale(1);
    transform:scale(1);
  } */
  .btn-comm {
    transform: translate(230px,-67px);
    
    /* padding-right: 10px; */
}


  .link-to-user{

    font-size: 10px;
    line-height: 12px;
    
  }
  .item-sold{
    font-size: 10px;
line-height: 12px;
  }
  .trusted-seller-img{
    width: 
   
15.07px;
    height: 
   
15.07px;
  }
  .trusted-seller{
    font-size: 11px;
line-height: 15px;
  }
  .see-more{
    color: #404040;
    font-size: 12px;
  }
  
  .reviews{
    font-size: 12px;
font-style: normal;
font-family: 'Montserrat-M500';
color: #C6AC96;
  }

  .comment{
    font-size: 12px;
    line-height: 13px;
  }
  .libas-logo{

    width: 101px;
  height: 21.86px;
   }
  .pricee{
    font-family: "Montserrat-B700";
    font-size: 13px;
    line-height: 16px;
  }
  .price{
    font-family: 'Montserrat-Reg';
    font-size: 14px;
    line-height: 15px;
    padding-top: 3px;
    padding-bottom: 5px;
  }
  .prof-img{
    width: 73px;
    height: 73px;
  }
  .auth-modalTitle{
    font-family: 'Montserrat-SemiB';
  font-style: normal;

  font-size: 16px;
  line-height: 34px;
  letter-spacing: 0.07em;
  text-transform: uppercase;
  
  }
  .sub-authModal{
    font-family: 'Montserrat-Reg';
  font-style: normal;
  
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.07em;
  
  }
  .padding-top {
    padding-top: 49px;
}
.padding-top2 {
  padding-top: 1rem!important
}
  .brand-image-txt{
    font-family: "Montserrat-SemiB";
    font-size: 10px;
    line-height: 17px;
  }

  
   .sub-section2{
    font-size: 12px;
line-height: 15px;
    font-family: 'Montserrat-M500';
   }
   .sub-section{
    font-family: 'Montserrat-Reg';

    font-size: 14px;
   }

  .brand-txt{
    font-family: 'Montserrat-M500';
    font-size: 14px;
    line-height: 22px;
  text-align: center;
  
  color: #FFFFFF;
    padding-bottom: 20px;
    position: absolute;
    bottom: 0px;
    left: 43%;
    top: 80%;
    transform: translate(-50%, -50%);
    
   }
   .brand-txt2{
    font-family: 'Montserrat-SemiB';
    font-size: 18px;
    line-height: 22px;
  text-align: center;
  
  color: #FFFFFF;
  
    position: absolute;
    bottom: 0px;
    left: 50%;
    bottom: 27%;
    transform: translate(-50%, -50%);
    
   }
   .view-more {
    font-size: 11px;
line-height: 15px;
   }
  .modal-dialog-delete {
  
    margin: auto !important;
   top: 100px;
}
.delete-modal {
  max-width: 400px !important;
}

  .paginationBttns a {
    /* padding-inline: 10px !important; */
      /* padding-top: 2px!important;
      padding-bottom: 2px!important; */
      border-radius: 0px !important;
      font-family: 'Montserrat-L300'!important;
      font-style: normal;
     
      font-size: 14px !important;
      line-height: 17px !important;
      box-shadow: none !important;
      text-transform: capitalize;
      background: transparent !important;
      /* border: 0.4px solid #404040 !important; */
      /* color: #404040 !important; */
      margin-inline: 4px;
      text-decoration: none;
  }
  .paginationBttns a :hover{

    color: #C6AC96 !important;
  }
  .clear-filters2{
    font-size: 14px;
    line-height: 31px;
  }
  .clear-filters{
 
    font-size: 14px;
    line-height: 31px;
    
    }
  .width50{
    width:100%
  }
  .width60{
    width:60%
  }
  .padding-inline-center{
    padding-right: 1rem!important;
    padding-left: 1rem!important;  }
 }
 .txt-credit{
font-style: normal;
font-family: 'Montserrat-M500';
font-size: 13px;
line-height: 16px;
color: #B2B2B2;
 }
 /* .pos-txt{
  top: 109px;
    left: 50%;
    transform: translate(-50%, -50%);
 } */
 
 .heart{
  top: 0;
  right: 0;
width: 
19px;
height: 
19px;
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  border: none;
}
.bookmark-product {
  width: 
  13px ;
  height: 
20px;
}
@media screen and (min-width: 530px) {

/* 
  .heart{
    top: 0;
    right: 0;
    padding-right: 39px;
    padding-top: 40px;
    background-image: url(./assets/images/Order/heart.png);
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent;
    border: none;
  } */
  /* .heart:focus{
    background-image: url(./assets/images/RedHeart.png);
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent;
    border: none;
  } */
  
  .padding-inlineCards{
    padding-inline: 100px;
  }
 
  .padding-card{
    padding-top: 3rem;
    padding-bottom: 3rem !important;
    /* background-color: #F5F5F5; */
  }
  .icons-order{
    top: 21px;
    right: 0;
    align-items: center;
    padding-right: 35px;
  }
  .card-image{
    /* height: 214px;   */
    padding-inline: 1rem;
    padding-top: 47px
    /* margin-top: 11px; */
  }
.title2-card{
  font-style: normal;
  font-family: 'Montserrat-M500';
  font-size: 21px;
  line-height: 26px;
  text-align: center;
  
  color: #404040;
padding-bottom: 0.5rem!important;

}
.title3-card{
  font-family: 'Montserrat-L300';
  font-style: normal;
font-size: 15px;
line-height: 18px;
text-align: center;
color: #404040;
padding-bottom: 0.5rem;
}
.title4-card{
  font-family: 'Montserrat-M500';
  font-style: normal;
font-size: 14px;
line-height: 22px;
text-align: center;
/* padding-bottom: 0.5rem; */
color: #C6AC96;
}
.title5-card {
  font-family: 'Montserrat-Reg';
font-style: normal;

font-size: 11px;
line-height: 13px;
text-align: center;
color: #404040;
justify-content: center;
/* padding-bottom: 3rem; */
}
  .card-title{
    font-family: 'Montserrat-M500';
font-style: normal;
font-size: 40px;
line-height: 55px;
/* identical to box height */


color: #C6AC96;
  }
  .label-comment{
    font-family: 'Montserrat-M500';
    font-style: normal;
    font-size: 20px;
    line-height: 29px;
    
    color: #C6AC96
  }
  .comment-text{
  height: 146px;
  border: 1px solid #000000 !important;
  }
  .comment-text:focus{
    box-shadow: none !important;
    }
  .comment-text::placeholder{
    font-family: 'Montserrat-M500' !important;
    font-style: normal !important;
    font-size: 16px !important;
    line-height: 20px;

    /* identical to box height */
    
    
    color: #959595 !important;
  }
  .btn-comment{
    width: 130px;
  height: 43px;
 background: #F2F2F2;;
  font-family: 'Montserrat-Reg';
  font-style: normal;

  font-size: 20px;
  line-height: 29px;
  color: #404040;
  border:none;
  }
  .seller-txtAcc{
    font-family: 'Montserrat-M500';
  font-style: normal;

  font-size: 35px !important;
  line-height: 29px;
  
  color: #C6AC96;
  }
  .seller-txtAcc2{
font-style: normal;
font-family: 'Montserrat-ELight200';
font-size: 20px;
line-height: 24px;

color: #404040;

  }
 
  .seller-txtAcc3{
    font-family: 'Montserrat-ELight200';
    font-style: normal;
    font-size: 20px;
    line-height: 24px;
    color: black;
  }
  .seller-txtAcc3:hover{
    color: black !important;

  }
  .loc{
    width: 18.96px;
height: 19.72px;
  }
  .bg-prod{
    background: #F5F5F5;
  }
  .seller-button:not(.collapsed)::after {
    background-image: url(./assets/images/-.png) !important;
}
  .seller-button::after {
    background-image: url(./assets/images/Order/plus.png) !important;
  
  }
  .description {
    font-family: 'Montserrat-M500';
    font-style: normal;
    font-size: 18px;
    line-height: 22px;
    color: #404040;
}
.username{
  font-family: 'Montserrat-M500';
  font-style: normal;
  font-size: 16px;
  line-height: 17px;
  color: #404040;
}
.badge-txt{
  width: 50%;
}
.vector-handBag{
  background: #D9D9D9;
  border-radius: 25px;
  width: 86px !important;
  height: 86px;
  border-radius: 50%;
}

}
.btn-keep {
  font-family: 'Montserrat-M500';
  font-style: normal;
font-size: 20px;
line-height: 26px;
color: #404040;
background: rgba(255, 255, 255, 0.24);
border: 1px solid #404040;
/* padding-inline: 85px; */
width: 234px;
/* padding-top: 5px;
padding-bottom: 5px; */
height: 45px;

}
.btn-del{
  width: 234px;
  font-family: 'Montserrat-M500';

  background: #404040;
font-style: normal;
font-size: 20px;
line-height: 26px;
/* padding-inline: 85px; */
/* padding-top: 5px;
padding-bottom: 5px; */
height: 45px;
color: #FFFFFF;
}
.btn-no{
  border: none;
  background-color: transparent;
}
.bg-acc-none{
  background-color:  #F5F5F5 !important;
}
.bg-acc-none2{
  background-color:  #F5F5F5 !important;
}
.emailDiv{
background-color: white;
}
.email-txt{
  font-family: 'Montserrat-Reg';
  font-style: normal;

  font-size: 19px;
  line-height: 23px;
  /* identical to box height */
  text-decoration: none;
  
  color: #404040;
}

.email-msg{
  width: 
  39.34px;
  height: 
  29.5px;
}
.email-msg2{
  Width:
32.92px;
height: 
29.51px;
}
.emailDiv{
width: 
156.32px;
height: 
150.58px;
}
.seller-txtAcc3:focus{color: black;}
@media (max-width: 576px){
  .seller-txtAcc3:focus{color: black;}

  .email-msg{
    width: 34.45px;
height: 
25.87px;
  }
  .email-msg2{
    width: 
    28.83px;
height: 
25.88px;
  }
  .emailDiv{
    width: 109px;
height: 105px;
  }
  .loc{
    width: 17px;
    height: 18px;

  }
  .seller-txtAcc{
    font-size: 18px;
    color: #C6AC96;
  }
  .seller-txtAcc2{
    font-family: 'Montserrat-ELight200';
    font-size: 12px;
    line-height: 15px;
    
    color: #404040;
  }
  .seller-txtAcc2Ar{
    font-family: 'Montserrat-ELight200';
    font-size: 12px;
    line-height: 15px;
    
    color: #404040;
  }
  .seller-txtAcc3{
    font-family: 'Montserrat-ELight200';

    font-size: 12px;
    line-height: 15px;
    
    color: #404040;
  }
  .email-txt{
    font-size: 14px;
  }
  .seller-button:not(.collapsed)::after {
    background-image: url(./assets/images/-.png) !important;
}
  .seller-button::after {
    background-image: url(./assets/images/Order/plus.png) !important;
  
  }
  .bg-prod{
    background:white;
  }

.delete-modal{
    max-width: 500px;
    margin: 1.75rem auto;
}
}
.sell-text{
  font-family: 'Montserrat-M500';
  font-style: normal;
font-size: 40px;
line-height: 49px;
text-transform: capitalize;
color: #C6AC96;
}
.list-sell{
  font-family: 'Montserrat-L300';
font-style: normal;
font-size: 50px;
line-height: 73px;
text-transform: capitalize;
color: #404040;
}
.image-sell{
  height: 422px !important;
    width: 100%;
}
.padding-top-sell {
  padding-top: 100px;
}
.list-items{
  font-family: 'Montserrat-Reg';
font-style: normal;

font-size: 35px;
line-height: 55px;
text-transform: capitalize;
color: #404040;
}
.sell-txt{
  font-family: 'Montserrat-L300';
font-style: normal;
font-size: 25px;
line-height: 30px;

color: #404040;
}
.bg-sell{
  background: rgba(198, 172, 150, 0.19);
}
.justify-content-space-around{
justify-content: space-around;
}
.phone-img{
  width: 332px ;
  height: 459px !important;
}
.btn-sell{
  font-family: 'Montserrat-M500';
  font-style: normal;
font-size: 25px;
line-height: 30px;
background: #404040;
border: none;
color: #FFFFFF;
width: 100%;
/* padding-inline: 183px; */
padding-bottom: 10px;
padding-top: 10px;
}
.bg-circle{
  background-color: transparent;
}
.how-it-work{
font-style: normal;
font-family: 'Montserrat-M500';
font-size: 40px;
line-height: 49px;
text-transform: capitalize;
color: #C6AC96;
}
.circle1{
  border: 2px solid #404040;
  height: 119px;
  width: 119px;
  border-radius: 50%;
  text-align: center;
  justify-content: center;
    display: flex;
    font-family: 'RacNF';
font-style: normal;
font-weight: 400;
font-size: 45px;
line-height: 65px;

color: #404040;
}
.hr-circle{
  border: 1px solid #404040;
  margin: 1rem 0;
    color: inherit;
    background-color: currentColor;
    border: 0;
    opacity: .25
}
.circle-txt{
font-style: normal;
font-family: 'Montserrat-M500';
font-size: 16px;
line-height: 30px;
color: #404040;
}

.bg-sell2{
  background: #C6AC96;
}
.sell-quset{
  font-family: 'Montserrat-L300';
font-style: normal;
font-size: 40px;
line-height: 61px;
/* identical to box height */

text-transform: capitalize;

color: #FFFFFF;
}
.sell-text-quest{
  font-family: 'Montserrat-L300';
font-style: normal;
font-size: 20px;
line-height: 30px;
text-align: center;

color: #FFFFFF;
}
.seeList{
font-family: 'Montserrat-L300';
font-style: normal;
font-size: 18px;
line-height: 22px;
text-align: center;
text-decoration-line: underline;
color: #FFFFFF;
}
.seeList:hover{
  color: #FFFFFF;

}
.bg-img-txt{
  background-color: rgba(198,172,149,0.3);
}
.step1-title{
  padding-top:50px;
  padding-bottom: 50px;
  font-family: 'Montserrat-Reg';
font-style: normal;

font-size: 20px;
line-height: 37px;

text-align: center;
letter-spacing: 0.07em;
color: #404040;
}

  .padding-inline-step1{
    padding-inline: 15rem;
  }

@media screen and (max-width: 1300px) {
  .banner-img{
    width: 649.16px;
    height: 597.33px !important;

  }

  .padding-inline-step1{
    padding-inline: 5rem;
  }
}

@media screen and (max-width: 1005px) {
  .share{
    width: 20px;
    height: 24px;
  }
  .follow{
   
  font-size: 16px;
  line-height: 29px;
  width: 120px;
  height: 31px;
 
  }
  .padding-inline-step1{
    padding-inline: 7rem;
  }
}
.step1-title2{
  
  font-family: 'Montserrat-M500';
  font-style: normal;
font-size: 20px;
line-height: 37px;
letter-spacing: 0.07em;

color: #C6AC96;
}
.btn-catg{
font-style: normal;
font-family: 'Montserrat-M500';
font-size: 18px;
line-height: 22px;
color: #404040;
border-color: #404040;
padding-top: 10px;
padding-bottom: 10px;
/* padding-inline: 30px; */
width: 204px;
}
.btn-catg:focus{
  background: #404040;
  color: white;

}
.search .fa-search{

  position: absolute;
  top: 20px;
  left: 16px;

 }
 .step1-title3{
  font-family: 'Montserrat-M500';

font-style: normal;
font-size: 25px;
line-height: 30px;
letter-spacing: 0.07em;

color: #404040;
 }
 .step1-back{
  font-family: 'Montserrat-M500';
  font-style: normal;

font-size: 20px;
line-height: 37px;
/* identical to box height */

letter-spacing: 0.07em;

color: #404040;
 }
 
 .step1-pagenbr{
  font-family: 'Montserrat-Reg';
font-style: normal;

font-size: 20px;
line-height: 24px;
letter-spacing: 0.07em;

color: #404040;
 }
 .close-step{
  font-family: 'Montserrat-Reg';
font-style: normal;
font-size: 20px;
line-height: 29px;
text-transform: capitalize;
color: #404040;
 }
 .my-store{
  
font-family: 'Montserrat-Reg';
font-style: normal;
font-size: 20px;
line-height: 29px;
text-transform: capitalize;

color: #404040;
 }
 .sell-items{
font-style: normal;
font-family: 'Montserrat-M500';
font-size: 35px;
line-height: 43px;
/* identical to box height */

text-transform: capitalize;

color: #C6AC96;
 }
 .btn-top-selling{
  font-family: 'Montserrat-M500';

font-size: 18px;
line-height: 22px;
color: #404040;
border: 1px solid #404040;
background-color: transparent;
width: 184px;
padding-top: 10px;
padding-bottom: 10px;
 }
 .catg-mobile{
  font-family: 'Montserrat-M500';

font-size: 16px;
line-height: 20px;
color: #C6AC96;
 }
 .catg-mobile-txt{
  font-family: 'Montserrat-M500';

font-style: normal;
font-size: 16px;
line-height: 20px;
color: #404040;
 }
 .list-catg-phone{
  font-family: 'Montserrat-Reg';
  font-style: normal;
  font-size: 12px;
  line-height: 15px;
  color: #404040;
 }
 .search-brand{
  background: transparent !important;
  height: 40px !important;
 }
 .search-brand::placeholder{
  font-family: 'Montserrat-M500' !important;
font-style: normal;
font-size: 10px !important;
line-height: 10px !important;
/* identical to box height */

letter-spacing: 0.11em !important;
border:0px !important;
/* border-bottom: 1px solid #404040 !important; */
color: #959595 !important;
 }
 @media not all and (min-resolution:.001dpcm) { 
  @supports (-webkit-appearance:none) {
    .search-brand2{
      background :transparent !important;
      height: 37px !important;
      width: 265px !important;
      border: 1px solid #404040 !important;
      border-radius: 0px !important;
      border-left: 0px !important;
      padding-bottom: 0px !important;
     }
  }
}


  
 .search-brand2Ar:focus{
  border-bottom: 1px solid #404040 !important;
  border-right: 0px !important;

 }
 .search-brand2:focus{
  border-bottom: 1px solid #404040 !important;
  /* border: 0px !important; */

 }
 .search-nav-new:focus{
  border-bottom: 1px solid #404040 !important;
  /* border: 0px !important; */

 }
 .search-brand3{
  background :white !important;
  height: 37px !important;
  width: 100% !important;
  border-bottom: 1px solid black !important;
  border-radius: 0px !important;
  border-top:0px !important;
  border-left:0px !important;

  border-right:0px !important;

 }
 .search-brand3::placeholder{
  font-family: 'Montserrat-M500' !important;
font-style: normal;
font-size: 10px !important;
line-height: 10px !important;
/* identical to box height */
letter-spacing: 0.11em !important;

color: #959595 !important;
 }
 .search-brand3:focus{
  border-bottom: 1px solid black !important;

 }
 .search-brand2::placeholder{
  font-family: 'Montserrat-M500' !important;
font-style: normal;
font-size: 10px !important;
line-height: 10px !important;
/* identical to box height */

letter-spacing: 0.11em !important;
border:0px !important;
/* border-bottom: 1px solid #404040 !important; */
color: #959595 !important;
 }
 .search-nav-new::placeholder{
  font-family: 'Montserrat-M500' !important;
font-style: normal;
font-size: 10px !important;
line-height: 10px !important;
/* identical to box height */

letter-spacing: 0.11em !important;
border:0px !important;
/* border-bottom: 1px solid #404040 !important; */
color: #959595 !important;
 }
 .search-brand2Ar::placeholder{
  font-family: 'Montserrat-M500' !important;
font-style: normal;
font-size: 10px !important;
line-height: 10px !important;
/* identical to box height */

letter-spacing: 0.11em !important;

color: #959595 !important;
 }
 .search-navAr-new::placeholder{
  font-family: 'Montserrat-M500' !important;
font-style: normal;
font-size: 10px !important;
line-height: 10px !important;
/* identical to box height */

letter-spacing: 0.11em !important;

color: #959595 !important;
 }
 .btn-close-filter{
  background-color: transparent ;
  border: none;

 }
 .filters-options{
  font-family: 'Montserrat-M500';
  font-style: normal;
  font-size: 13px;
  line-height: 15px;
  /* identical to box height */
  /* background: #E9E9E9; */
/* border-radius: 4px; */
border-radius:0px;

color: #1A1818;
padding-inline: 12px;
padding-top: 8px;
padding-bottom: 8px;
text-transform: capitalize;
width: max-content !important;
border: 1px solid #1A1818;
}
/* .x-close{
  width: 10.64px;
  height: 18.25px;
} */
.gfg {
  margin: 3%;
  position: relative;
}

.first-txt {
  position: absolute;
  top: 17px;
  left: 50px;
}

.second-txt {
  position: absolute;
  bottom: 20px;
  left: 10px;
}
.img-banner{
  width: 1390px;
  height: 694.36px;
}

.shoptopick{
  font-style: normal;
  font-family: 'Montserrat-Reg';
  font-size: 18px;
  text-align: center;
  color: #000000;
  background-color: transparent;
  cursor: pointer;
  text-decoration: none;
}
.btn-shop{
  font-style: normal;
  font-family: 'Montserrat-M500';
  font-size: 15px;
  text-align: center;
  color: #000000;
  background-color: transparent;
  border: 1px solid #000;
  width: 229.281px;
  height: 54.236px;
  /* -webkit-transition-duration: 0.3s;
  -webkit-transition-timing-function: linear; */
  /* box-shadow:0px 0 0 #C6AC96  inset; */
}
.btn-shop:hover{
  box-shadow:100px 0 0 #C6AC96 inset;
  border: 0px !important;
}
/* .btn-shop:hover{
  border: none;
} */
.sort{
  width: 11.001px;
  height: 9.593px;
}
.txt-accordionModal{
  font-family: "Montserrat-Reg";
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */
  
  text-align: center;
  

  color: #000000;
}
.txt-accordion3{
  font-style: normal;
  font-family: 'Montserrat-M500';

  font-size: 17px;
  line-height: 22px;
  text-transform: capitalize;
  text-decoration: none;
  color: black;
}
.txt-accordion3:not(.collapsed) {
  color: #000000 !important;
}
.FILTER-TXT{
  font-family: 'Montserrat-M500';
  font-style: normal;
font-size: 13px;
line-height: 15px;
text-align: center;
background-color: transparent;
border: 0px ;
color: #000000 !important;
}

.FILTER-TXT:hover{
  color: #000000 !important;
  border: 0px !important;

}
.FILTER-TXT:focus{
  border: 0px !important;
  color: #000000 !important;

}
.delete-btn{
  width: 24px;
  height: 24px;
}
.radiobtn{
  width: 
  15px;
  height: 15px;
}
.card-checkout{
  border-radius: 0% !important;
  border: 0px !important;
}
.padding-top2{
  padding-top: 100px;
}
.sub-emptybag{
  font-family: 'Montserrat-Reg';
font-style: normal;

font-size: 20px;
line-height: 24px;

color: #FFFFFF;
 }
 .sub-emptybag2{
  font-family: 'Montserrat-ELight200';
font-style: normal;

font-size: 12px;
line-height: 24px;

color: #FFFFFF;
 }
 .pos-emptybagbtn{
  left: 50%;
  transform: translate(-50%, -50%);
  bottom: -240px;
 }
 .btn-emptybag{
  background: #C6AC96;
  font-family: 'Montserrat-M500';
  font-style: normal;
  font-size: 26px;
  line-height: 37px;
  width: 400px;
  height: 94px;
  border: 0px;
  color: #FFFFFF;
 }
 .pos-txt{
  top: 76px;
}
.mainblog-menu-width{
  cursor: pointer;
}
.title2-howitworks{
  font-family: 'Montserrat-Reg';
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 40px;
  /* or 125% */
  
  text-align: center;
  letter-spacing: 0.11em;
  
  color: #000000;
}
.desc-howitworks{
  font-family: 'Montserrat-Reg';
  font-style: normal;
  font-size: 26px;
  line-height: 40px;
  text-align: center;
  letter-spacing: 0.11em;
  color: #000000;
}
.width-howitworks{
  width: 913px;
}
.flex-blog{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.position-btn-blog2{
  right: 60px;
  top: 838px;
  position: absolute;
}
.position-btn-blog{
  z-index: 1;
  top: 166%;
  right: 40px;
  position: absolute;
}
.copyright{
  width: 10px;
  height: 10px;
}
.border-search{
  border-left: 1px solid #959595;;
}
.border-searchAr{
  border-right: 1px solid #959595;;
}
.fa-search-brand3{
  width: 19px;
  height: 18px;
}
.nextBttn{
  border: 0px  !important;
  background-color: transparent;

  content: url(./assets/images/arrow_left.svg);
  /* width: 67px; */
height: 13px;
}
.previousBttnAr{
  content: url(./assets/images/arrow_left.svg);
  border: 0px  !important;
  background-color: transparent;
margin-inline: 5px;
  height: 13px;
}
.previousBttn {
  border: 0px  !important;
  background-color: transparent;
margin-inline: 5px;
  content: url(./assets/images/arrow-right.svg);
  /* width: 67px; */
height: 13px;
}
.nextBttnAr{
  content: url(./assets/images/arrow-right.svg);
  border: 0px  !important;
  background-color: transparent;
margin-inline: 5px;
  height: 13px;
}
.accordion-button::after{
  display: none;
  margin-left: 0px !important;
  
}
.accordion-button{
 justify-content: space-between;
  display: flex;
}
.seller-txtAcc2Ar{
  text-align: right !important;
  font-style: normal;
  font-family: 'Montserrat-ELight200';
  font-size: 20px;
  line-height: 24px;
  
  color: #404040;
     }
     .textRightAr{
      text-align: right !important;

     }
     .FILTER-TXT::after{
      border: 0px !important;
      }
     
@media screen and (max-width: 530px) {

 
  .copyright{
    width: 6px;
    height: 6px;
  }
  .position-btn-blog{
    z-index: 1;
    top: 22%;
    right: 15px;
    position: absolute;
  }
  .width-howitworks{
    width: 
    366px;
  }
  .desc-howitworks{
    font-size: 12px;
    line-height: 20px;
    font-style: normal;
  }
  .title2-howitworks{
font-style: italic;
font-weight: 700;
font-size: 12px;
line-height: 20px;
/* or 167% */

text-align: center;
letter-spacing: 0.11em;
text-transform: uppercase;

color: #000000;
  }
  .pos-txt{
    top: 36px;
  }

  @keyframes skeleton-loading {

    0% {
      background-image: url(./assets/images/palceholderimage.webp);margin: auto;
      text-align: center;
      background-size: contain;
      width: 130px;
      height: 130px;
     
    }
    100% {
      background-image: url(./assets/images/palceholderimage.webp);margin: auto;
      text-align: center;
    background-size: contain;
      width: 130px;
      height: 130px;
     
    }
  
  }
  .heart{

    width: 
   18px;
    height: 
    13px;
   
    }
    .bookmark-product {

      width: 10.5px;
      height: 17px;
  
    }
  .btn-emptybag{
    height: 
    52px;
    width: 
    
200px;
    font-family: 'Montserrat-M500';
font-size: 12px;
line-height: 20px;
  }
  .bg-emptybag{
    background: #F9F5F6;
/* transform: matrix(1, 0, 0, -1, 0, 0); */
  }
  .radiobtn{
    width: 
    15px;
    height: 15px;
  }

  .delete-btn{
    width: 16px !important;
  height: 16px !important;
  }
  .nextBttn{
    /* content: url(./assets/images/Forward.png); */
    /* width: 30px; */
  height: 10px;
  }
  .previousBttn{
    /* content: url(./assets/images/ForwardAr.png); */
  height: 10px;
  }
  .previousBttnAr{
    /* content: url(./assets/images/ForwardAr.png); */
  height: 10px;
  }
  .nextBttnAr{
    /* width: 30px; */
  height: 10px;
  }
  .paginationBttns a:hover {
    color: #C6AC96 !important;}
    .paginationActive a {
      color: #C6AC96 !important;
      /* background: #C6AC96 !important; */
  }
  /* .img-topPicks{
    width: 
    119.1px !important;
  height: 
  117.78px !important;
  } */
  .txt-accordion3:not(.collapsed) {
    color: #000000 !important;
  }
  .txt-accordionModal{
    font-family: "Montserrat-Reg";
    font-size: 16px;
    line-height: 20px;
    /* identical to box height */
    
    text-align: center;
    

    color: #000000;
  }
  .txt-accordion3{
    font-family: 'Montserrat-M500';
    font-style: normal;
    font-size: 17px;
    line-height: 22px;
    text-transform: capitalize;
    text-decoration: none;
    color: #1A1818;;
  }
  .bg-acc-none2{
    background-color:  white !important;
  }
  .card-order{
    width: 165px!important;
    height: 360px!important;
    /* width: 160px !important;
    height: 320px    !important; */
    border-radius: none !important;
    box-shadow: 0px 7px 7px -1px rgb(0 0 0 / 17%) !important;
    padding-bottom: 2%;
    
    /* box-shadow: 1px 1px 3px gray !important */
  }
  .card3{
    width: 185px !important;
    height: 290px  !important;
    border-radius: none !important;
    box-shadow: 0px 7px 7px -1px rgb(0 0 0 / 17%) !important
  }
  .card-title{
    font-style: normal;
    font-family: 'Montserrat-M500';
    font-size: 18px;

color: #C6AC96;
  }
  .shoptopick{
    font-family: 'Montserrat-Reg';
font-size: 12px;
line-height: 15px;
/* width: 145px;
height: 38px; */
color: #000000;
cursor: pointer;
text-decoration: none;
  }
  .btn-shop{
    font-family: 'Montserrat-Reg';
font-size: 12px;
line-height: 15px;
width: 145px;
height: 38px;
color: #000000;
border: 1px solid black;
  }
  .filters-options{
    font-family: 'Montserrat-M500';
background-color: white;
    font-size: 10px;
    line-height: 11px;
  padding-inline: 10px;
  padding-top: 7px;
  padding-bottom: 7px;
  width: max-content !important;
  /* border: none; */
  color: #1A1818;
  border: 1px solid #1A1818;


  }
  .btn-clear-filters {
    background: #C6AC96;
    font-family: 'Montserrat-M500';

    font-size: 14px;
    line-height: 43px;
    color: #FFFFFF;
    border: none;
    padding-inline: 35px;
    padding-top: 3px;
    padding-bottom: 3px;
    text-decoration: none;
}
  .x-close {
    width: 11px;
    height: 11px;
}
.x-close2 {
  width:6px;
  height: 6px;
}
  .card-product{
    /* box-shadow: 0px 7px 18px -1px #0000002B !important; */
    width: 100% !important;

    height:370px  !important;
    box-shadow: 0px 5px 7px -1px rgba(0, 0, 0, 0.17) !important
  }
  .letter {
    font-family: "Montserrat-Reg";
font-size: 45px;
line-height: 55px;
  }
  .brand-logo{
    /* padding-top: 30px; */
    font-family: "Montserrat-Reg";
font-size: 28px;
line-height: 29px;
  }
 .brand-logo2{
  font-family: "Montserrat-Reg";
  font-size: 28px;
line-height: 29px;
}
  .btn-catg{
    width: 104px;
    font-size: 8px;
  }
  .padding-inline-step1{
    padding-inline: 5rem;
  }
  .seeList{
    font-family: "Montserrat-L300";
   
font-size: 6px;
line-height: 7px;
  }
  .sell-text-quest{
    font-family: "Montserrat-L300";
font-size: 8px;
line-height: 10px;
  }
  .sell-text-quest{
    font-family: "Montserrat-L300";
font-size: 8px;
line-height: 10px;
  }
  .sell-quset{
    
    font-family: "Montserrat-L300";
font-size: 12px;
line-height: 15px;
  }
  .how-it-work{
    font-family: 'Montserrat-M500';

    font-size: 12px;
    line-height: 15px;
  }
  .hr-img{
    width: 42px;
  }
  .circle-txt{
    font-family: "Montserrat-Reg";
font-size: 8px;
line-height: 12px;
  }
  .circle1{
    border: 2px solid #404040;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    font-family: "Montserrat-Reg";
    font-size: 12px;
    line-height: 14px;
  
  }
  .btn-sell{
    font-family: 'Montserrat-M500';

   
font-size: 9px;
line-height: 11px;
  }
  .phone-img {
    width: 243px;
    height: 237px !important;
}
  .list-items{
    font-family: "Montserrat-Reg";
font-size: 12px;
line-height: 16px;
  }
  .sell-txt{
    font-style: normal;
    font-family: "Montserrat-L300";
font-size: 11px;
line-height: 15px;
  }
  .back-arrow{
    width: 9px;
    height: 9px;
  }
  .padding-top-sell {
    padding-top: 20px;
  }
  .image-sell{
    height: auto !important;
      width: 100%;
  }
  .sell-text{
    font-family: 'Montserrat-M500';

    font-size: 12px;
    line-height: 15px;
  }
  .list-sell{
    font-family: "Montserrat-L300";
font-size: 13px;
line-height: 16px;
  }

  .show-modal-location {
    position: fixed;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 1;
}

  .btn-keep {
    font-size: 12px;
    width: 139px;
    height: 35px;
  }
  .btn-del {
    font-size: 12px;
    width: 139px;
    height: 35px;
  }
  .text-delete{
font-size: 12px;
line-height: 25px;

  }
  .price-mybag {
    font-style: normal;
    font-family: 'Montserrat-M500';
font-size: 12px;
line-height: 15px;
  }
  .auth {
    font-style: normal;
    font-family: "Montserrat-Reg";
font-size: 9px;
line-height: 15px;
  }
  .learnMore {
    font-family: "Montserrat-Reg";
font-size: 10px;
line-height: 12px;
text-decoration-line: underline;
  }
  .priceAuth {
    font-style: normal;
    font-family: 'Montserrat-M500';
font-size: 12px;
line-height: 15px;
  }
  .usernameMyBag {
    font-family: 'Montserrat-M500';
font-style: normal;
font-size: 10px;
line-height: 12px;
text-decoration-line: underline;

color: #404040;
  }
  .brandName-mybag {
    font-family: 'Montserrat-M500';
font-size: 12px;
line-height: 15px;
  }
  .description-mybag {

    font-family: "Montserrat-Reg";
font-size: 12px;
line-height: 15px;
  }
  .accordion-payment{
    font-family: "Montserrat-Reg";
    font-size: 12px !important;
    line-height: 15px !important;
    padding: 1.25rem 1rem !important;
    height: 59px  !important;
  }
  .accordion-paymentAr{
    font-family: "Montserrat-Reg";
    font-size: 12px !important;
    line-height: 15px !important;
    padding: 1.25rem 1rem !important;
    height: 59px  !important;
  }
  .padding-accrordion-left{
    padding-left:1rem !important;

  }
  .padding-accrordion-right{
    padding-right:1rem !important;

  }
  .input-promo {
    padding: 1.25rem 1rem !important;
    border: 0px !important;
    border-radius: 0px !important;
}
.catg-payment{
  font-style: normal;
  font-family: "Montserrat-Reg";
  font-size: 12px;
  line-height: 15px;
  color: #404040;
}
.price-payment-details{
  font-family: 'Montserrat-M500';
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */
  
  
  color: #404040;
}
.catg-prcie-payment {

  font-family: 'Montserrat-M500';

font-style: normal;

font-size: 12px;
line-height: 15px;
/* identical to box height */


color: #404040;
}
.aplly-border {
  padding-right: 1rem !important;

  font-weight: 500 !important;
font-size: 12px!important;
line-height: 25px!important;
}
.input-promo::placeholder {

  font-family: "Montserrat-Reg";
  font-style: normal!important;
font-size: 12px!important;
line-height: 15px!important;
color: #777777 !important;
}
.checkbox-save-future{
  font-family: 'Montserrat-M500';

  font-size: 13px;
  line-height: 16px;
  color: #404040;
}
  .expiration-date{

    font-family: "Montserrat-EBold800";
font-style: normal;
font-size: 13px;
line-height: 16px;
/* identical to box height */


color: #404040;
  }

  
  .bg-myaddress{
    background: #F7F7F7 !important;
  }
  .bg-mybag{
    background-color: white;
  }
  /* .rolex{
    width: 155px;
    height: 155px;

  } */
  .gucci{
    width: 79px;
    height: 127px;
  }
  .heart-product {
background-size: 13.89px 13.89px;
  }
 
  .title5-product {
    display: none;
  }


/* @keyframes skeleton-loading {
  0% {
    background-image: url(./assets/images/palceholderimage.webp);margin: auto;
    background-size: cover;
    height: 127px;
margin: 0 auto;
margin-top: 20px;
width: 100%;
padding-inline: 9px;
    
  }
  100% {
    background-image: url(./assets/images/palceholderimage.webp);margin: auto;
    background-size: cover;
    margin: 0 auto;
    height: 127px;
    margin-top: 20px;
    width: 100%;
    padding-inline: 9px;
  }
} */
  .SOLD{
    font-family: 'Montserrat-M500';

 padding-bottom: 0.3rem;
    font-size: 11px;
    line-height: 10px;
   
  }
  .title2-product{
    font-family: 'Montserrat-M500';

    font-size: 14px;
  }
  .title3-product{
    font-size: 12px;
    margin-bottom: 5px;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .title4-product {
    font-family: 'Montserrat-M500';

    font-size: 14px;
    line-height: 15px;
    color: #C6AC96;
  }

  .deal-price{
    /* font-family: "Montserrat-SemiB"; */
    font-size: 15px;
    line-height: 16px;
  
  }
  .deal-price1{

    font-size: 14px;
    line-height: 15px;
    padding-bottom: 0.3rem;
  }
  .price-notindeal{
    padding-bottom: 2rem;
    font-size: 12px;
  }
  .louis-title {
    font-family: 'Montserrat-M500';

    font-size: 18px;
    line-height: 22px;
    color: #404040;
  }

.sub-title-louis {
font-style: normal;
font-family: "Montserrat-Reg";
font-size: 12px;
line-height: 17px;
/* identical to box height */
color:  #404040;
}
  .requestView{
 height: 70px;
background: #FFFFFF;
border: 1px solid #404040;
font-family: 'Montserrat-M500';

font-size: 14px;
line-height: 17px;
  color: #404040;
  width: 100%;
  /* padding-inline: 15px;
      padding-top: 20px;
      padding-bottom: 20px; */
  }

    /* old */
  .btn-order{
    height: 45px;
    background: #404040;
    border:none;
    font-family: 'Montserrat-M500';
 padding-top: 0px;
 padding-bottom: 0px;
    font-size: 14px;
    line-height: 20px;
    color: #FFFFFF;
    margin-top: 0px;
    margin-top: 0px;
    }
    .btn-offer{
      background: #FFFFFF;
      border: 1px solid #E3E3E3;

    font-family: 'Montserrat-Reg';
    font-style: normal;
    width: 40%;
    font-size: 12px;
    line-height: 29px;
    
    color: #000000;
    }
    .image-container{
      display: flex;
      flex-direction: column;
      padding: 0; /* Remove any padding */
      margin: 0; /* Remove any margin */
      position: absolute;
      top: 5%;
      left: 8%;
      width: 80%;
    
    }
    .btn-2Image{
      margin-top: 10px;
      position: relative;
      background: transparent;
    border: 1px solid #404040;
    
    font-family: 'Montserrat-Reg';
    font-style: normal;
    width: 110px;
    height: 40px;
    font-size: 14px;
    color: #000000;
    }
    .image-title{
      position: relative;
      font-family: 'Montserrat-Reg';
      font-size: 18px;
    }
    .image-subtitle{
      position: relative;
      font-family: 'Montserrat-M500';
      font-size: 30px;
    }
  .bag-details .msg {
    padding-top: 21px;
    font-family: "Montserrat-Reg";

font-style: normal;
font-size: 14px;
line-height: 17px;

color: #404040;
  }
  .vector-handBag {
    background: #D9D9D9;
    border-radius: 25px;
 
    border-radius: 50%;
    width: 43px;
    height: 43px;
  }
  .username-handBag {
    font-family: "Montserrat-M500";
font-style: normal;
font-size: 12px;
line-height: 15px;
color: #404040;
  }
  .trusted-sm{
    width: 85px;
    height: 13.21px;
  }

.bag-details .price1 {
  font-family: 'Montserrat-M500';

font-size: 20px;
line-height: 24px;
text-decoration-line: line-through;
color: #404040;
    }
.bag-details .price2 {  
  font-family: "Montserrat-SemiB";
  font-style: normal;
font-size: 20px;
line-height: 24px;
color: #DD0000;
  }
.bag-details .pourcentage {
  font-style: normal;
  font-family: "Montserrat-L300";
  font-size: 12px;
  line-height: 15px;
  
  color: #146900;
  
  }
.estimated {
  font-family: "Montserrat-Reg";
  font-style: normal;
font-size: 12px;
line-height: 17px;

color: #777777;
  }
  .learn-more{
    color: #404040;

  }
  .learn-more:hover{
    text-decoration: underline;
  }
  .badge-txt{
    font-family: "Montserrat-Reg";
font-style: normal;
font-size: 10px;
line-height: 12px;
width: 100% !important;
color: #404040;
  }
  .bag-details {
    padding-top: 50px;
    border: none;
  }
  .bags{
    width: 100%;
    height: 100%;
  }
  .bookmark-order{
    width: 19px;
    }

  .padding-inlineCards{
    padding-inline: 100px;
  }

  .padding-card{
    /* padding-top: 30px;
    padding-bottom: 30px; */
    /* background-color: #F5F5F5; */
  }
  .icons-order{
    top: 21px;
    right: 0;
    align-items: center;
    padding-right: 35px;
  }

  .card-image {
    /* height: 155px; */
    padding-top: 30px;
    /* margin-top: 11px; */
}
  .title2-card{
    font-family: 'Montserrat-B700';
font-style: normal;

font-size: 13px;
line-height: 16px;
    text-align: center;
    color: #404040;

    padding-bottom: 0.3rem;

  }
  .title3-card{
  
   font-family: 'Montserrat-Reg';

font-size: 9px;
line-height: 11px;
/* identical to box height */

padding-bottom: 0.5rem;

color: #959595;
/* font-weight: 400;
font-size: 14px;
line-height: 17px; */
    text-align: center;

  }
  .title4-card{
    
    font-family: "Montserrat-Reg";
font-style: normal;
font-size: 14px;
line-height: 17px;
  text-align: center;
  padding-bottom: 0.3rem;
    color: #C6AC96;
  }
  .title5-card {
    font-family: 'Montserrat-B700';
  font-style: normal;

  font-size: 9px;
  line-height: 11px;
  justify-content: center;
  color: #959595;
  padding-bottom: 0.3rem;  }
   
  .comment-text{
    height: 88px;
      }
    .comment-text:focus{
      box-shadow: none !important;
      }
    .comment-text::placeholder{
      font-family: 'Montserrat-M500';

      font-size: 12px;
      line-height: 15px;
      color: #959595
    }
    .btn-comment{
      width: 
      100.48px;
      height: 31px;
   
      font-family: 'Montserrat-M500';

    font-size: 11px;
    line-height: 13px;
    color: #404040;
    border:none;
    }
  .label-comment{
    font-family: 'Montserrat-M500';

    font-size: 14px;
    line-height: 17px;
    color: #C6AC96;
  }
  .username{
    font-family: 'Montserrat-M500';

font-size: 14px;
line-height: 15px;

color: #404040;
  }
  .vector{
    width: 48px;
height: 48px;

  }
  .img-seller{
    width:22.31px ;
height:24.79px ;

  }
  .accordion-footerTitle{
    font-family: 'Montserrat-Reg'!important;
    font-style: normal !important;
   
    font-size: 22px !important;
    line-height: 13px;
    /* text-transform: uppercase; */
    
    color: #FFFFFF !important;

  }

  .seller-button::after{
    background-image: url(./assets/images/Order/plus.png) !important;
     }
  .category{
    width: 102px;
    height: 131px;
  }
  .bag{
    width: 165.38px;
    height: 215.61px;
  }
  .watch{
    width: 102px;
    height: 131px;
  }
  .signup-offer .txt1 {
    font-family: 'Montserrat-M500';

font-size: 10px;
line-height: 12px;
  }
  .signup-offer .txt2 {
    font-style: normal;
    font-family: "Montserrat-L300";
font-size: 7px;
line-height: 9px;
  }
  .btn-getOffer {
    font-family: 'Montserrat-M500';

font-size: 7px;
line-height: 9px;
padding-inline:30px ;

  }

  .bg-luxury .txt {
    font-style: normal;
    font-family: "Montserrat-Reg";
    font-size: 12px;
    line-height: 15px;
  }
  .bg-luxury .view {
    font-family: 'Montserrat';
font-style: normal;
font-size: 9px;
line-height: 11px;
/* identical to box height */

text-align: center;
text-decoration-line: underline;
  }
  .brand-img{
    width: 239px;
    height: 188px;
  }
  .designrer-brand{
    width: 112px;
    height: 112px;

  }
  .bg-auth .txt {
font-style: normal;
font-family: "Montserrat-Reg";
font-size: 14px;
line-height: 17px;
  }
  .bg-auth .txt-2 {
font-style: normal;
font-family: "Montserrat-L300";
font-size: 10px;
line-height: 12px;
  }
  .bg-auth .txt-3 {
    font-family: "Montserrat-L300";
    font-size: 12px;
    line-height: 15px;
  }

  .footer-txt{
 
    font-family: "Montserrat-Reg";
font-size: 10px !important;
line-height: 15px;
color: black;
  }

  .date{
    font-family: "Montserrat-Reg";
    font-size: 10px;
    line-height: 12px;
  }

  .link-text{
    font-family: 'Montserrat-M500';

    font-size: 12px;
    line-height: 11px;
    /* identical to box height */
    
    
    color: #404040;
   
 }
 .view-all {
  font-family: 'Montserrat-M500';

font-size: 8px;
line-height: 10px;
}

.bg-explore .txt {
  font-family: 'Montserrat-M500';

font-size: 12px;
line-height: 15px;
}
.bg-explore .txt2 {
  font-style: normal;
  font-family: "Montserrat-Reg";
  font-size: 10px;
line-height: 12px;
}
.designer-title {
  font-family: 'Montserrat-M500';

font-size: 14px;
line-height: 17px;
/* identical to box height */

text-transform: uppercase;
}
.detailsTitle {
  font-family: 'Montserrat-M500';

font-size: 18px;
}
.description-Title{
  font-family: 'Montserrat-M500';

font-size: 16px;
}
.description {
  color: #404040;
  font-family: 'Montserrat-M500';

font-size: 12px;
line-height: 15px;
}
.item{
font-style: normal;
font-family: 'Montserrat-M500';

font-size: 16px;
line-height: 20px;
}
.detailText {
font-style: normal;
font-family: 'Montserrat-M500';

font-size: 16px;
line-height: 17px;
color: black;
}
.detailType {
  font-family: 'Montserrat-ELight200';

font-size: 14px;
line-height: 15px;
text-align: right;

color: #404040;
}
.option-selected{
  background-color: white !important;
  font-style: normal !important;
font-family: "Montserrat-M500";
font-size: 12px !important;
line-height: 15px !important;
color: #404040 !important;
background-image: none !important;
border: 0.5px solid #F5F5F5 !important;
width: 100% !important;
}

.payment-tiltle{
  font-family: "Montserrat-M500";
  font-size: 14px;
  line-height: 16px;
}
.btn-selected{
  background-color: white ;
  border: 0.5px solid #F5F5F5;
  
 
 }
 .padding-inline-center{
  padding-right: 1rem!important;
  padding-left: 1rem!important;

}
.adress-payment {
  font-family: "Montserrat-Reg";
  font-size: 12px !important;
  line-height: 15px !important;
  
}
 .back-Link{
  font-size: 10px;
}
.pin-btn {
  font-size: 11px;
  padding-top: 1px;
  padding-bottom: 1px;
}
.address-title {
  font-size: 13px;
}
.adress-line-desc {
  font-size: 12px;
  line-height: 17px;
}
.form-country{
  font-size: 9px!important;

}
.save-adress{
  font-size: 14px !important;
  line-height: 42px;
}
.next-step{
  border: none;
  padding-top: 15px;
  padding-bottom: 15px;
  background: #404040;
  font-family: 'Montserrat';
font-style: normal;
font-family: "Montserrat-Reg";
font-size: 14px;
line-height: 17px;
/* identical to box height */


color: #FFFFFF;
}
.accordion-item-border{
  border: 0px  !important;
}
.accordion-steps {
  font-family: 'Montserrat-M500' !important;

  font-size: 16px!important;
  line-height: 20px!important;
  color: #404040 !important;;
}
.mybag-Title {
  font-family: 'Montserrat-M500';

  font-size: 18px;
  line-height: 20px;
  color: #C6AC96;
}
.mybag-TitleNEW{
  font-family: 'Montserrat-M500';

  font-size: 18px;
  line-height: 20px;
  color: #404040;
}
/* .img-payment{
  width: 41px;
  height: 20px;
} */
 }
 
 @media screen and (max-width: 1400px) {
  .shared-img{
    width: 424.78px;
        height: 416.53px;
  
    
  }

 }
@media screen and (max-width:971px) {

  .title-section{
    font-size: 24px;
    text-align: start;
    letter-spacing: 0px;

   }
  }
  @media screen and (max-width: 990px) {

   
    .pos-emptybagbtn{
      left: 50%;
      transform: translate(-50%, -50%);
      bottom: 23px;
     }
  }
  @media screen   and (max-width: 354px)   {
    .img-topPicks{
      width: 70px !important;
    height: 70px !important;

    }
  
  }


  @media screen   and (max-width: 420px)   {
 
    .instagram-media{
      min-width: auto;
      max-width: inherit;
      min-height: 691px;
      max-height: 1500px;
  /* height: 550px !important; */
    }
    .instagram-media3{
      min-width: auto;
      max-width: inherit;
      min-height: 591px;
      max-height: 1500px;
  /* height: 550px !important; */
    }
 
  }
  @media screen   and (max-width: 348px)   {
 
    .instagram-media{
      min-width: auto;
      max-width: inherit;
      min-height: 572px;
      max-height: 1500px;
  /* height: 550px !important; */
    }
    .instagram-media3{
      min-width: auto;
      max-width: inherit;
      min-height: 572px;
      max-height: 1500px;
  /* height: 550px !important; */
    }
    .instagram-media2{
      min-width: auto;
      max-width: inherit;
      /* height: 450px !important; */
      min-height: 450px;
  
        }
   
  }
  @media screen   and (max-width: 324px)   {
    .blog-txt{
 
    width: 127px;
    }
    
    .mainblog-img{
      width: 127px;
      height: 127px;
    }
    .btn404-pos{
      position: absolute;
      bottom: 22px;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
